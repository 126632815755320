import styled from 'styled-components'

export const TermsAndCondition = styled.div`
  h2 {
    color: ${(props) => props.color || props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSizes.lg};
    margin-bottom: 27px;
  }

  ul {
    list-style-type: decimal;
    display: flex;
    flex-direction: column;
    gap: 26px;
    font-weight: 550;

    li {
      color: ${(props) => props.color || props.theme.colors.white};
      font-size: ${(props) => props.listFontSize || props.theme.fontSizes.xs};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    h2 {
      color: ${(props) => props.color || props.theme.colors.text['700']};
    }
    ul {
      transform: translate(10px);
      li {
        color: ${(props) => props.color || props.theme.colors.text['700']};
      }
    }

    button {
      margin-top: 50px;
      width: 100%;
    }
  }
`

export const MediaVision = styled.div`
  display: none;
  
  span{
    color: ${props=>props.theme.colors.text[100]};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`
