import styled from 'styled-components'

export const InfoDropdown = styled.div`
  transform: translate(30px, 0);
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px 30px;
  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  gap: 10px;

  position: absolute;
  right: -5px;
  top: 100%;
  margin-top: 20px;
  z-index: 99;
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '325px')};

  transition: opacity 0.3s ease-in-out;

  svg.mark-arrow {
    position: absolute;
    right: 40px;
    top: -8px;
  }
  svg,
  p,
  h5 {
    cursor: pointer;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    left: -30px;
    top: 30px;
    width: 100%;
    overflow: auto;
    box-shadow: none;
    height: 100vh;
    padding-bottom: 150px;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`

export const Actions = styled.div`
  div.ico {
    transform: ${(props) => (props.open ? 'rotate(180deg)' : '')};
    transition: 0.3s linear;
  }
`
