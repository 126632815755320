import { useMutation } from '@apollo/client'
import { useAuthToken } from './useAuthToken'
import { useHistory } from 'react-router-dom'
import { LOGIN, SOCIAL_LOGIN } from 'services/mutations/auth'

const useLogin = () => {
  const history = useHistory()
  const [_, setAuthToken, removeAuthToken] = useAuthToken()
  const chosenFriendSlug = localStorage.getItem('chosenFriend')
  const onCompleted = (data) => {
    const token = data?.login?.token || data?.SocialLogin?.token
    if (token) {
      setAuthToken(token)

      if (chosenFriendSlug) {
        history.push(`/user/${chosenFriendSlug}`)
      } else {
        history.push('/feed')
      }
    }
  }

  const [loginMutation, loginResult] = useMutation(LOGIN, {
    errorPolicy: 'all',
    onCompleted,
  })

  const [socialLoginMutation, socialLoginResult] = useMutation(
    SOCIAL_LOGIN,
    {
      errorPolicy: 'all',
      onCompleted,
    }
  )

  const login = (data) => {
    removeAuthToken();
    loginMutation({
      variables: data,
    })
  }

  const socialLogin = (token, provider) => {
    removeAuthToken()
    socialLoginMutation({
      variables: {
        social_provider: provider,
        token,
      },
    })
  }

  return {
    login,
    socialLogin,
    loginResult,
    socialLoginResult,
  }
}

export default useLogin
