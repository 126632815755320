import Icon from 'components/Icon'
import * as S from './styled'

const Checkbox = ({ children, name, register, ...rest }) => {
  const registeredObj = !!register ? register(name) : {}
  return (
    <S.Checkbox>
      {rest?.textLeft ? (
        <S.CheckboxContainer
          checkboxContainerwidth={rest?.checkboxContainerwidth}
          flexDirection={rest?.flexDirection}
        >
          <span>{children}</span>
          <div>
            <S.Input type="checkbox" {...rest} {...registeredObj} />
            <i>
              <Icon filename="success" />
            </i>
          </div>
        </S.CheckboxContainer>
      ) : (
        <>
          <S.Input type="checkbox" {...rest} {...registeredObj} />
          <i>
            <Icon filename="success" />
          </i>
          <span>{children}</span>
        </>
      )}
    </S.Checkbox>
  )
}

export default Checkbox
