import { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'

import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import Grid from 'components/Grid'
import Button from 'components/Forms/Button'

import usePostSuggestions from 'hooks/usePostSuggestions'
import useSendVolunteerRequest from 'hooks/useSendVolunteerRequest'

import WorkSpace from 'modules/Modals/WorkSpace'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const RequestRightBar = () => {
  const [skills, setSkills] = useState([])
  const [amount, setAmount] = useState(0)

  const { me } = useAuthorizedUser()
  const location = useLocation()
  const postUrl = location?.pathname.split('/')
  const postId = Number(postUrl[postUrl.length - 1])

  const { data, error, loading } = usePostSuggestions(postId)
  const [sendVolunteerRequest] = useSendVolunteerRequest()
  const { t } = useTranslation()

  const [skillsShow, setSkillsShow] = useState(false)
  const enoughtTime = data?.post?.start_date - data?.post?.end_date
  const userId = data?.post?.user?.id

  const helpButtonDisabled =
    !!data?.post?.volunteer_requests?.find((item) => item.id === me?.id) ||
    !!data?.post?.volunteers?.find((item) => item.id === me?.id)

  useEffect(() => {
    const filterSkills = data?.post?.skills?.slice(0, 3)
    setSkills(filterSkills)

    if (data?.post?.skills?.length > 3) {
      setAmount(data?.post?.skills?.length - 3)
    }
  }, [data?.post?.skills])

  const showAllSkills = () => {
    setSkills(data?.post?.skills)
    setSkillsShow(true)
    setAmount(0)
  }

  return (
    <S.RequestRightBar>
      <S.Author>
        <h4>{t('post.author')}</h4>
        <S.AuthorInfo>
          <Grid type="avatar">
            <Avatar
              type="simple"
              src={data?.post?.user?.avatar?.path}
              sex={data?.post?.user?.sex}
            />
            <h5>
              {data?.post?.user?.name} {data?.post?.user.surname}
            </h5>
            <S.Box>
              <p>{data?.post?.user?.state?.name}</p>
              <S.Circle />
              <p>{data?.post?.end_date}</p>
            </S.Box>
          </Grid>
          {/* <Link to={`/messages/${data?.post?.user?.conversation_id}`}>
            <Icon filename="messages" />
          </Link> */}
        </S.AuthorInfo>
        {userId != me?.id && (
          <S.JobInfo>
            {/* <p>შეგიძლია ამ სამუშაოს შესრულება {enoughtTime} საათში?</p> */}
            <h3>
              {data?.post?.volunt}
              <span>{t('common.timecoin')}</span>
            </h3>
            {!data?.post?.is_started && (
              <>
                {/* <Button
                type="primary"
                onClick={() => sendVolunteerRequest(data?.post?.id)}
                disabled={helpButtonDisabled}
              >
                {t('common.help')}
              </Button> */}
              </>
            )}
          </S.JobInfo>
        )}

        <WorkSpace />

        <div>
          <S.SkillsTitle>{t('post.skills')}</S.SkillsTitle>
          <S.Skills open={skillsShow}>
            {skills?.map((each, i) => (
              <div className="skill">
                <Link to="#" key={i}>
                  #{each.name}
                </Link>
                {i === 2 && amount > 0 && (
                  <span onClick={showAllSkills}>+{amount}</span>
                )}
              </div>
            ))}
            {/* <Button type='clean' onClick={() => setSkillsShow(!skillsShow)}>
                        +5
                    </Button> */}
          </S.Skills>
        </div>
      </S.Author>
    </S.RequestRightBar>
  )
}

export default RequestRightBar
