import { gql } from '@apollo/client'

export const UPDATE_RESUME = gql`
  mutation {
    UpdateResume {
      response
      user {
        resume {
          path
        }
      }
    }
  }
`
