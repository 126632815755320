import { gql } from '@apollo/client'

export const SEND_VOLUNTS = gql`
  mutation SendVoluntsToVolunteer($request_id: Int, $user_id: Int) {
    SendVoluntsToVolunteer(request_id: $request_id, user_id: $user_id) {
      response
    }
  }
`

export const REQUEST_VOLUNTS = gql`
  mutation RequestVolunts($request_id: Int) {
    RequestVolunts(request_id: $request_id) {
      response
    }
  }
`

export const APPROVE_TRANSACTION = gql`
  mutation ApproveTransaction($transaction_id: Int) {
    ApproveTransaction(transaction_id: $transaction_id) {
      response
    }
  }
`

export const REJECT_TRANSACTION = gql`
  mutation RejectTransaction($transaction_id: Int) {
    RejectTransaction(transaction_id: $transaction_id) {
      response
    }
  }
`

export const RATE_USER = gql`
  mutation RateUser(
    $request_id: Int
    $user_id: Int
    $rating: String
    $organization_id: Int
    $type: String
    $review: String
  ) {
    RateUser(
      request_id: $request_id
      user_id: $user_id
      rating: $rating
      organization_id: $organization_id
      type: $type
      review: $review
    ) {
      response
    }
  }
`
