import { useMutation } from '@apollo/client'
import { UPDATE_PRIVACY } from 'services/mutations/privacy'

const usePost = () => {
  const [updatePrivacyMutation, updatePrivacyResult] = useMutation(
    UPDATE_PRIVACY,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser'],
    }
  )

  const updatePrivacy = (data) => {
    updatePrivacyMutation({
      variables: data,
    })
  }

  return [updatePrivacy, updatePrivacyResult]
}

export default usePost
