import { gql } from '@apollo/client'

export const UPDATE_SKILLS = gql`
  mutation UpdateUserSkills($id: [Int]) {
    UpdateUserSkills(id: $id) {
      response
    }
  }
`

export const DELETE_SKILL = gql`
  mutation DeleteUserSkill($id: Int) {
    DeleteUserSkill(id: $id) {
      response
    }
  }
`
