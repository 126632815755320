import styled from 'styled-components'

export const CreatePostWrapper = styled.div`
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 35px 20px;

  .active {
    background-color: ${(props) => props.theme.colors.primary[700]};
    color: ${(props) => props.theme.colors.text[100]};
  }
`

export const CreatePost = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  button {
    width: 100%;
    /* border: 1px solid ${(props) => props.theme.colors.background[150]}; */
  }
`

export const ParagraphText = styled.p`
  height: 60px;
`
