import { useQuery } from '@apollo/client'
import { GET_PEOPLE } from 'services/queries/getPeopleYouMayKnow'

const usePeopleMayKnow = () => {
  const { data } = useQuery(GET_PEOPLE)

  const people = data?.PeopleYouMayKnow?.data

  return [people]
}

export default usePeopleMayKnow
