import { useMutation } from '@apollo/client'
import getDateAsObject from 'utils/getDateAsObject'
import {
  ADD_WORK_EXPERIENCE,
  EDIT_WORK_EXPERIENCE,
  DELETE_WORK_EXPERIENCE,
} from 'services/mutations/work'

const useWork = () => {
  const [addWorkMutation, addWorkResult] = useMutation(ADD_WORK_EXPERIENCE, {
    errorPolicy: 'all',
    refetchQueries: ['getUser'],
  })

  const [editWorkMutation, editWorkResult] = useMutation(EDIT_WORK_EXPERIENCE, {
    errorPolicy: 'all',
    refetchQueries: ['getUser'],
  })

  const [deleteWorkMutation, deleteWorkResult] = useMutation(
    DELETE_WORK_EXPERIENCE,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser'],
    }
  )

  const workOriginal = (func, data) => {
    const startDateObject = getDateAsObject(data.start_date)
    const endDateObject = getDateAsObject(data.end_date)

    func({
      variables: {
        ...data,
        start_year: startDateObject?.year,
        start_month: startDateObject?.month,
        end_year: endDateObject?.year,
        end_month: endDateObject?.month,
      },
    })
  }

  const addWork = (data) => {
    workOriginal(addWorkMutation, data)
  }

  const editWork = (data, id) => {
    workOriginal(editWorkMutation, { ...data, id })
  }

  const deleteWork = (id) => {
    deleteWorkMutation({
      variables: {
        id,
      },
    })
  }

  return {
    addWork,
    addWorkResult,
    editWork,
    editWorkResult,
    deleteWork,
    deleteWorkResult,
  }
}

export default useWork
