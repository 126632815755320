import Header from 'components/Header'
import Footer from 'components/Footer'
import AuthorizedUserProvider from 'contexts/AuthorizedUser'
import * as S from './styled'

const DefaultLayout = (props) => {
  return (
    <AuthorizedUserProvider>
      <S.HomeContent>
        <Header />
        {props.children}
        <Footer />
      </S.HomeContent>
    </AuthorizedUserProvider>
  )
}

export default DefaultLayout
