import { useMutation } from '@apollo/client'
import getDateAsObject from 'utils/getDateAsObject'
import {
  ADD_EDUCATION,
  EDIT_EDUCATION,
  DELETE_EDUCATION,
} from 'services/mutations/education'

const useEducation = () => {
  const [addEducationMutation, addEducationResult] = useMutation(
    ADD_EDUCATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser'],
    }
  )

  const [editEducationMutation, editEducationResult] = useMutation(
    EDIT_EDUCATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser'],
    }
  )

  const [deleteEducationMutation, deleteEducationResult] = useMutation(
    DELETE_EDUCATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser'],
    }
  )

  const educationOriginal = (func, data) => {
    const startDateObject = getDateAsObject(data.start_date)
    const endDateObject = getDateAsObject(data.end_date)

    func({
      variables: {
        ...data,
        start_year: startDateObject?.year,
        start_month: startDateObject?.month,
        end_year: endDateObject?.year,
        end_month: endDateObject?.month,
      },
    })
  }

  const addEducation = (data) => {
    educationOriginal(addEducationMutation, data)
  }

  const editEducation = (data, id) => {
    educationOriginal(editEducationMutation, { ...data, id })
  }

  const deleteEducation = (id) => {
    deleteEducationMutation({
      variables: {
        id,
      },
    })
  }

  return {
    addEducation,
    addEducationResult,
    editEducation,
    editEducationResult,
    deleteEducation,
    deleteEducationResult,
  }
}

export default useEducation
