import { gql } from '@apollo/client'

export const GET_PEOPLE = gql`
  query PeopleYouMayKnow {
    PeopleYouMayKnow(limit: 10, page: 1) {
      data {
        id
        slug
        name
        sex
        surname
        avatar {
          path
        }
        state {
          name
        }
        followed_by_you
        is_following_you
      }
    }
  }
`
