import { gql } from '@apollo/client'

export const GET_USER_POSTS = gql`
  query userFeed($limit: Int, $page: Int, $slug: String) {
    userFeed(limit: $limit, page: $page, slug: $slug) {
      data {
        is_shared
        can_help
        og_url
        end_date
        organization_id
        shared_post {
          id
          title
          type
          approved
          skills {
            name
          }
          slug
          can_help
          description
          workers
          volunt
          start_date
          end_date
          cover
          latitude
          longitude
          likes_count
          comments_count
          shares_count
          likedUser
          isPostSharer
          is_started
          is_finished
          comments {
            id
            body
            replies {
              id
              body
            }
          }
          user {
            id
            slug
            sex
            name
            type
            surname
            email
            state {
              name
            }
            avatar {
              id
              path
            }
            is_me
            is_online
            is_activated
            state {
              name
            }
          }
        }
        id
        title
        type
        approved
        description
        volunt
        likes_count
        comments_count
        shares_count
        share_url
        likedUser
        cover
        isPostSharer
        isPostBookmarked
        created_at
        start_date
        user_id
        is_started
        is_finished
        workers
        skills {
          id
          name
          slug
          own
        }
        user {
          id
          slug
          name
          surname
          sex
          avatar {
            id
            path
          }
          cover {
            id
            path
          }
          is_me
          avatar {
            path
          }
          state {
            name
          }
        }
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`
