import styled from 'styled-components'

export const Helpers = styled.div`
  display: flex;
  align-items: center;
  gap: 56px;
`

export const BulletCont = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`
export const CategoriesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  > span {
    color: ${(props) => props.theme.colors.text[500]};
    font-size: ${(props) => props.theme.fontSizes.xs};
  }
`

export const HelpersInput = styled.div`
  display: flex;
  gap: 15px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 20px;
  }
`

export const InputContainer = styled.div`
  width: 100%;
`

export const VolunteersQuantity = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`

export const Message = styled.p`
  padding-top: 3px;
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.error};
`

export const CoverSelectNotificationText = styled.p`
  color: #ffce07;
  font-weight: bold;
`

export const EditorSwitcher = styled.p`
  top: 12px;
  right: 16px;
  cursor: pointer;
  position: absolute;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
  cursor: pointer;
  justify-content: flex-end;

  path {
    stroke: #000;
  }
`

export const MetaImage = styled.img`
  max-height: 250px;
  width: 100%;
  margin-top: 10px;
  position: relative;
  bottom: 10px;
  border-radius: 10px;
`
