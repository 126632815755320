import { gql } from '@apollo/client'

export const EDIT_USER = gql`
  mutation UpdateInformation(
    $name: String
    $surname: String
    $date_of_birth: String
    $phone: String
    $address: String
    $email: String
    $state_id: Int
    $sex: Int
    $about: String
    $current_password: String
    $password: String
    $password_confirmation: String
  ) {
    UpdateInformation(
      name: $name
      surname: $surname
      date_of_birth: $date_of_birth
      phone: $phone
      address: $address
      email: $email
      state_id: $state_id
      sex: $sex
      about: $about
      current_password: $current_password
      password: $password
      password_confirmation: $password_confirmation
    ) {
      response
    }
  }
`
