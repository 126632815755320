import styled from 'styled-components'

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-height: 50px;
  margin-top: 10px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`

export const ModalText = styled.div`
  display: flex;
  flex-direction: column;
`

export const NetworkInfo = styled.div`
  width: 100%;

  h5 {
    color: ${(props) => props.theme.colors.text['500']};
  }

  p {
    margin-top: 8px;
    color: ${(props) => props.theme.colors.text['700']};
  }

  input {
    padding: 8px 5px;
    border-bottom: 1px solid #cbcbcb;
    border-radius: 0;
  }
`

export const SocialNetworkItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  svg {
    width: 25px;
    height: 25px;
  }

  button {
    width: 100%;
  }
  ${NetworkInfo} {
    input {
      width: 100%;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 100%;
    width: 100%;
    svg {
      width: 24px;
      height: 24px;
    }

    .social-items {
      height: 100%;
      overflow-y: scroll;
    }
  }
`

export const ErrorMessage = styled.div`
  span{
    color: #D52F35;
    padding:10px;
  }
`
