import { useState, useEffect } from 'react'
import { debounce } from 'lodash-es'

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)

  const updateWindowDimensions = debounce(() => {
    const newWidth = window.innerWidth
    setWidth(newWidth)
  }, 100)

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions)

    return () => {
      window.removeEventListener('resize', updateWindowDimensions)
    }
  }, [width])

  return width
}

export default useWindowWidth
