import { gql } from '@apollo/client'

export const ADD_ORGANIZATION = gql`
  mutation CreateOrganization(
    $title: String
    $description: String
    $cover: Upload
    $skill_id: [Int]
    $approved: Int
  ) {
    CreateOrganization(
      title: $title
      description: $description
      cover: $cover
      skill_id: $skill_id
      approved: $approved
    ) {
      response
    }
  }
`

export const EDIT_ORGANIZATION = gql`
  mutation EditOrganization(
    $id: Int
    $title: String
    $description: String
    $cover: Upload
    $skill_id: [Int]
  ) {
    EditOrganization(
      id: $id
      title: $title
      description: $description
      cover: $cover
      skill_id: $skill_id
    ) {
      response
    }
  }
`

export const JOIN_ORGANIZATION = gql`
  mutation JoinToOrganization($organization_id: Int) {
    JoinToOrganization(organization_id: $organization_id) {
      response
    }
  }
`

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: Int) {
    DeleteOrganization(id: $id) {
      response
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganizationInformation(
    $organization_id: Int
    $email: String
    $phone: String
    $state_id: Int
    $description: String
  ) {
    UpdateOrganizationInformation(
      organization_id: $organization_id
      email: $email
      phone: $phone
      state_id: $state_id
      description: $description
    ) {
      response
    }
  }
`

export const UPDATE_ORGANIZATION_SKILLS = gql`
  mutation UpdateOrganizationSkills($id: [Int], $organization_id: Int) {
    UpdateOrganizationSkills(id: $id, organization_id: $organization_id) {
      response
    }
  }
`

export const DELETE_ORGANIZATION_SKILL = gql`
  mutation DeleteOrganizationSkill($organization_id: Int, $id: Int) {
    DeleteOrganizationSkill(organization_id: $organization_id, id: $id) {
      response
    }
  }
`

export const APPROVE_USER_TO_ORGANIZATION = gql`
  mutation ApproveUserToOrganization($organization_id: Int, $user_id: Int) {
    ApproveUserToOrganization(
      organization_id: $organization_id
      user_id: $user_id
    ) {
      response
    }
  }
`

export const DECLINE_USER_TO_ORGANIZATION = gql`
  mutation DeclineUserToOrganization($organization_id: Int, $user_id: Int) {
    DeclineUserToOrganization(
      organization_id: $organization_id
      user_id: $user_id
    ) {
      response
    }
  }
`

export const KICK_USER_FROM_ORGANIZATION = gql`
  mutation KickOrganizationMember($organization_id: Int, $user_id: Int) {
    KickOrganizationMember(
      organization_id: $organization_id
      user_id: $user_id
    ) {
      response
    }
  }
`

export const CANCEL_REQUEST_TO_ORGANIZATION = gql`
  mutation CancelJoinRequestToOrganization($organization_id: Int) {
    CancelJoinRequestToOrganization(organization_id: $organization_id) {
      response
    }
  }
`

export const LEAVE_ORGANIZATION = gql`
  mutation LeaveOrganization($organization_id: Int) {
    LeaveOrganization(organization_id: $organization_id) {
      response
    }
  }
`
