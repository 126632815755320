import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'

import Container from 'components/Container'
import Button from 'components/Forms/Button'
import Select from 'components/Forms/Select'
import Logo from 'components/Logo'
import { languages } from 'constants/global'
import AuthorizedUser from './AuthorizedUser'

import { SEARCH_INFO } from 'services/queries/searchInfo'
import SearchResults from './SearchResults/SearchResults'

import { useAuthToken } from 'hooks/useAuthToken'
import useComponentVisible from 'hooks/useComponentVisible'
import useQueryString from 'hooks/useQueryString'
import useSwitchLocale from 'hooks/useSwitchLocale'
import Icon from 'components/Icon'

import i18n from '../../i18n'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const Header = ({ showAsOtherUser, setShowAsOtherUser }) => {
  const { me } = useAuthorizedUser()

  const [authToken] = useAuthToken()
  const authorized = !!authToken
  const { pathname } = useLocation()

  const { result: searchVal, history } = useQueryString('value')

  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useState(searchVal)
  const { t } = useTranslation()
  const [switchLocale] = useSwitchLocale()

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible()

  const [searchInfo, { data, loading }] = useLazyQuery(SEARCH_INFO, {
    variables: {
      query: searchValue,
    },
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    setIsComponentVisible(false)
  }, [pathname])

  useEffect(() => {
    searchInfo()
  }, [searchValue])

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      history.push(`/search?type=users&value=${searchValue}`)
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    i18n.changeLanguage(me?.active_locale)
  }, [me?.active_locale])

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng?.value)
    switchLocale(lng?.value)
  }

  return (
    <S.Header className="header">
      <Container>
        <S.HeaderContent className="header-content">
          <Logo />

          {authorized && (
            <>
              <section ref={ref}>
                <S.SearchBox>
                  <S.SearchInput
                    type="search"
                    onKeyUp={onEnter}
                    placeholder={t('common.search')}
                    value={searchValue}
                    onFocus={() => setIsComponentVisible(true)}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  {searchValue && (
                    <S.DeleteSearchValueButton
                      onClick={() => setSearchValue('')}
                    >
                      <Icon filename="close-square" />
                    </S.DeleteSearchValueButton>
                  )}
                </S.SearchBox>
                {isComponentVisible && !!searchValue && (
                  <SearchResults
                    data={data}
                    loading={loading}
                    onClick={() => setIsComponentVisible(false)}
                    searchValue={searchValue}
                  />
                )}
              </section>
              <AuthorizedUser />
            </>
          )}

          {!authorized && (
            <S.HeaderActions className="header-action">
              <S.LanguageSwitcher>
                <Select
                  defVal={i18n.language}
                  onChange={(e) => changeLanguage(e)}
                  options={languages}
                  icon="globe"
                  onClick={() => setOpen(!open)}
                  open={open}
                />
              </S.LanguageSwitcher>
              <Link to="/login">
                <Button type="primary">{t('register.login')}</Button>
              </Link>
            </S.HeaderActions>
          )}
        </S.HeaderContent>
      </Container>
      {showAsOtherUser && (
        <S.ShowAsOtherUserContainer>
          <div></div>
          <p>{t('user.showAsOtherUser')}</p>
          <S.CloseButton onClick={() => setShowAsOtherUser(false)}>
            <Icon filename="close-cancel" />
            <S.CloseButtonText>{t('user.close')}</S.CloseButtonText>
          </S.CloseButton>
        </S.ShowAsOtherUserContainer>
      )}
    </S.Header>
  )
}

export default Header
