import { GET_POST_SUGGESTIONS } from '../services/queries/getPostSuggestions'
import { useQuery } from '@apollo/client'

const usePostSuggestions = (id) => {
  const { loading, error, data } = useQuery(GET_POST_SUGGESTIONS, {
    errorPolicy: 'all',
    variables: {
      id,
    },
  })

  return { loading, error, data }
}

export default usePostSuggestions
