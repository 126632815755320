import { useMutation } from '@apollo/client'
import { SWITCH_LOCALE } from 'services/mutations/switchLocale'

const useSwitchLocale = () => {
  const [SwitchLocale] = useMutation(SWITCH_LOCALE, {
    errorPolicy: 'all',
    refetchQueries: ['SwitchLocale'],
  })

  const switchLocale = (data) => {
    SwitchLocale({
      variables: {
        code: data,
      },
    })
  }

  return [switchLocale]
}

export default useSwitchLocale
