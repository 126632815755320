import { useApolloClient } from '@apollo/react-hooks'
import { useAuthToken } from 'hooks/useAuthToken'
import { useHistory } from 'react-router-dom'
import useGoogleAuth from './useGoogleAuth'

const useLogout = () => {
  const history = useHistory()
  const { signOut } = useGoogleAuth()
  const [, , removeAuthToken] = useAuthToken()
  const apolloClient = useApolloClient()

  const logout = async () => {
    await apolloClient.clearStore()
    removeAuthToken()
    signOut()
    history.push('/')
  }

  return logout
}

export default useLogout
