import styled from 'styled-components'

export const AvatarWrapper = styled.div`
  position: relative;

  span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.primary[700]};
    position: absolute;
    bottom: -0px;
    right: -0px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
    }
  }
`

export const Avatar = styled.div`
  width: ${(props) => props.avatarWidth || '70px'};
  height: ${(props) => props.avatarHeight || '70px'};
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.colors.primary[700]};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const UserAvatar = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 100%;
`

export const EmptyAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.colors.primary[700]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
  }
`

export const SimpleAvatar = styled(Avatar)`
  background: unset;
  border: unset;
  width: ${(props) => props.width || '100px'};
  height: ${(props) => props.height || '100px'};
`

export const AvatarLive = styled.div`
  position: relative;

  i {
    display: block;
    width: 13px;
    height: 13px;
    background-color: ${(props) => props.theme.colors.success};
    border-radius: 100%;
    border: 3px solid ${(props) => props.theme.colors.white};
    position: absolute;
    top: 2px;
    right: 0px;
  }
`

export const AvatarWithInfo = styled.div`
  display: grid;

  .simple-avatar {
    grid-area: avatar;
    width: 40px;
    height: 40px;
  }

  h5 {
    grid-area: title;
  }

  p {
    grid-area: description;
  }
`
