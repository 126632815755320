import { gql } from '@apollo/client'

export const ADD_WORK_EXPERIENCE = gql`
  mutation AddWorkExperience(
    $company: String
    $position: String
    $start_month: Int
    $start_year: Int
    $end_month: Int
    $end_year: Int
    $still_working: Boolean
  ) {
    AddWorkExperience(
      company: $company
      position: $position
      start_month: $start_month
      start_year: $start_year
      end_month: $end_month
      end_year: $end_year
      still_working: $still_working
    ) {
      response
    }
  }
`

export const EDIT_WORK_EXPERIENCE = gql`
  mutation EditWorkExperience(
    $id: Int
    $company: String
    $position: String
    $start_month: Int
    $start_year: Int
    $end_month: Int
    $end_year: Int
    $still_working: Boolean
  ) {
    EditWorkExperience(
      id: $id
      company: $company
      position: $position
      start_month: $start_month
      start_year: $start_year
      end_month: $end_month
      end_year: $end_year
      still_working: $still_working
    ) {
      response
    }
  }
`

export const DELETE_WORK_EXPERIENCE = gql`
  mutation DeleteWorkExperience($id: Int) {
    DeleteWorkExperience(id: $id) {
      response
    }
  }
`
