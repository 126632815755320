import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_CONVERSATION } from 'services/queries/getConversation'
import {
  ADD_USER_TO_CONVERSATION,
  SEND_MESSAGE,
  SEND_IMAGE_TO_CONVERSATION,
} from 'services/mutations/addUserToConversation'
import {
  APPROVE_TRANSACTION,
  REJECT_TRANSACTION,
} from 'services/mutations/requestVolunts'
import { GET_MESSAGES } from 'services/queries/getMessages'
import { FOLLOW_USER } from '../services/mutations/followUser'

export const fetchConversationDefaultParams = { limit: 20, page: 1 }

const useConversation = () => {
  const [sendMessageToChat, { data: messageData }] = useMutation(SEND_MESSAGE, {
    errorPolicy: 'all',
  })

  const [getConversationQuery, { data: conversationData }] = useLazyQuery(
    GET_CONVERSATION,
    {
      errorPolicy: 'all',
    }
  )

  const [getAllMessageToChatQuery, { data: messageChatData }] = useLazyQuery(
    GET_MESSAGES,
    {
      errorPolicy: 'all',
    }
  )

  const [sendMessageMutation, { data: sendToConversationData }] = useMutation(
    ADD_USER_TO_CONVERSATION,
    {
      errorPolicy: 'all',
      refetchQueries: [
        { query: GET_CONVERSATION, variables: fetchConversationDefaultParams },
      ],
    }
  )

  const [sendImageMutation, { data: sendImageData }] = useMutation(
    SEND_IMAGE_TO_CONVERSATION,
    {
      errorPolicy: 'all',
    }
  )

  const [approveMutation, approveResult] = useMutation(APPROVE_TRANSACTION, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: GET_CONVERSATION, variables: fetchConversationDefaultParams },
    ],
  })

  const [rejectMutation, rejectResult] = useMutation(REJECT_TRANSACTION, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: GET_CONVERSATION, variables: fetchConversationDefaultParams },
    ],
  })

  const [addFollowMutation, followUserResult] = useMutation(FOLLOW_USER, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: GET_CONVERSATION, variables: fetchConversationDefaultParams },
    ],
    // { query: SEARCH_USERS, variables: {page: 1, limit: 30, query: 'ge'} }
  })

  const approveHandler = (data) => {
    approveMutation({
      variables: {
        transaction_id: Number(data),
      },
    })
  }

  const rejectHandler = (data) => {
    rejectMutation({
      variables: {
        transaction_id: Number(data),
      },
    })
  }

  const getConversationUser = (data) => {
    getConversationQuery({
      fetchPolicy: 'network-only',
      variables: data
        ? {
            ...data,
          }
        : fetchConversationDefaultParams,
    })
  }

  const getAllMessageToChat = (data) => {
    getAllMessageToChatQuery({
      variables: {
        conversation_id: Number(data),
        limit: 100,
        page: 1,
      },
    })
  }

  const sendMessageToConversation = (data) => {
    const json = JSON.stringify(data)
    const withStrings = JSON.parse(json, (key, val) =>
      key === 'conversation_id' || key === 'user_id' ? Number(val) : val
    )
    sendMessageMutation({
      variables: {
        message: withStrings?.message,
        conversation_id: withStrings?.conversation_id,
        file: data?.file || '',
        image: data?.image || '',
      },
    })
  }

  const sendImageConversation = (data) => {
    const json = JSON.stringify(data)
    const withStrings = JSON.parse(json, (key, val) =>
      key === 'conversation_id' || key === 'user_id' ? Number(val) : val
    )
    sendImageMutation({
      variables: {
        file: withStrings?.image,
        conversation_id: withStrings?.conversation_id,
      },
    })
  }

  const sentMessageToChatUser = (data) => {
    // sendMessageToChat({
    //     variables: data
    // })
  }

  const followUser = (data) => {
    addFollowMutation({
      variables: {
        user_id: data,
      },
    })
  }

  return {
    getConversationUser,
    conversationData,
    sendMessageToConversation,
    sendToConversationData,
    sentMessageToChatUser,
    messageData,
    getAllMessageToChat,
    messageChatData,
    sendImageConversation,
    sendImageData,
    approveHandler,
    rejectHandler,
    approveResult,
    rejectResult,
    followUser,
    followUserResult,
  }
}

export default useConversation
