const { gql } = require('@apollo/client')

export const GET_POST_SUGGESTIONS = gql`
  query getSuggestions($id: Int) {
    post(id: $id) {
      user_id
      is_finished
      start_date
      end_date
      comments_count
      shares_count
      approved
      workers
      description
      cover
      id
      title
      volunt
      og_url
      share_url
      type
      comments {
        id
        parent_id
        created_at
        body
        user {
          id
          slug
          name
          surname
          email
          avatar {
            id
            path
          }
        }
        post {
          id
        }
        commentCount
        likedUser
        commentReplyCount
        currentCommentLikes
        replies {
          id
          parent_id
          created_at
          body
          user {
            id
            slug
            name
            surname
            email
            avatar {
              id
              path
            }
          }
          post {
            id
          }
          commentCount
          likedUser
          commentReplyCount
          currentCommentLikes
        }
      }
      skills {
        name
      }
      is_started
      can_help
      user {
        id
        slug
        is_me
        conversation_id
        sex
        name
        state {
          name
        }
        surname
        avatar {
          id
          path
        }
      }
      volunteer_requests {
        id
        name
        surname
        address
        state {
          name
        }
        avatar {
          id
          disk_name
          file_name
          file_size
          content_type
          title
          description
          path
          created_at
          updated_at
        }
        sex
        latitude
        longitude
        requests_count
      }
      volunteers {
        id
        name
        surname
        address
        volunt
        state {
          name
        }
        avatar {
          id
          disk_name
          file_name
          file_size
          content_type
          title
          description
          path
          created_at
          updated_at
        }
        sex
      }
    }
  }
`
