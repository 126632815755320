import React from 'react'
import * as S from './styled'

const BottomMenu = ({ show, children, onBackdropClick }) => {
  return (
    <>
      <S.Backdrop onClick={onBackdropClick} show={show} />
      <S.BottomModal show={show}>{children}</S.BottomModal>
    </>
  )
}

export default BottomMenu
