import styled from 'styled-components'

export const FriendsList = styled.div`
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 25px 15px;
  display: ${(props) => (props.visibility ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  gap: 30px;
  transition: ease-in-out 0.6s;

  :hover {
    p {
      display: flex !important;
    }
  }

  @media screen and(max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`
export const UserListBlock = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > div {
    width: 50px;
  }
  p {
    padding-left: 12px;
    width: 180px;
    height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: none;

    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .simple-avatar {
    width: 50px;
    height: 50px;
  }

  div {
    height: 50px;
    width: 50px;
  }
`
