import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { educationSchema } from 'validation'
import { yupResolver } from '@hookform/resolvers/yup'
import useEducation from 'hooks/useEducation'

import Modal from 'components/Modal'
import Input from 'components/Forms/Input'
import Button from 'components/Forms/Button'

import * as S from '../styled'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const CreateEducation = (props) => {
  const [endError, setEndError] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endEduDate, setEndEduDate] = useState('')
  const [editModal, setEditModal] = useState(undefined)
  // const [checked, setChecked] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: useMemo(() => {
      return props.data
    }, [props.data]),
    resolver: yupResolver(educationSchema(editModal)),
  })

  const { addEducation, editEducation } = useEducation()
  const { t } = useTranslation()

  const stillStudying = watch('still_studying')
  const endDate = watch('end_date')
  const endDateExist = !!endDate || stillStudying

  useEffect(() => {
    if (endDateExist) setEndError(null)
  }, [endDateExist])

  useEffect(() => {
    setStartDate(props.data?.start_month + '-' + props.data?.start_year + '-1')
    setEndEduDate(
      props.data?.still_studying
        ? new Date()
        : props.data?.end_month + '-' + props.data?.end_year + '-1'
    )
    // setChecked(props.data?.still_studying)
    if (props.data?.course) {
      setEditModal('update')
    }
  }, [props.show])

  const onSubmit = (e) => {
    const endDateFunc = () => {
      if (e.end_date) {
        setEndEduDate(e.end_date)
        return e.end_date
      } else if (e.still_studying) {
        return null
      } else {
        return moment(endEduDate)._d
      }
    }

    const defaultValues = {
      ...e,
      start_date: e.start_date ? e.start_date : moment(startDate)._d,
      end_date: endDateFunc(),
      end_month: e.still_studying ? null : e.end_month,
      end_year: e.still_studying ? null : e.end_year,
    }

    if (!endEduDate && !e.still_studying) {
      setEndError(t('user.warning'))
      return
    }
    if (props.data?.course) {
      const id = props.data?.id
      editEducation(defaultValues, id)
    } else {
      addEducation(e)
    }

    if (typeof props.onClose === 'function') {
      props.onClose()
    }
    props.setData({})
  }

  useEffect(() => {
    if (props.data) {
      reset(props.data)
    }
  }, [props.show])

  const onClose = () => {
    props.setData({})
    props.onClose()
  }

  return (
    <Modal
      title={
        props.data?.course ? t('user.editEducation') : t('user.addEducation')
      }
      {...props}
      onClose={onClose}
    >
      <S.ModalForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          register={register}
          name="educational_institution"
          error={errors?.educational_institution}
          placeholder={t('user.educationalInstitution')}
        />
        <Input
          register={register}
          name="course"
          error={errors?.course}
          placeholder={t('user.course')}
        />
        <Input
          register={register}
          name="degree"
          placeholder={t('user.educationDegree')}
        />
        <Input
          register={register}
          name="start_date"
          error={errors?.start_date}
          type="date"
          placeholder={t('user.startDate')}
          defVal={startDate}
        />
        <Input
          register={register}
          disabled={stillStudying}
          type="date"
          name="end_date"
          placeholder={t('user.endDate')}
          defVal={endEduDate}
        />
        {endError && <S.Message>{endError}</S.Message>}

        <S.FormActions>
          <Input
            type="checkbox"
            register={register}
            name="still_studying"
            // checked={checked}
            // onClick={()=>setChecked(!checked)}
          >
            {t('user.stillStudying')}
          </Input>
          <Button type="primary" className="submit" submit>
            {props.data?.course ? t('user.edit') : t('user.add')}
          </Button>
        </S.FormActions>
      </S.ModalForm>
    </Modal>
  )
}

export default CreateEducation
