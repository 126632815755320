import * as yup from 'yup'

const schema = (arg = undefined) => {
  return yup.object({
    educational_institution: yup.string().required('აუცილებელი ველი'),
    course: yup.string().required('აუცილებელი ველი'),
    start_date: arg ? null : yup.date().required('აუცილებელი ველი'),
  })
}

export default schema
