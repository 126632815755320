import * as S from './styled'

const Switch = ({ children, variant, leftText, rightText, ...rest }) => {
  switch (variant) {
    case 'toggle': {
      return (
        <S.SwitchToggle className="switch-toggle-input">
          <S.Input type="checkbox" {...rest} />
          <div>
            <span>{leftText}</span>
            <span>{rightText}</span>
          </div>
        </S.SwitchToggle>
      )
    }
    default:
      return (
        <S.Switch>
          <S.Input type="checkbox" {...rest} />
          <i />
          <span>{children}</span>
        </S.Switch>
      )
  }
}

export default Switch
