import { gql } from '@apollo/client'

export const VOLUNTEER_WORK_EXPERIENCE_FRAGMENT = gql`
  fragment VolunteerWorkExperience on VolunteerWorkExperience {
    id
    company
    position
    start_month
    start_year
    end_month
    end_year
    still_working
  }
`
