import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: ${(props) => props.theme.fontSizes.sm};
    color: ${(props) => props.theme.colors.text[500]};
    font-family: primaryFont;
  }

  a {
    text-decoration: unset;
  }

  svg {
    overflow: visible;
  }

  h1,h2,h3,h4,h5,h6 {
    color: ${(props) => props.theme.colors.text[700]};
    margin: 0px;
    
    span {
      font-size: inherit;
      color: ${(props) => props.theme.colors.primary[700]};
    }
  }

  h2 {
    font-size: ${(props) => props.theme.fontSizes.xl};
  }
  
#root{
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0;
  }
}
`

export default GlobalStyle
