import styled from 'styled-components'

export const MyOrganizations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 10px;
`

export const Organizations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Organization = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const OrganizationInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    margin-top: 4px;
  }
  h5{
    width: 200px;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;

    a{
      width: 100%;
    }
  }
`

export const OrganizationAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`

export const SettingsMenu = styled.div`
  position: relative;

  div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  p {
    font-weight: bold;
    font-size: 12px;
  }
`
