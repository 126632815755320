import { gql } from '@apollo/client'

export const SEARCH_INFO = gql`
  query search($query: String) {
    search(query: $query) {
      users {
        id
        name
        slug
        surname
        sex
        avatar {
          path
          id
        }
        state {
          name
        }
      }
      requests {
        id
        title
      }
      organizations {
        id
        title
      }
    }
  }
`
