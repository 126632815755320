import * as yup from 'yup'

const schema = yup.object({
  login: yup
    .string()
    .email('ელ.ფოსტა არასწორია')
    .matches(
      '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}',
      'შეიყვანეთ მხოლოდ ლათინური სიმბოლოები'
    )
    .required('ველის შევსება სავალდებულოა'),
  password: yup.string().required('ველის შევსება სავალდებულოა'),
})

export default schema
