import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './i18n/en.json'
import ge from './i18n/ge.json'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      ka: {
        translation: ge,
      },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'ka'],
    lng: "ka",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
