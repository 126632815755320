import Icon from 'components/Icon'
import _ from 'lodash'
import * as S from './styled'

const blackList = ['src', 'type', 'className']

const Avatar = (props) => {
  if (!props.src) {
    return (
      <S.EmptyAvatar
        sex={props.sex === 2}
        className="avatar empty-avatar"
        {..._.omit(props, blackList)}
      >
        {props.sex === 1 && <S.UserAvatar src={'/img/user/maleAvatar.png'} />}
        {props.sex === 2 && <S.UserAvatar src={'/img/user/femaleAvatar.png'} />}
      </S.EmptyAvatar>
    )
  }

  switch (props.type) {
    case 'simple': {
      return (
        <S.SimpleAvatar
          className="avatar simple-avatar"
          height={props.height}
          width={props.width}
          {..._.omit(props, blackList)}
        >
          <img src={props.src} alt="User simple Avatar" />
        </S.SimpleAvatar>
      )
    }

    default:
      return (
        <S.AvatarWrapper
          className="avatar default-avatar"
          {..._.omit(props, blackList)}
        >
          <S.Avatar
            avatarWidth={props.avatarWidth}
            avatarHeight={props.avatarHeight}
          >
            <img src={props.src} alt="User Avatar" />
          </S.Avatar>
          <span>
            <Icon filename="star" />
          </span>
        </S.AvatarWrapper>
      )
  }
}

export const AvatarLive = ({ isOnline, sex, ...rest }) => {
  return (
    <S.AvatarLive className="avatar-online">
      <Avatar sex={sex} {...rest} />
      {isOnline && <i className="is-online"></i>}
    </S.AvatarLive>
  )
}

export default Avatar
