import { lazy } from 'react'
import AuthorizedUserProvider from 'contexts/AuthorizedUser/AuthorizedUser'
import UserProvider from 'contexts/UserProvider'

import AuthLayout from 'layouts/auth'
import OnlyHeaderLayout from 'layouts/onlyheader'
import AuthorizedLayout from 'layouts/authorized'

const Home = lazy(() => import('pages/Home'))
const FAQ = lazy(() => import('pages/FooterNavigation/FAQ'))
const PrivacyPolicy = lazy(() => import('pages/FooterNavigation/PrivacyPolicy'))
const Contact = lazy(() => import('pages/FooterNavigation/Contact'))
const TermsAndConditions = lazy(() =>
  import('pages/FooterNavigation/TermsAndConditions')
)
const Search = lazy(() => import('pages/Search'))
const AboutUs = lazy(() => import('pages/FooterNavigation/AboutUs'))
const Login = lazy(() => import('pages/Auth/Login'))
const Register = lazy(() => import('pages/Auth/Register'))
const SuccessMessages = lazy(() => import('pages/Auth/SuccessMessages'))
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'))
const UserActive = lazy(() => import('pages/Auth/ActivateUser'))
const Skills = lazy(() => import('pages/Skills'))
const Error = lazy(() => import('pages/Error'))
const PeopleYouMayKnow = lazy(() => import('pages/PeopleYouMayKnow'))
const Feed = lazy(() => import('pages/Feed'))
const User = lazy(() => import('pages/User'))
const Followers = lazy(() => import('pages/Followers'))
const Organizations = lazy(() => import('pages/Organizations'))
const Organization = lazy(() => import('pages/Organization'))
const Request = lazy(() => import('pages/Request'))
const Post = lazy(() => import('pages/Post'))
const Notifications = lazy(() => import('pages/Notifications'))
const Messages = lazy(() => import('pages/Messages'))
const ComingSoon = lazy(() => import('pages/ComingSoon'))
const BookmarkPosts = lazy(() => import('pages/BookmarkPosts'))

const FeedLayout = (props) => (
  <AuthorizedLayout
    {...props}
    infos={['createPost', 'postNearby', 'youMayKnow']}
  />
)

const UserLayout = (props) => (
  <AuthorizedLayout
    {...props}
    provider={UserProvider}
    infos={['userSidebarSettings', 'myOrganizations', 'youMayKnow']}
  />
)

const NotificationsLayout = (props) => (
  <AuthorizedLayout
    notificationsPage
    {...props}
    provider={UserProvider}
    infos={['userSidebarSettings', 'youMayKnow']}
  />
)

const OrganizationLayout = (props) => (
  <AuthorizedLayout {...props} infos={['youMayKnow']} />
)

const RequestInnerLayout = (props) => (
  <AuthorizedLayout
    provider={AuthorizedUserProvider}
    {...props}
    infos={['requestRightBar']}
    hideFooter
  />
)

const PostInnerLayout = (props) => (
  <AuthorizedLayout {...props} infos={['postNearby', 'youMayKnow']} />
)

const getRoutes = () => [
  { path: '/', name: 'Home', Component: Home, exact: true },
  { path: '/faq', name: 'FAQ', Component: FAQ, exact: true },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    Component: PrivacyPolicy,
    exact: true,
  },
  { path: '/contact', name: 'Contact', Component: Contact, exact: true },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    Component: TermsAndConditions,
    exact: true,
  },
  {
    path: '/search',
    name: 'Search',
    Component: Search,
    exact: true,
    isProtected: true,
  },
  { path: '/about-us', name: 'AboutUs', Component: AboutUs, exact: true },
  {
    path: '/login',
    name: 'Login',
    Component: Login,
    exact: true,
    layout: AuthLayout,
  },
  {
    path: '/register',
    name: 'Register',
    Component: Register,
    exact: true,
    layout: AuthLayout,
  },
  {
    path: '/user-active',
    name: 'User Active',
    Component: UserActive,
    exact: true,
    layout: AuthLayout,
  },
  {
    path: '/success/:type',
    name: 'Successful',
    Component: SuccessMessages,
    exact: true,
    layout: OnlyHeaderLayout,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    Component: ResetPassword,
    exact: true,
    layout: AuthLayout,
  },
  {
    path: '/skills',
    name: 'Skills',
    Component: Skills,
    exact: true,
    layout: OnlyHeaderLayout,
    isProtected: true,
  },
  {
    path: '/peopleyoumayknow',
    name: 'PeopleYouMayKnow',
    Component: PeopleYouMayKnow,
    exact: true,
    isProtected: true,
  },
  {
    path: '/feed',
    name: 'Feed',
    Component: Feed,
    exact: true,
    layout: FeedLayout,
    isProtected: true,
  },
  {
    path: ['/user/:slug'],
    name: 'User',
    Component: User,
    exact: true,
    layout: UserLayout,
    isProtected: true,
  },
  {
    path: ['/user/:id/:followers', '/user/:id/:followings'],
    name: 'Followers',
    Component: Followers,
    exact: true,
    isProtected: true,
  },
  {
    path: '/organizations',
    name: 'Organizations',
    Component: Organizations,
    exact: true,
    isProtected: true,
  },
  {
    path: '/organization/:id',
    name: 'Organization',
    Component: Organization,
    exact: true,
    layout: OrganizationLayout,
    isProtected: true,
  },
  {
    path: '/request/:id',
    name: 'Request',
    Component: Request,
    exact: true,
    layout: RequestInnerLayout,
    isProtected: true,
  },
  {
    path: '/post/:id',
    name: 'Post',
    Component: Post,
    exact: true,
    layout: PostInnerLayout,
    isProtected: true,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    Component: Notifications,
    exact: true,
    layout: NotificationsLayout,
    isProtected: true,
  },
  {
    path: ['/messages', '/messages/:id'],
    name: 'Messages',
    Component: Messages,
    exact: true,
    layout: OnlyHeaderLayout,
    isProtected: true,
  },
  {
    path: '/coming-soon',
    name: 'Coming Soon',
    Component: ComingSoon,
    exact: true,
    layout: OnlyHeaderLayout,
  },
  {
    path: '/bookmarks',
    name: 'Book marks',
    Component: BookmarkPosts,
    // layout: FeedLayout,
    exact: true,
  },
  {
    path: '*',
    name: 'Error',
    Component: Error,
    exact: true,
  },
]

export default getRoutes
