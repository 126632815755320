import { useMutation } from '@apollo/client'
import { READ_MESSAGE } from 'services/mutations/readMessage'

const useReadMessage = () => {
  const [readMessage] = useMutation(READ_MESSAGE, {
    errorPolicy: 'all',
    refetchQueries: ['conversations'],
  })

  const readMessageHandler = (id) => {
    readMessage({
      variables: {
        message_id: id,
      },
    })
  }

  return [readMessageHandler]
}

export default useReadMessage
