import Header from 'components/Header'
import AuthorizedUserProvider from 'contexts/AuthorizedUser'

const OnlyHeaderLayout = (props) => {
  return (
    <AuthorizedUserProvider>
      <Header />
      {props.children}
    </AuthorizedUserProvider>
  )
}

export default OnlyHeaderLayout
