import { useHistory } from 'react-router-dom'
import * as S from './styled'

const Search = (props) => {
  const history = useHistory()
  return (
    <S.Search className="search-input">
      <S.Input
        {...props}
        onKeyDown={(e) =>
          e.key === 'Enter' &&
          history.push(`search?type=users&value=${props.value || ''}`)
        }
      />
      <S.SearchIcon
        filename="search"
        onClick={() =>
          history.push(`search?type=users&value=${props.value || ''}`)
        }
      />
    </S.Search>
  )
}

export default Search
