import Button from 'components/Forms/Button'
import { ToastContainer, toast, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createPortal } from 'react-dom'
import * as S from './styled'
import Icon from 'components/Icon'

const Toast = ({ notify }) => {
  const element = document.getElementById('toast-root')

  return createPortal(
    <div>
      <S.Button onClick={notify} />
      <ToastContainer
        hideProgressBar
        draggable={true}
        transition={Zoom}
        autoClose={1500}
        style={{
          zIndex: '1000000002',
          // textAlign: 'center',
        }}
        icon={<Icon filename="notification" />}
      />
    </div>,
    element
  )
}

export default Toast
