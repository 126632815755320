import styled from 'styled-components'

export const OfferList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.text[500]};
`

export const OfferListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  button {
    width: 39px;
    height: 39px;
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.primary[700]};
    justify-content: center;

    svg {
      path {
        stroke: ${(props) => props.theme.colors.white};
      }
    }
  }
`

export const ChosenOnes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 15px;
`

export const ChosensList = styled.div`
  display: flex;
  gap: 20px;
`
