import { gql } from '@apollo/client'
import { USER_FRAGMENT } from 'services/fragments/user'
// import { POST_FRAGMENT } from "services/fragments/post";

export const GET_USER = gql`
  ${USER_FRAGMENT()}
  query getUser($slug: String) {
    user(slug: $slug) {
      ...UserFragment
      conversation_id
      followers {
        id
        name
        type
        surname
        slug
        state {
          id
          name
        }
        avatar {
          path
          id
        }
        sex
      }
      followings {
        id
        name
        type
        surname
        slug
        state {
          id
          name
        }
        avatar {
          path
          id
        }
        sex
      }
      userOrganizations {
        id
        volunt
        approved
        volunt_time
        state {
          name
        }
        title
        slug
        description
        # user {
        #   ...UserFragment
        # }
        # members {
        #   ...UserFragment
        # }
      }
    }
  }
`
