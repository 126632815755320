import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Logo = styled(Link)`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: flex;
    align-items: center;
    width: 30px;
    overflow: hidden;
  }
`

export const BetaImg = styled.div`
  position: relative;
  /* top: 25px; */
  left: 0;
  top: -40px;
  left: ${props=>props.width < 768? 0 : "155px" }  ;
  
  img{
    width: 20px;
    height: 20px;
  }
  
  @media screen and (max-width: ${props=>props.theme.breakpoints.md}) {
    position: absolute;
    left: 20px;
    top: 20px;
  }
  `;
