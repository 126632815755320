import { gql } from '@apollo/client'

export const ADD_EDUCATION = gql`
  mutation AddEducation(
    $educational_institution: String
    $course: String
    $degree: String
    $start_month: Int
    $start_year: Int
    $end_month: Int
    $end_year: Int
    $still_studying: Boolean
  ) {
    AddEducation(
      educational_institution: $educational_institution
      course: $course
      degree: $degree
      start_month: $start_month
      start_year: $start_year
      end_month: $end_month
      end_year: $end_year
      still_studying: $still_studying
    ) {
      response
    }
  }
`
export const EDIT_EDUCATION = gql`
  mutation EditEducation(
    $id: Int
    $educational_institution: String
    $course: String
    $degree: String
    $start_month: Int
    $start_year: Int
    $end_month: Int
    $end_year: Int
    $still_studying: Boolean
  ) {
    EditEducation(
      id: $id
      educational_institution: $educational_institution
      course: $course
      degree: $degree
      start_month: $start_month
      end_month: $end_month
      start_year: $start_year
      end_year: $end_year
      still_studying: $still_studying
    ) {
      response
    }
  }
`

export const DELETE_EDUCATION = gql`
  mutation DeleteEducation($id: Int) {
    DeleteEducation(id: $id) {
      response
    }
  }
`
