import * as yup from 'yup'

const schema = yup.object({
  name: yup.string().required('ველის შევსება აუცილებელია'),
  surname: yup.string().required('ველის შევსება აუცილებელია'),
  email: yup.string().required('ველის შევსება აუცილებელია'),
  address: yup.string().required('ველის შევსება აუცილებელია'),
  phone: yup.string().required('ველის შევსება აუცილებელია'),
})

export default schema
