import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import Modal from 'components/Modal'
import Icon from 'components/Icon'
import Button from 'components/Forms/Button'
import Input from 'components/Forms/Input'

import socialNetwork from 'validation/socialNetwork'
import * as _ from 'lodash'
import * as S from './styled'
import * as CS from '../styled'

const CreateSocialNetwork = (props) => {
  const [error, setError] = useState(null)
  const { t } = useTranslation()

  const socialNetworks = [
    {
      title: 'Facebook',
      url: props.user?.facebook_url,
      fileName: 'facebook',
      name: 'facebook_url',
    },
    {
      title: 'Instagram',
      url: props.user?.isntagram_url,
      fileName: 'messenger',
      name: 'instagram_url',
    },
    {
      title: 'Dribble',
      url: props.user?.dribble_url,
      fileName: 'dribble',
      name: 'dribble_url',
    },
    {
      title: 'Youtube',
      url: props.user?.youtube_url,
      fileName: 'youtube',
      name: 'youtube_url',
    },
    {
      title: 'Be',
      url: props.user?.behance_url,
      fileName: 'be',
      name: 'behance_url',
    },
    {
      title: 'Linkedin',
      url: props.user?.linkedin_url,
      fileName: 'linkedin',
      name: 'linkedin_url',
    },
    {
      title: 'Twitter',
      url: props.user?.twitter_url,
      fileName: 'twitter',
      name: 'twitter_url',
    },
  ]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(socialNetwork),
    defaultValues: useMemo(() => {
      return props.user
    }, [props.user]),
  })

  useEffect(() => {
    const key = Object.keys(errors)[0]
    const getError = errors[key]?.message

    setError(getError)
  }, [errors])

  useEffect(() => {
    setError(null)
  }, [props.show])

  const submitHandler = (args) => {
    props.editSocialMedia(args)
    props.onClose()
  }

  return (
    <Modal title={t('user.socialNetworks')} {...props}>
      <CS.ModalForm onSubmit={handleSubmit(submitHandler)} autoComplete="off">
        <S.SocialNetworkItem>
          <div className="social-items">
            {socialNetworks.map((each, i) => (
              <S.ModalContent key={each.name}>
                <Link to={each.url}>
                  <Icon filename={each.fileName} />
                </Link>
                <S.NetworkInfo>
                  <h5>{each.title}</h5>
                  <Input
                    name={each.name}
                    type="text"
                    register={register}
                    error={errors?.name}
                  />
                  {errors && Object.keys(errors)[each.name] && <div>some</div>}
                </S.NetworkInfo>
              </S.ModalContent>
            ))}
          </div>
          {error && (
            <S.ErrorMessage>
              <span>{error}</span>
            </S.ErrorMessage>
          )}
          <Button type="primary" submit>
            {t('common.save')}
          </Button>
        </S.SocialNetworkItem>
      </CS.ModalForm>
    </Modal>
  )
}

export default CreateSocialNetwork
