import { gql } from '@apollo/client'

export const GET_FEED_FOOTER = gql`
  query FooterContent {
    FooterContent {
      copyright_text
      navigation {
        items {
          url
          title
        }
      }
    }
  }
`
