import styled from 'styled-components'

export const CoverSelectNotificationText = styled.p`
  color: #ffce07;
  font-weight: bold;
`

export const EditorSwitcher = styled.p`
  top: 12px;
  right: 10px;
  cursor: pointer;
  position: absolute;
`

export const IconContainer = styled.div`
  display: flex;
  margin-right: 10px;
  cursor: pointer;
  justify-content: flex-end;

  path {
    stroke: #000;
  }
`

export const MetaImage = styled.img`
  max-height: 250px;
  width: 100%;
  margin-top: 10px;
  position: relative;
  bottom: 10px;
  border-radius: 10px;
`
