import { useMutation } from '@apollo/client'
import { SEND_VOLUNTEER_REQUEST } from 'services/mutations/sendVolunteerRequest'

const useSendVolunteerRequest = () => {
  const [sendVolunteerRequestMutation, sendVolunteerRequestMutationResult] =
    useMutation(SEND_VOLUNTEER_REQUEST, {
      errorPolicy: 'all',
    })

  const sendVoluntRequest = (request_id, shouldRefetch) => {
    sendVolunteerRequestMutation({
      variables: {
        request_id,
      },
      refetchQueries: [shouldRefetch && 'post'],
    })
  }

  return [sendVoluntRequest, sendVolunteerRequestMutationResult]
}

export default useSendVolunteerRequest
