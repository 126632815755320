import styled from 'styled-components'

export const AuthLayout = styled.div`
  display: grid;
  grid-template-columns: 3fr 6fr;
  height: 100vh;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    position: relative;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
  }
`

export const AuthLeftSide = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary[700]};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: ${(props) => (props.terms ? 'block' : 'none')};
    background-color: ${(props) => props.theme.colors.background[100]};
  }
`

export const LeftSideContent = styled.div`
  /* height: 100%; */
  padding: 25px 0px;
  padding-right: 20px;
  display: grid;
  grid-template-rows: auto 1fr;

  .terms-and-conditions {
    padding-top: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    img[alt='Finger Up'] {
      display: none;
    }

    .terms-and-conditions {
      padding-top: 50px;
      line-height: 25px;
    }
  }
`

export const AuthRightSide = styled.div`
  width: calc(100% + 20px);
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  background-color: white;
  transform: translateX(-20px);

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    transform: translateX(-20px);

    display: ${(props) => (props.terms ? 'none' : 'block')};
  }
`

export const RightSideContent = styled.div`
  /* height: 100%; */
  padding-top: 16px;
  display: grid;
  grid-template-rows: auto 1fr;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const AuthActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const AuthContent = styled.div`
  height: 100%;
`

export const HeaderMobile = styled.div`
  display: flex;
  margin-bottom: 30px;
  gap: 60px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    padding-left: 20px;
    justify-content: space-between;

    .custom-select-container {
      padding: 3px 20px 3px 20px;
      border: 1px solid #f3f3f3;
      background-color: transparent;
      height: 50px;
    }
  }

  > div {
    padding: 0px;
    border: none;
  }
`
