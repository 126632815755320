import * as S from './styled'
import Icon from 'components/Icon'
import Avatar from 'components/Avatar'
import { useTranslation } from 'react-i18next'

const SearchResults = ({ data, loading, searchValue, onClick }) => {
  const organizations = data?.search?.organizations
  const users = data?.search?.users
  const requests = data?.search?.requests

  const { t } = useTranslation()

  if (
    organizations?.length === 0 &&
    users?.length === 0 &&
    requests?.length === 0
  ) {
    return (
      <S.SearchResultsMenu>
        <S.Loading>{t('search.notFound')}</S.Loading>
      </S.SearchResultsMenu>
    )
  }

  if (loading)
    return (
      <S.SearchResultsMenu>
        <S.Loading>{t('search.loading')}</S.Loading>
      </S.SearchResultsMenu>
    )

  return (
    <S.SearchResultsMenu>
      <S.FoundContent>
        <S.Heading>{t('search.users')}</S.Heading>
        {users?.map((user, i) => {
          return (
            <S.UserContent key={i} to={`/user/${user?.slug}`}>
              <Avatar src={user?.avatar?.path} type="simple" sex={user?.sex} />
              <div>
                <S.UserInfo>
                  <S.TextContent>
                    {user?.name} {user?.surname}
                  </S.TextContent>
                </S.UserInfo>
                <p>{user?.state?.name}</p>
              </div>
            </S.UserContent>
          )
        })}
      </S.FoundContent>

      <S.FoundContent>
        <S.Heading>{t('search.requests')}</S.Heading>
        {requests?.map((req, i) => {
          return (
            <S.SearchContent to={`/request/${req?.id}`} key={i}>
              <div>
                <Icon filename="search" />
              </div>
              <S.TextContent>{req?.title}</S.TextContent>
            </S.SearchContent>
          )
        })}
      </S.FoundContent>

      <S.FoundContent>
        <S.Heading>{t('search.organizations')}</S.Heading>
        {organizations?.map((org, i) => {
          return (
            <S.SearchContent to={`/organization/${org?.id}`} key={i}>
              <div>
                <Icon filename="search" />
              </div>
              <S.TextContent>{org?.title}</S.TextContent>
            </S.SearchContent>
          )
        })}

        <S.ShowAll
          to={`/search?type=users&value=${searchValue}`}
          onClick={onClick}
        >
          <p>{t('common.seeAll')}</p>
          <Icon filename="show-all" />
        </S.ShowAll>
      </S.FoundContent>
    </S.SearchResultsMenu>
  )
}

export default SearchResults
