import * as yup from 'yup'
import {
  requiredMessage,
  // phoneNumberMessage,
  emailMessage,
  matchPassword,
} from './messages'
const schema = yup.object({
  name: yup
    .string()
    .required(requiredMessage)
    .matches(/\p{Letter}/u, 'სახელი არ უნდა შეიცავდეს მხოლოდ ციფრებს'),
  surname: yup
    .string()
    .required(requiredMessage)
    .matches(/\p{Letter}/u, 'გვარი არ უნდა შეიცავდეს მხოლოდ ციფრებს'),
  email: yup.string().required(requiredMessage).email(emailMessage),
  date_of_birth: yup.date().required(requiredMessage),
  // phone: yup
  //   .string()
  //   .required(requiredMessage)
  //   .matches(
  //     /^[+][(](?=.*?[0-9]).{0,3}[)](?=.*?[0-9]).{6,9}$/,
  //     phoneNumberMessage
  //   ),
  password: yup
    .string()
    .required(requiredMessage)
    .matches(
      // eslint-disable-next-line
      /^(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      'პაროლის ველი უნდა შეიცავდეს მინიმუმ 6 სიმბოლოს - ლათინური ასოებითა და მინიმუმ ერთი ციფრით'
    ),
  repassword: yup.string().oneOf([yup.ref('password'), null], matchPassword),
  state_id: yup.string().required(requiredMessage),
  sex: yup.string().required(requiredMessage),
  address: yup.string().required(requiredMessage),
  agreement: yup.bool().oneOf([true], 'გთხოვთ, დაეთანხმოთ წესებსა და პირობებს'),
})

export default schema
