import { useMutation } from '@apollo/client'
import { useUser } from 'contexts/UserProvider'
import { ADD_POST } from 'services/mutations/post'
import { GET_USER_POSTS } from 'services/queries/getActivityPosts'
import { GET_FEED } from 'services/queries/getFeed'
import { GET_ORGANIZATION_FEED } from 'services/queries/getOrganizationPosts'

const usePost = () => {
  const { user } = useUser()

  const [addPostMutation, addPostResult] = useMutation(ADD_POST, {
    errorPolicy: 'all',
    refetchQueries: [
      GET_USER_POSTS,
      GET_ORGANIZATION_FEED,
      {
        query: GET_FEED,
        variables: {
          limit: 10,
          page: 1,
        },
      },
    ],
  })

  const addPost = (data) => {
    addPostMutation({
      variables: {
        ...data,
        user: user?.id,
      },
    })
  }

  return [addPost, addPostResult]
}

export default usePost
