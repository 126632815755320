import styled from 'styled-components'

export const AuthorizedContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background[200]};
  position: relative;
  margin-top: 100px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 56px;
  }
`

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 6.5fr 2.5fr;
  grid-gap: 27px;
  padding-top: 20px;
  min-height: calc(100vh - 100px);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
    width: 100%;
    position: relative;
    > div {
      max-width: 100%;
      position: relative;
    }
  }
`

export const Content = styled.div``

export const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
    margin: 0;
  }
`

export const Friends = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  max-width: 250px;

  > div:hover {
    width: 250px;
    position: relative;
    svg {
      transform: rotate(180deg);
      position: absolute;
      left: 20px;
    }
    > div {
      width: 100%;
      p {
        display: flex;
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg2}) {
    display: none;
  }
`
