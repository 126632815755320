import { useState, useEffect, useRef } from 'react'

const useComponentVisible = () => {
  const [isComponentVisible, setIsComponentVisible] = useState(false)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return { ref, isComponentVisible, setIsComponentVisible }
}

export default useComponentVisible
