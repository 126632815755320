import { gql } from '@apollo/client'

export const ADD_USER_TO_CONVERSATION = gql`
  mutation SendMessageToConversation(
    $conversation_id: Int
    $message: String
    $file: Upload
    $image: Upload
  ) {
    SendMessageToConversation(
      conversation_id: $conversation_id
      message: $message
      file: $file
      image: $image
    ) {
      message {
        id
        message
        created_at
        can_approve_transaction
        can_reject_transaction
        can_rate {
          can_rate
          user_id
          request_id
        }
        has_file
        file {
          id
          path
          file_name
        }
        has_image
        image {
          id
          path
        }
        user {
          id
          slug
          name
          sex
          surname
          avatar {
            id
            path
          }
          is_online
          is_me
        }
      }
    }
  }
`

export const SEND_FILE_TO_CONVERSATION = gql`
  mutation SendFileToConversation($conversation_id: Int, $file: Upload) {
    SendFileToConversation(conversation_id: $conversation_id, file: $file) {
      response
    }
  }
`

export const SEND_IMAGE_TO_CONVERSATION = gql`
  mutation SendImageToConversation($conversation_id: Int, $file: Upload) {
    SendImageToConversation(conversation_id: $conversation_id, file: $file) {
      response
    }
  }
`

export const SEND_MESSAGE = gql`
  mutation SendMessageToUser($user_id: Int, $message: String) {
    SendMessageToUser(user_id: $user_id, message: $message) {
      id
      message
      created_at
      can_approve_transaction
      can_reject_transaction
      transaction_id
      can_rate {
        can_rate
        user_id
        request_id
      }
      has_file
      file {
        id
        path
        file_name
      }
      has_image
      image {
        id
        path
      }
      user {
        id
        slug
        name
        surname
        sex
        avatar {
          id
          path
        }
        is_online
        is_me
      }
    }
  }
`
