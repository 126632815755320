import { gql } from '@apollo/client'
import { ORGANIZATION_FRAGMENT } from '../fragments/organization'
import { POST_FRAGMENT } from 'services/fragments/post'
export const GET_ME = gql`
  ${ORGANIZATION_FRAGMENT}
  ${POST_FRAGMENT}
  query getMe {
    me {
      id
      name
      surname
      about
      slug
      email
      address
      comment_notifications
      active_locale
      education {
        id
        educational_institution
        course
        degree
        start_month
        start_year
        end_year
        end_month
        still_studying
      }
      state {
        id
        name
      }
      unread_notifications
      unread_messages
      avatar {
        path
        id
      }
      cover {
        path
        id
      }
      sex
      date_of_birth
      phone
      grouped_skills {
        name
        children {
          id
          name
        }
      }
      skills {
        id
        name
        own
      }
      resume {
        id
        disk_name
        file_name
        file_size
        content_type
        title
        description
        path
      }
      transaction_statistics {
        all {
          incoming
          outgoing
        }
        month {
          incoming
          outgoing
        }
        week {
          incoming
          outgoing
        }
      }
      address
      volunt
      volunt_time
      referal_code
      referal_code_share_url
      linkedin_url
      facebook_url
      instagram_url
      dribble_url
      pinterest_url
      twitter_url
      youtube_url
      behance_url
      userOrganizations {
        ...OrganizationFragment
      }
      bookmark {
        ...PostFragment
      }
      work_experience {
        id
        company
        position
        start_month
        start_year
        end_month
        end_year
        still_working
      }
    }
  }
`
