import { gql } from '@apollo/client'

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    id
    title
    slug
    description
    profile {
      path
      id
    }
    state {
      name
    }
  }
`
