import * as yup from 'yup'

const schema = (arg = undefined) => {
  return yup.object({
    company: yup.string().required('აუცილებელი ველი'),
    position: yup.string().required('აუცილებელი ველი'),
    start_date: arg
      ? null
      : yup.date('თარიღის ფორმატი').required('აუცილებელი ველი'),
  })
}

export default schema
