import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal'
import Button from 'components/Forms/Button'
import Input from 'components/Forms/Input'

import useUpdateNotificationSettings from 'hooks/useUpdateNotificationSettings'

import * as CS from './styled'
import * as S from '../styled'

const NotificationSettings = (props) => {
  const [checked, setChecked] = useState(false)
  const { updateNotificationSettings } = useUpdateNotificationSettings()
  const { register } = useForm()
  const { t } = useTranslation()

  // Update notification settings
  const handleChange = (e) => {
    updateNotificationSettings(e.target.checked)
    setChecked(!checked)
  }

  useEffect(()=>{
    setChecked(props.commentNotifications)
  },[props.commentNotifications])

  return (
    <Modal title={t('notifications.notificationsParameters')} {...props}>
      <CS.SettingsTitle>
        {t('notifications.manageNotifications')}
      </CS.SettingsTitle>

      <S.ModalForm>
        <CS.SettingItem>
          <CS.SettingItemInfo>
            <p>{t('notifications.comments')}</p>
            <p>{t('notifications.commentsOnMyPost')}</p>
          </CS.SettingItemInfo>
          <Input
            type="switch"
            name="toogle"
            {...register('comment')}
            onChange={(e) => handleChange(e)}
            checked={checked}
          />
        </CS.SettingItem>
        {/* <CS.SettingItem>
                    <CS.SettingItemInfo>
                        <p>
                            კომენტარები
                        </p>
                        <p>ჩემს პოსტზე დაწერილი კომენტარები</p>
                    </CS.SettingItemInfo>
                    <Input type='switch' {...register('comment')} />
                </CS.SettingItem>
                <CS.SettingItem>
                    <CS.SettingItemInfo>
                        <p>
                            კომენტარები
                        </p>
                        <p>ჩემს პოსტზე დაწერილი კომენტარები</p>
                    </CS.SettingItemInfo>
                    <Input type='switch' {...register('comment')} />
                </CS.SettingItem>
                <CS.SettingItem>
                    <CS.SettingItemInfo>
                        <p>
                            კომენტარები
                        </p>
                        <p>ჩემს პოსტზე დაწერილი კომენტარები</p>
                    </CS.SettingItemInfo>
                    <Input type='switch' {...register('comment')} />
                </CS.SettingItem>
                <S.FormActions>
                    <Button type='primary' className='submit' submit>
                        {t("common.save")}
                    </Button>
                </S.FormActions> */}
      </S.ModalForm>
    </Modal>
  )
}

export default NotificationSettings
