import Icon from 'components/Icon'
import * as S from './styled'

const Upload = ({ label, register, name, icon, ...rest }) => {
  const { onChange } = register(name)

  const onUpload = (e) => {
    const file = e?.target?.files[0]
    onChange({
      target: {
        ...e.target,
        name,
        value: file,
      },
    })
  }

  return (
    <S.Upload>
      <S.Input {...rest} onChange={onUpload} name={name} type="file" />
      <Icon filename={`${icon || 'gallery-export'}`} />
      <span>{label}</span>
    </S.Upload>
  )
}

export default Upload
