export const languages = [
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'ka',
    label: 'GE',
  },
]
