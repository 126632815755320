import { useEffect, useState, useRef } from 'react'
import Button from 'components/Forms/Button'
import { AvatarLive } from 'components/Avatar'
import Icon from 'components/Icon'
import useConversation from 'hooks/useConversation'
import { Link, useLocation } from 'react-router-dom'
import * as S from './styled'
import { useTranslation } from 'react-i18next'

const FriendsList = (props) => {
  const [userList, setUserList] = useState([])
  const { getConversationUser, conversationData } = useConversation()
  const location = useLocation()

  const { t } = useTranslation()

  useEffect(() => {
    getConversationUser()
  }, [location.pathname])

  useEffect(() => {
    setUserList(conversationData?.conversations?.data)
  }, [conversationData])

  return (
    <S.FriendsList visibility={userList?.length > 0}>
      <Button type="clean">
        <p style={{ display: 'none' }}>{t('user.myFollowers')}</p>
        <Icon filename="login" />
      </Button>
      <S.List>
        {userList &&
          userList?.map((item, index) => {
            if (index >= 10) {
              return false
            }
            return (
              <Link key={index} to={`/messages/${item?.id}`}>
                <S.UserListBlock>
                  <AvatarLive
                    type="simple"
                    src={item?.user?.avatar?.path}
                    sex={item?.user?.sex}
                    isOnline={item?.user?.is_online}
                  />
                  <p>
                    {item?.user?.name} {item?.user?.surname}{' '}
                  </p>
                </S.UserListBlock>
              </Link>
            )
          })}
      </S.List>
    </S.FriendsList>
  )
}

export default FriendsList
