import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_ALL_SKILLS } from 'services/queries/getSkills'
import { DELETE_SKILL, UPDATE_SKILLS } from 'services/mutations/skills'

const useSkills = () => {
  const [getSkillsQuery, { data: skillsData }] = useLazyQuery(GET_ALL_SKILLS, {
    errorPolicy: 'all',
  })

  const [updateSkillsMutation, { data: updateSkillsData }] = useMutation(
    UPDATE_SKILLS,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser', 'getMe'],
    }
  )

  const [deleteSkillMutation] = useMutation(DELETE_SKILL, {
    errorPolicy: 'all',
    refetchQueries: ['getUser', 'getMe'],
  })

  const getSkills = (data) => {
    getSkillsQuery({
      variables: data,
    })
  }

  const deleteSkill = (id) => {
    deleteSkillMutation({
      variables: {
        id,
      },
    })
  }

  const updateSkills = (data) => {
    const arrOfSKills = Object.values(data)
      ?.filter((el) => typeof el === 'string')
      .map((el) => parseInt(el))

    updateSkillsMutation({
      variables: {
        id: arrOfSKills,
      },
    })
  }

  return {
    getSkills,
    skillsData,
    deleteSkill,
    updateSkills,
    updateSkillsData,
  }
}

export default useSkills
