import { gql } from '@apollo/client'

export const GET_MESSAGES = gql`
  query messages($conversation_id: Int, $limit: Int, $page: Int) {
    messages(conversation_id: $conversation_id, limit: $limit, page: $page) {
      data {
        request {
          id
          title
        }
        transaction {
          approved
          rejected
          recipient {
            name
            surname
            id
          }
          author {
            name
            surname
            id
          }
        }
        id
        message
        created_at
        transaction_id
        can_approve_transaction
        can_reject_transaction
        can_rate {
          can_rate
          user_id
          request_id
        }
        has_file
        file {
          id
          path
          file_name
        }
        has_image
        image {
          id
          path
        }
        user {
          id
          slug
          name
          surname
          sex
          avatar {
            id
            path
          }
          is_online
          is_me
        }
      }
    }
  }
`
