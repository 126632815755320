import { Link, useHistory } from 'react-router-dom'
import Button, { ButtonGroup } from 'components/Forms/Button'
import Icon from 'components/Icon'
import { useQuery } from '@apollo/client'
import { GET_NEARBY_REQUESTS } from 'services/queries/getNearbyRequests'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

const PostNearby = () => {
  const history = useHistory()
  const { data } = useQuery(GET_NEARBY_REQUESTS)
  const { t } = useTranslation()

  return (
    <S.PostNearby>
      <S.PostNearbyJeader>
        <h5>{t('feed.nearby')}</h5>
        <Button
          type="clean"
          onClick={() => history.push('/search?type=requests&value=')}
        >
          {t('feed.seeAll')}
          <Icon filename="angle-right" />
        </Button>
      </S.PostNearbyJeader>
      <S.PostNearbyList>
        {data?.NearByRequests?.data?.map(
          (request) =>
            request?.cover && (
              <Link key={request.id} to={`/request/${request?.id}`}>
                <S.Post>
                  <img src={request?.cover} alt={request?.title} />
                  <S.DescriptionBox>
                    <h4>{request?.title}</h4>
                    <p
                      dangerouslySetInnerHTML={{ __html: request?.description }}
                    ></p>
                    <ButtonGroup>
                      <Button type="clean">
                        <Icon filename="buy-crypto" />
                        <span>{request?.volunt}</span>
                      </Button>
                      <Button type="clean">
                        <Icon filename="comments" />
                        <span>{request?.comments?.length || '0'}</span>
                      </Button>
                    </ButtonGroup>
                  </S.DescriptionBox>
                </S.Post>
              </Link>
            )
        )}
      </S.PostNearbyList>
    </S.PostNearby>
  )
}

export default PostNearby
