import { useState } from 'react'
import Button from 'components/Forms/Button'
import CreateRequest from 'modules/Modals/CreateRequest'
import CreatePostModal from 'modules/Modals/CreatePost'
import * as S from './styled'
import Toast from 'components/Toast/Toast'
import usePost from 'hooks/usePost'
import useRequest from 'hooks/useRequest'
import { useTranslation } from 'react-i18next'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'

const CreatePost = () => {
  const [requestOpen, setRequestOpen] = useState(false)
  const [postOpen, setPostOpen] = useState(false)
  const { me } = useAuthorizedUser()

  const { t } = useTranslation()

  const generatedText = [t('feed.randomText'), t('feed.textChanged')]

  const [text, setText] = useState(generatedText[0])

  const [addPost] = usePost()
  const [addRequest] = useRequest(me?.id)

  const changeColor = (event, index) => {
    event.currentTarget.classList.add('active')
    setText(generatedText[index])
  }

  const removeColor = (event) => {
    event.currentTarget.classList.remove('active')
  }

  return (
    <S.CreatePostWrapper>
      <S.CreatePost>
        <S.ParagraphText>{text}</S.ParagraphText>
        <Button
          onClick={() => setRequestOpen(true)}
          onMouseEnter={(e) => changeColor(e, 0)}
          onMouseLeave={(e) => removeColor(e)}
        >
          {t('feed.createRequest')}
        </Button>
        <Button
          onClick={() => setPostOpen(true)}
          onMouseEnter={(e) => changeColor(e, 1)}
          onMouseLeave={(e) => removeColor(e)}
        >
          {t('feed.createPost')}
        </Button>
      </S.CreatePost>
      <CreateRequest
        show={requestOpen}
        addRequest={addRequest}
        onClose={() => setRequestOpen(false)}
      />
      <CreatePostModal
        show={postOpen}
        addPost={addPost}
        onClose={() => setPostOpen(false)}
      />
      <Toast />
    </S.CreatePostWrapper>
  )
}

export default CreatePost
