import { ApolloClient, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { useAuthToken } from 'hooks/useAuthToken'
import { toast } from 'react-toastify'

const uploadLink = createUploadLink({
  uri: 'https://v2.volunteer.ge/api/graphql',
})

const authMiddleware = (authToken) =>
  new ApolloLink((operation, forward) => {
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      })
    }

    return forward(operation)
  })

const cache = new InMemoryCache({})

const queryName = ['OrganizationMediaFiles']
const errorLink = (logout) =>
  onError(({ graphQLErrors, networkError, response }) => {
    if (networkError?.statusCode === 401) {
      logout()
    }
    let bool = false

    for (let i = 0; i < queryName.length; i++) {
      if (queryName[i] === graphQLErrors[0].path[0]) {
        bool = true
      }
    }

    // if (graphQLErrors)
    //   graphQLErrors.forEach(({ message, locations, path }) => {
    //     if (message && !bool && message !== 'validation') {
    //       toast.error(message, {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //       })
    //     }
    //   })
  })

const responseHandler = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (
      data?.data &&
      data.data[operation?.operationName] &&
      data.data[operation?.operationName].response
    ) {
      toast.success(data.data[operation?.operationName].response, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
    return data
  })
})

const useAppApolloClient = () => {
  const [authToken, _, removeToken] = useAuthToken()

  const logout = () => {
    removeToken()
  }

  return new ApolloClient({
    link: authMiddleware(authToken)
      .concat(responseHandler)
      .concat(errorLink(logout))
      .concat(uploadLink),
    cache,
    name: 'timebank',
    version: '1.0',
  })
}

export default useAppApolloClient
