import styled from 'styled-components'

export const RequestRightBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Author = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 40px;
`

export const SkillsTitle = styled.p`
  color: #414042;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
`

export const Circle = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #c5c9ca;
  margin: 0 5px;
`

export const AuthorInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`

export const JobInfo = styled.div`
  padding: 30px 27px 30px 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 25px;

  P {
    max-width: 280px;
  }

  h3 {
    font-size: ${(props) => props.theme.fontSizes.xl};
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      font-size: ${(props) => props.theme.fontSizes.sm};
      color: ${(props) => props.theme.colors.text[700]};
      font-weight: normal;
    }
  }

  button {
    width: 100%;
  }
`

export const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 30px;
  position: relative;
  margin: 10px 0px;
  /* overflow: hidden; */
  max-height: ${(props) => (props.open ? '100%' : '60px')};
  transition: all 0.3s ease-in-out;

  button {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: ${(props) => props.theme.colors.background[300]};
    padding: 3px 13px 5px 15px;
    border-radius: 24px;
    font-size: ${(props) => props.theme.fontSizes.xs};
  }
  .skill {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  span {
    cursor: pointer;
    background-color: #e1e1e1;
    padding: 8px;
    border-radius: 20px;
    color: ${(props) => props.theme.colors.text[700]};
  }
`
