import React from 'react'
import * as S from './styled'
import Input from 'components/Forms/Input'
import Modal from 'components/Modal'
import { useForm } from 'react-hook-form'
import Button from 'components/Forms/Button'
import useSkills from 'hooks/useSkills'
import { useEffect } from 'react'
import useOrganization from 'hooks/useOrganization'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const CreateSkills = (props) => {
  const { register, handleSubmit, reset } = useForm({ mode: 'onChange' })

  const { updateSkills, skillsData, getSkills } = useSkills()
  const {
    getOrganizationSkills,
    organizationSkills,
    updateOrganizationSkills,
  } = useOrganization()

  const { q } = useTranslation()

  useEffect(() => {
    getOrganizationSkills(props.id)
  }, [props])

  useEffect(() => {
    getSkills()
    reset()
  }, [props])

  const submitHandler = (data) => {
    if (props.isOrganization) {
      updateOrganizationSkills(data, props.id)
    } else {
      updateSkills(data)
    }

    props.onClose()
  }

  const modalActions = (
    <Button type="primary" submit onClick={handleSubmit(submitHandler)}>
      {t('user.select')}
    </Button>
  )

  const skills = props.isOrganization
    ? organizationSkills?.OrganizationSkills
    : skillsData?.skills

  return (
    <Modal
      height="50vh"
      displayFlex="flex"
      title={t('user.addSkills')}
      justifyContent="flex-end"
      modalActions={modalActions}
      {...props}
    >
      <form>
        <S.SkillsMenu>
          {skills?.map((skill, i) => {
            return (
              <React.Fragment key={i}>
                <h1>{skill.name}</h1>
                {skill.children.map((el, i) => {
                  return (
                    <Input
                      key={i}
                      value={el.id}
                      name={el.name}
                      type="checkbox"
                      register={register}
                      checked={
                        props.isOrganization ? el.owned || null : el.own || null
                      }
                    >
                      <span># {el.name}</span>
                    </Input>
                  )
                })}
              </React.Fragment>
            )
          })}
        </S.SkillsMenu>
      </form>
    </Modal>
  )
}

export default CreateSkills
