import styled from 'styled-components'

export const WhoCan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    p {
      margin-top: 15px;
      /* display: flex; */
      /* flex-direction: row; */
      /* align-items: center; */
      /* gap: 10px; */
      /* &::before {
        content: '';
        width: 14px;
        height: 14px;
        background-color: ${(props) => props.theme.colors.primary[700]};
        border-radius: 50%;
      } */
    }
  }
`
