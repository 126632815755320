import {
  useContext,
  useReducer,
  createContext,
  useEffect,
  useState,
} from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { EDIT_USER } from 'services/mutations/user'
import { GET_USER } from 'services/queries/getUser'
import { useParams } from 'react-router-dom'
import reducer, { initialState } from './reducer'
import { setShowAsAction } from './actions'
import 'react-toastify/dist/ReactToastify.css'
import { useAuthToken } from 'hooks/useAuthToken'
import Pusher from 'pusher-js'

const UserContext = createContext(initialState)

export const useUser = () => useContext(UserContext)

const UserProvider = (props) => {
  const [getUser, { data, loading, error }] = useLazyQuery(GET_USER)
  const [notification, setNotification] = useState({})
  const [editUserMutation, editUserData] = useMutation(EDIT_USER, {
    errorPolicy: 'all',
    refetchQueries: ['getUser'],
  })

  const { page, slug } = useParams()
  const [state, dispatch] = useReducer(reducer, initialState)

  const setShowAs = setShowAsAction(dispatch)

  const isFollowers = page === 'followers'
  const isFollowings = page === 'followings'

  // pusher
  // const [authToken] = useAuthToken()
  /*const pusher = new Pusher('5a61a15c00bccc1706e8', {
    cluster: 'ap2',
    encrypted: true,
    authTransport: 'ajax',
    auth: {
      headers: {
        //'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    },
    authEndpoint: 'https://volunteer-api.elliotapps.me/api/pusher/auth',
  })

  const channel = pusher.subscribe(`private-user.${data?.user?.id}`)
  channel.bind(
    'notification.recived',
    function (data) {
      // if (data.message) {
      console.log(data)
      // }
    },
    channel.unbind()
  )*/

  // const pusher2 = new 2("5a61a15c00bccc1706e8", {
  //     cluster: "ap2"
  // })
  // const channel2 = pusher2.subscribe(`presence-conversation.5`)
  // channel2.bind('message.recived', function (data) {
  //     if (data.message) {
  //     }
  // }, channel2.unbind())

  const editUser = (data) => {
    editUserMutation({
      variables: {
        ...data,
        sex: Number(data.sex),
        phone: data.phone.startsWith('+(995)')
          ? data.phone
          : !data.phone
          ? ''
          : `+(995)${data.phone}`,
      },
    })
  }

  useEffect(() => {
    if (slug) {
      getUser({
        variables: { slug },
      })
    }
  }, [slug])

  const newState = {
    ...state,
    setShowAs,
    isFollowers,
    isFollowings,
    user: data?.user,
    error,
    loading,
    editUser,
    editUserData,
    notification,
  }

  return (
    <UserContext.Provider value={{ ...newState }}>
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
