import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'

import Avatar from 'components/Avatar'
import Grid from 'components/Grid'
import Modal from 'components/Modal'
import Button from 'components/Forms/Button'
import Input from 'components/Forms/Input'
import Select from 'components/Forms/Select'
import Icon from 'components/Icon'

import useSkills from 'hooks/useSkills'
import { yupResolver } from '@hookform/resolvers/yup'
import { requestSchema } from 'validation'
import moment from 'moment'

import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, convertFromHTML } from 'draft-js'

import * as CS from './styled'
import * as S from '../styled'
import { useTranslation } from 'react-i18next'
import useMetaData from 'hooks/useMetaData'

const previewLink = (str) => {
  return str?.split(' ').find((st) => st.startsWith('https'))
}

const CreateRequest = (props) => {
  const { me } = useAuthorizedUser()
  const [chosenSkills, setChosenSkills] = useState([])
  const [defaultValue, setDefaultValue] = useState({})

  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(requestSchema(props.request?.title)),
    defaultValues: defaultValue,
  })

  const { getSkills, skillsData } = useSkills()
  const [skillsList, setSkillsList] = useState({})
  const [categoriesError, setCategoriesError] = useState(false)
  const [type, setType] = useState([])
  const [showVoluntsInput, setShowVoluntsInput] = useState(false)
  const [editorShown, setEditorShown] = useState(false)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const onEditorStateChange = (editorState) => setEditorState(editorState)

  const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  const containHtmlContent = props.request?.description?.startsWith('<')
  const { getMetaInfo, metaInfo } = useMetaData()

  useEffect(() => {
    const blocksFromHTML = convertFromHTML(props.request?.description || '')
    const initialEditorState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )

    setEditorState(EditorState.createWithContent(initialEditorState))
  }, [props.request?.description])

  const coverImage = watch('cover')
  const inpDescription = watch('description')

  useEffect(() => {
    const metaUrl = previewLink(inpDescription)

    if (metaUrl) {
      getMetaInfo(metaUrl, setValue)
    }
  }, [inpDescription])

  const getSkillsInfo = (curr) => {
    return {
      label: curr.name,
      value: curr.id,
    }
  }

  useEffect(() => {
    return () => {
      reset()
      setEditorShown(false)
      if (metaInfo) {
        getMetaInfo('', setValue)
      }
    }
  }, [props.onClose])

  useEffect(() => {
    getSkills()
    setSkillsList(
      skillsData?.skills?.reduce((acc, curr) => {
        const arr = curr.children.map(getSkillsInfo)
        return acc.concat(arr)
      }, [])
    )
    let organization = me?.userOrganizations?.map((item) => {
      return {
        label: item?.title,
        value: item?.id,
      }
    })
    setType(organization)
  }, [skillsData, me])

  const submitHandler = (args) => {
    if (!chosenSkills.length && !props.request?.title) {
      setCategoriesError(t('post.requiredToSelectCategories'))
      return
    }
    if (props.request) {
      const payload = {
        id: props.request?.id,
        organization_id: args.organization_id
          ? args.organization_id
          : props.request?.organization_id,
        categories: chosenSkills,
        external_image: args.external_image || metaInfo?.external_image,
        cover: args?.cover,
        og_url: metaInfo?.url,
        description:
          editorShown || containHtmlContent ? description : args.description,
        end_date: args.end_date
          ? args.end_date
          : moment(props.request?.end_date),
        title: args.title,
        type: args.type,
        volunt: args.volunt
          ? args.volunt.toString()
          : props.request?.volunt.toString(),
        workers: args.workers.toString(),
      }

      props.editRequest(payload)
    } else {
      props.addRequest({
        ...args,
        og_url: metaInfo?.url,
        volunt: args.volunt || '1',
        cover: !watch('external_image') && args.cover,
        categories: chosenSkills,
        description: editorState.getCurrentContent().hasText()
          ? description
          : args.description,
      })
    }
    window.scrollTo(0, 0)
    props.onClose()
    setCategoriesError(null)
  }

  const defaultInputValue = () => {
    const skillsArr = []
    props.request?.skills?.map((each, i) => {
      skillsArr.push(each.id)
    })

    const payload = {
      organization_id: props.request?.organization_id,
      category: skillsArr,
      title: props.request?.title,
      description: props.request?.description,
      workers: props.request?.workers,
      number: props.request?.volunt,
    }
    setDefaultValue(payload)
    reset(payload)
  }

  useEffect(() => {
    defaultInputValue()
  }, [props.show])

  return (
    <Modal
      title={props.request ? t('post.editRequest') : t('post.createRequest')}
      {...props}
    >
      <S.ModalForm onSubmit={handleSubmit(submitHandler)} autoComplete="off">
        <Grid type="avatar">
          <Avatar type="simple" src={me?.avatar?.path} sex={me?.sex} />
          <h5>{t('post.applicant')}</h5>
          <p>
            {me?.name} {me?.surname}
          </p>
        </Grid>

        <Select
          options={type}
          register={register}
          name="organization_id"
          placeholder={t('post.selectOrganization')}
          defVal={type?.filter(
            (each) => each.value === props.request?.organization_id
          )}
        />

        <CS.CategoriesList>
          <span>{t('post.categoriesNumNotRestrected')}</span>
          <Input
            isMulti
            isSearchable
            type="select"
            name="category"
            register={register}
            options={skillsList}
            placeholder={t('post.selectCategory')}
            onChange={(e) => setChosenSkills(e.map((el) => el.value))}
            setChosenSkills={setChosenSkills}
            defVal={props.request?.skills}
          />
          {categoriesError && <CS.Message>{categoriesError}</CS.Message>}
        </CS.CategoriesList>

        <div style={{ display: 'none' }}>
          <Input
            type="hidden"
            name="type"
            value="request"
            register={register}
          />
        </div>
        <Input
          placeholder={t('post.title')}
          name="title"
          error={errors?.title}
          register={register}
        />
        {!(editorShown || containHtmlContent) && (
          <div style={{ position: 'relative' }}>
            <Input
              type="textarea"
              placeholder={t('post.detailedDescription')}
              error={errors?.description}
              name="description"
              register={register}
            />

            {(metaInfo?.og_image || props.data?.cover) && (
              <CS.MetaImage src={metaInfo?.og_image || props.data?.cover} />
            )}

            {metaInfo ? (
              <CS.EditorSwitcher
                onClick={() => {
                  getMetaInfo('', setValue)
                  setValue('title', '')
                  setValue('description', '')
                }}
              >
                <Icon filename="delete" />
              </CS.EditorSwitcher>
            ) : (
              <CS.EditorSwitcher onClick={() => setEditorShown(true)}>
                <Icon filename="edit" />
              </CS.EditorSwitcher>
            )}
          </div>
        )}
        {editorShown && (
          <CS.IconContainer>
            <Icon
              filename="arrow-right"
              onClick={() => setEditorShown(false)}
            />
          </CS.IconContainer>
        )}
        {(editorShown || containHtmlContent) && (
          <div>
            <Editor
              editorState={editorState}
              editorClassName="demo-editor"
              wrapperClassName="demo-wrapper"
              editorStyle={{
                minHeight: '100px',
                paddingLeft: '15px',
                borderTopRadius: '20px',
                borderBottomRadius: '20px',
                background: 'rgba(197,201,202,0.1)',
              }}
              onEditorStateChange={onEditorStateChange}
            />
            <textarea disabled hidden value={description} />
          </div>
        )}
        <Input
          placeholder={t('post.endDate')}
          type="date"
          name="end_date"
          error={errors?.end_date}
          register={register}
          defVal={props.request?.end_date}
        />

        <CS.Helpers>
          {showVoluntsInput ? (
            <CS.InputContainer>
              <Input
                name="workers"
                error={errors?.workers}
                placeholder={t('post.volunteersCount')}
                register={register}
              />
            </CS.InputContainer>
          ) : (
            <CS.VolunteersQuantity>
              <CS.BulletCont>
                <p>{t('post.neededVolunteersCount')}</p>
                <CS.HelpersInput>
                  <Input
                    type="radio"
                    name="workers"
                    value="1"
                    register={register}
                    label="1"
                  />
                  <Input
                    type="radio"
                    name="workers"
                    value="2"
                    register={register}
                    label="2"
                  />
                  <Input
                    type="radio"
                    name="workers"
                    value="3"
                    register={register}
                    label="3"
                  />
                  <Input
                    type="radio"
                    name="workers"
                    value="4"
                    register={register}
                    label="4"
                  />
                  <Input
                    type="radio"
                    name="workers"
                    value="5+"
                    register={register}
                    label="5+"
                    onClick={() => setShowVoluntsInput(true)}
                  />
                </CS.HelpersInput>
              </CS.BulletCont>
              {errors?.workers && (
                <CS.Message>{t('post.selectVolunteersCount')}</CS.Message>
              )}
            </CS.VolunteersQuantity>
          )}
        </CS.Helpers>

        <S.TimeCoin>
          <p>{t('post.timecoinsOffer')}</p>
          <Input
            type="range"
            minValue={1}
            maxValue={20}
            error={errors?.volunt}
            name="volunt"
            register={register}
            hasManualIncrement
            defaultRange={Number(props.request?.volunt)}
          />
        </S.TimeCoin>

        <S.FormActions end={metaInfo ? 'end' : ''}>
          {!(props.data?.external_image || metaInfo) && (
            <Input
              type="upload"
              name="cover"
              register={register}
              accept="image/*"
              label={
                coverImage ? (
                  <CS.CoverSelectNotificationText>
                    {t('post.imageUploadedSuccessfully')}
                  </CS.CoverSelectNotificationText>
                ) : (
                  t('post.imageMaxSize')
                )
              }
            />
          )}

          <Button type="primary" submit>
            {props.request ? t('post.editRequest') : t('post.createRequest')}
          </Button>
        </S.FormActions>
      </S.ModalForm>
    </Modal>
  )
}

export default CreateRequest
