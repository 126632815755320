import { gql } from '@apollo/client'
import { USER_FRAGMENT } from 'services/fragments/user'

export const GET_ORGANIZATION = gql`
  ${USER_FRAGMENT()}
  query getOrganization($id: Int) {
    organization(id: $id) {
      id
      volunt
      volunt_time
      title
      type
      slug
      is_member
      awaiting_approve
      description
      linkedin_url
      facebook_url
      instagram_url
      dribble_url
      pinterest_url
      twitter_url
      youtube_url
      behance_url
      email
      phone
      transaction_statistics {
        all {
          incoming
          outgoing
        }
        month {
          incoming
          outgoing
        }
        week {
          incoming
          outgoing
        }
      }
      profile_media {
        id
        path
        type
        user_id
        organization_id
        file_id
      }
      profile_media_count

      posts_media {
        id
        path
        type
        user_id
        organization_id
        file_id
      }
      posts_media_count

      requests_media {
        id
        path
        type
        user_id
        organization_id
        file_id
      }

      requests_media_count

      state {
        name
        id
      }
      join_requests {
        ...UserFragment
      }
      grouped_skills {
        children {
          id
          name
        }
        name
      }
      profile {
        path
        id
      }
      cover {
        path
        id
      }
      user {
        id
        slug
        name
        surname
        sex
        is_me
        email
        cover {
          id
          path
        }
        avatar {
          id
          path
        }
        is_online
      }
      members {
        id
        name
        surname
        sex
        email
        slug
        cover {
          id
          path
        }
        avatar {
          id
          path
        }
        is_online
      }
    }
  }
`

export const GET_ORGANIZATION_SKILLS = gql`
  query OrganizationSkills($organization_id: Int) {
    OrganizationSkills(organization_id: $organization_id) {
      id
      name
      children {
        id
        name
        owned(organization_id: $organization_id)
      }
    }
  }
`
