import { useMutation } from '@apollo/client'
import { UNSHOW_NOTIFICATION } from 'services/mutations/unshowNotification'

const useUnshowNotification = () => {
  const [unShowNotification] = useMutation(UNSHOW_NOTIFICATION, {
    errorPolicy: 'all',
    refetchQueries: ['getMe'],
  })

  return [unShowNotification]
}

export default useUnshowNotification
