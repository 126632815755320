import { useEffect, useState } from 'react'
import * as S from './styled'
import moment from 'moment'

const DateRangePicker = ({ register, name, ...rest }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const startName = `start_${name}`
  const endName = `end_${name}`
  const { onChange: onStartChange } = register(startName)
  const { onChange: onEndChange } = register(endName)

  const onDateChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)

    onStartChange({
      target: {
        name: startName,
        value: start,
      },
    })
    onEndChange({
      target: {
        name: endName,
        value: end,
      },
    })
  }

  return (
    <S.DateInput
      {...rest}
      selected={startDate}
      onChange={onDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
    />
  )
}

const DateInput = ({ register, name, variant, defVal, ...rest }) => {
  const [value, setValue] = useState('')
  const onDateChange = (v) => {
    onChange({
      target: {
        name,
        value: v,
      },
    })

    setValue(v)
  }

  useEffect(() => {
    if (defVal) {
      setValue(moment(defVal)?._d)
    }
  }, [defVal])

  if (variant === 'range') {
    return (
      <S.DatePickerBox>
        <DateRangePicker
          register={register}
          name={name}
          dropdownMode="select"
          showYearDropdown
          showMonthDropdown
          scrollableYearDropdown
          scrollableMonthYearDropdown
          {...rest}
        />
      </S.DatePickerBox>
    )
  }

  const { onChange } = register(name)
  return (
    <S.DatePickerBox>
      <S.DateInput
        {...rest}
        onChange={onDateChange}
        selected={value}
        name={name}
        showPopperArrow={false}
        placeholderText={rest?.placeholder}
        dropdownMode="select"
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        scrollableMonthYearDropdown
        todayButton={<S.TodayButton>Today</S.TodayButton>}
      />
    </S.DatePickerBox>
  )
}

export default DateInput
