import { gql } from '@apollo/client'

export const GET_CONVERSATION = gql`
  query conversations($limit: Int, $page: Int) {
    conversations(limit: $limit, page: $page) {
      data {
        id
        user {
          id
          slug
          name
          surname
          sex
          state {
            name
          }
          avatar {
            id
            path
          }
          address
          followed_by_you
          is_online
        }
        can_request_volunt_time {
          id
          volunt
          user_id
        }
        can_send_volunt_time {
          id
          volunt
          user_id
        }
        messages {
          id
          message
          created_at
          transaction_id
          can_approve_transaction
          can_reject_transaction
          can_rate {
            can_rate
            user_id
          }
          has_file
          has_image
          file {
            id
            path
          }
          image {
            id
            path
          }
          user {
            id
            slug
            name
            surname
            sex
            avatar {
              id
              path
            }
            is_me
            is_activated
            is_online
          }
        }
        last_message {
          id
          message
          unread
          created_at
          user {
            id
            slug
            name
            surname
            sex
            avatar {
              id
              path
            }
            is_online
          }
        }
      }
    }
  }
`
