import { gql } from '@apollo/client'

export const GET_ALL_SKILLS = gql`
  query skills {
    skills {
      id
      name
      children {
        id
        name
        own
      }
    }
  }
`
