import Button from 'components/Forms/Button'
import Icon from 'components/Icon'
import Avatar from 'components/Avatar'
import usePeopleMayKnow from 'hooks/usePeopleMayKnow'
import followUser from 'hooks/useFollow'
import useUnFollow from 'hooks/useUnFollow'
import * as S from './styled'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const YouMayKnow = () => {
  const history = useHistory()
  const [people] = usePeopleMayKnow()
  const [addFollowUser] = followUser()
  const [removeFollowUser] = useUnFollow()
  const { t } = useTranslation()

  return (
    <>
      {people && (
        <S.YouMayKnow>
          <S.YouMayKnowHeader>
            <h5>{t('feed.youMayKnow')}</h5>
            <Button
              type="clean"
              onClick={() => history.push('/peopleyoumayknow')}
            >
              {t('feed.seeAll')}
              <Icon filename="angle-right" />
            </Button>
          </S.YouMayKnowHeader>
          <S.YouMayKnowList>
            {people &&
              people?.slice(0, 5).map((item, i) => (
                <S.UserContent key={i}>
                  <S.User>
                    <Avatar
                      type="simple"
                      src={item?.avatar?.path}
                      sex={item?.sex}
                      onClick={() => history.push(`/user/${item.slug}`)}
                    />
                    <h4 onClick={() => history.push(`/user/${item.slug}`)}>
                      {item?.name} {item?.surname}
                    </h4>
                    <p>{item?.state?.name}</p>
                  </S.User>
                  <Button
                    type="clean"
                    onClick={(e) =>
                      item?.followed_by_you
                        ? removeFollowUser(item?.id)
                        : addFollowUser(item?.id)
                    }
                  >
                    <Icon filename="add" />
                    <span>
                      {item?.followed_by_you
                        ? t('common.unfollow')
                        : t('common.follow')}
                    </span>
                  </Button>
                </S.UserContent>
              ))}
          </S.YouMayKnowList>
          <S.Banner>
            <img src="/img/edec-min.jpg" alt="edec-min-image" />
            <a href="https://elearning.edec.ge/" target="_blank">
              <Button type="primary">www.elearning.edec.ge</Button>
            </a>
          </S.Banner>
        </S.YouMayKnow>
      )}
    </>
  )
}

export default YouMayKnow
