import Icon from 'components/Icon'
import * as S from './styled'

const RadioInput = ({ variant, label, name, register, ...rest }) => {
  const registeredObj = register(name)

  switch (variant) {
    case 'check': {
      return (
        <S.RadioCheck className="radio-check-input">
          <S.Input {...rest} {...registeredObj} type="radio" />
          <div>
            <i>
              <Icon filename="success" />
            </i>
            <span>{label}</span>
          </div>
        </S.RadioCheck>
      )
    }

    default:
      return (
        <S.Radio>
          <S.Input {...rest} {...registeredObj} type="radio" />
          <p>{label}</p>
        </S.Radio>
      )
  }
}

export default RadioInput
