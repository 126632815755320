const { useMutation } = require('@apollo/client')
const {
  UPDATE_NOTIFICATION_SETTINGS,
} = require('services/mutations/updateNotificationSettings')

const useUpdateNotificationSettings = () => {
  const [UpdateNotificationSettings, updateNotificationSettingsResult] =
    useMutation(UPDATE_NOTIFICATION_SETTINGS, {
      errorPolicy: 'all',
      refetchQueries: [],
    })

  const updateNotificationSettings = (arg) => {
    UpdateNotificationSettings({
      variables: {
        comment_notifications: arg,
      },
    })
  }

  return { updateNotificationSettings, updateNotificationSettingsResult }
}

export default useUpdateNotificationSettings
