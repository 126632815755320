import * as yup from 'yup'

const schema = yup.object({
    facebook_url: yup.string().matches(
            /(https?:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
            {message: "Enter correct url!", excludeEmptyString: true}
        ),

        instagram_url: yup.string().matches(
            /(https?:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
            {message: "Enter correct url!", excludeEmptyString: true}
        ),

        dribble_url: yup.string().matches(
            /(https?:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
             {message: "Enter correct url!", excludeEmptyString: true}
        ),
        youtube_url: yup.string().matches(
            /(https?:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
            {message: "Enter correct url!", excludeEmptyString: true}
        ),
        behance_url: yup.string().matches(
            /(https?:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
            {message: "Enter correct url!", excludeEmptyString: true}
        ),
        linkedin_url: yup.string().matches(
            /(https?:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
            {message: "Enter correct url!", excludeEmptyString: true}
        ),
        twitter_url: yup.string().matches(
            /(https?:\/\/)([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
            {message: "Enter correct url!", excludeEmptyString: true}
        )
})

export default schema
