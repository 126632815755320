import { useState, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { languages } from 'constants/global'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'
import { useUser } from 'contexts/UserProvider'

import Icon from 'components/Icon'
import Grid from 'components/Grid'
import Avatar from 'components/Avatar'
import Button from 'components/Forms/Button'
import InfoDropdown from 'components/InfoDropdown'
import Toast from 'components/Toast/Toast'

import UserNotifications from 'modules/Modals/UserNotifications'
import CreatePost from 'modules/Modals/CreatePost'
import OfferList from 'modules/Modals/OfferList'
import PrivateMessages from 'modules/PrivateMessages'
import InviteFriend from 'modules/Modals/InviteFriend'
import CreateRequest from 'modules/Modals/CreateRequest'
import CreateOrganization from 'modules/Modals/CreateOrganization'
import useLogout from 'hooks/useLogout'
import usePost from 'hooks/usePost'
import useRequest from 'hooks/useRequest'
import useOrganization from 'hooks/useOrganization'
import useDownloadFile from 'hooks/useDownloadFile'
import useUnshowNotification from 'hooks/useUnshowNotification'
import useUnshowMessages from 'hooks/useUnshowMessages'
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next'
import useSwitchLocale from 'hooks/useSwitchLocale'

import * as S from './styled'
import BottomMenu from 'components/BottomMenu'
import UserInformation from 'modules/Modals/UserInformation'
import useWindowWidth from 'hooks/useWindowWidth'

const AuthorizedUser = () => {
  const history = useHistory()
  const { showAs, setShowAs } = useUser()
  const { me } = useAuthorizedUser()
  const logout = useLogout()
  const { user } = useUser()
  const width = useWindowWidth()

  const [addPost] = usePost()
  const [addRequest] = useRequest(me?.id)
  const { addOrganization } = useOrganization()

  const [downloadFile] = useDownloadFile()
  const [switchLocale] = useSwitchLocale()

  const [notificationOpen, setNotificationOpen] = useState(false)
  const [messagesOpen, setMessagesOpen] = useState(false)
  const [requestOpen, setRequestOpen] = useState(false)
  const [postOpen, setPostOpen] = useState(false)
  const [offerShow, setOfferShow] = useState(false)
  const [organizationShow, setOrganizationShow] = useState(false)
  const [inviteFriendShow, setInviteFriendShow] = useState(false)
  const [activeInfo, setActiveInfo] = useState(false)
  const [createPostShown, setCreatePostShown] = useState(false)
  const [userInformationShow, setUserInformationShow] = useState(false)
  const [headerDropDownShown, setHeaderDropDownShowm] = useState(false)
  const [editInformationShown, setEditInformationShown] = useState(false)

  const { t } = useTranslation()
  const [unShowNotification] = useUnshowNotification()
  const [unShowMessages] = useUnshowMessages()

  const userActions = useRef([
    [
      {
        title: 'შექმენი პოსტი/მოთხოვნა',
        onClick: () => {
          setCreatePostShown(true)
        },
        fileName: 'add-circle',
      },
      {
        title: 'პირადი შეტყობინება',
        onClick: () => routeChange('/messages'),
        fileName: 'message',
      },
      {
        title: t('common.notification'),
        onClick: () => routeChange('/notifications'),
        fileName: 'notification',
      },
    ],
    [
      {
        title: t('common.createOrganization'),
        onClick: () => setOrganizationShow(true),
      },
    ],
    [
      {
        title: 'სოციალური ქსელები',
        onClick: () => setOrganizationShow(true),
        fileName: 'share',
      },
    ],
    [
      {
        name: 'ჩვენ შესახებ',
        onClick: () => {
          routeChange('/about-us')
        },
      },
      { name: 'დახმარება' },
      { name: 'სიახლეები' },
      { name: 'წესები და პირობები' },
      { name: 'FAQ', onClick: () => routeChange('/faq') },
      { name: 'კონტაქტი', onClick: () => routeChange('/Contact') },
    ],
  ])

  const routeChange = (route) => history.push(route)
  const profilePage = window.location.pathname.split('/')[1] === 'user'

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng?.value)
    switchLocale(lng?.value)
  }

  const backdropHandler = () => {
    setCreatePostShown(false)
    setHeaderDropDownShowm(false)
  }

  const addPostHandler = () => {
    setPostOpen(true)
    setCreatePostShown(false)
    setHeaderDropDownShowm(false)
  }

  const addRequestHandler = () => {
    setRequestOpen(true)
    setCreatePostShown(false)
    setHeaderDropDownShowm(false)
  }

  return (
    <S.AuthorizedContent>
      <S.DesktopSize>
        <S.AddContainer>
          <InfoDropdown
            actions={
              <Button type="clean">
                <Icon filename="add-circle" />
              </Button>
            }
            minWidth={310}
            style={{ padding: '20px 10px' }}
          >
            <Grid type="avatar" onClick={() => setRequestOpen(true)}>
              <Icon filename="like" />
              <h5>{t('feed.createRequest')}</h5>
              <p>{t('common.getVolunteersHelp')}</p>
            </Grid>
            <Grid type="avatar" onClick={() => setPostOpen(true)}>
              <Icon filename="like-tag" />
              <h5>{t('feed.createPost')}</h5>
              <p>{t('common.shareInformationOnPlatform')}</p>
            </Grid>
          </InfoDropdown>

          <CreateRequest
            show={requestOpen}
            addRequest={addRequest}
            onClose={() => setRequestOpen(false)}
          />
          <CreatePost
            show={postOpen}
            addPost={addPost}
            onClose={() => setPostOpen(false)}
          />
          <Toast />
        </S.AddContainer>

        <S.CalendarIcon
          onClick={() => history.push('/search?type=requests&value=')}
        >
          <img src="/img/calendar.png" />
        </S.CalendarIcon>

        <S.NotificationContainer>
          <InfoDropdown
            minWidth={420}
            actions={
              <Button
                type="clean"
                onClick={() => {
                  setMessagesOpen(!messagesOpen)
                  unShowMessages()
                }}
              >
                <>
                  <Icon filename="message" />
                  {me?.unread_messages > 0 && (
                    <S.NewNotificationsNumber>
                      {
                        <p className="new-notification">
                          {me?.unread_messages < 100
                            ? me?.unread_messages
                            : '99+'}
                        </p>
                      }
                    </S.NewNotificationsNumber>
                  )}
                </>
              </Button>
            }
          >
            <PrivateMessages />
          </InfoDropdown>
        </S.NotificationContainer>
        <S.NotificationContainer>
          <InfoDropdown
            actions={
              <Button
                type="clean"
                onClick={() => {
                  setNotificationOpen(!notificationOpen)
                  unShowNotification()
                }}
              >
                <>
                  <Icon filename="notification" />
                  {me?.unread_notifications > 0 && (
                    <S.NewNotificationsNumber>
                      {
                        <p className="new-notification">
                          {me?.unread_notifications < 100
                            ? me?.unread_notifications
                            : '99+'}
                        </p>
                      }
                    </S.NewNotificationsNumber>
                  )}
                </>
              </Button>
            }
            minWidth={420}
          >
            <UserNotifications
              commentNotifications={me?.comment_notifications}
            />
          </InfoDropdown>
        </S.NotificationContainer>
      </S.DesktopSize>

      <S.User>
        <InfoDropdown
          open={headerDropDownShown && !createPostShown}
          actions={
            <S.UserInfo
              onClick={() => {
                setHeaderDropDownShowm((shown) => !shown)
              }}
            >
              <Avatar type="simple" src={me?.avatar?.path} sex={me?.sex} />
              <div className="ico">
                <Icon filename="angle-down" className="angle-down" />
              </div>
            </S.UserInfo>
          }
        >
          <S.MoreInfoHeader>
            <S.AvatarInfo>
              <Avatar type="simple" src={me?.avatar?.path} sex={me?.sex} />
              <h4>
                {me?.name} {me?.surname}
              </h4>
            </S.AvatarInfo>
            <S.MediaVision>
              <S.AvailableTime>
                <span>{t('feed.availableTime')}</span>
                <p>
                  {me?.volunt_time || '0'} {t('common.minute')}
                </p>
              </S.AvailableTime>
            </S.MediaVision>
            <Button onClick={() => history.push(`/user/${me?.slug}`)}>
              {t('common.myProfile')}
            </Button>
          </S.MoreInfoHeader>
          <S.MoreInfoContent>
            <S.MediaVision>
              <S.UserActionList>
                {userActions.current[0].map((item, index) => (
                  <Button
                    key={index}
                    onClick={item.onClick}
                    className="with-icon"
                    type="clean"
                  >
                    <li>
                      {item.fileName === 'add-circle' ? (
                        <Icon filename={item.fileName} className="gold_icon" />
                      ) : (
                        <Icon filename={item.fileName} />
                      )}
                      <span>{item.title}</span>
                    </li>
                  </Button>
                ))}
              </S.UserActionList>
            </S.MediaVision>
            <S.UserActionList>
              {userActions.current[1].map((item, index) => (
                <li onClick={item.onClick} key={index}>
                  <Icon filename="add-circle" />
                  <span>{t('organization.createAnOrganization')}</span>
                </li>
              ))}

              <S.OrganizationsListBox>
                {me?.userOrganizations?.map((organization) => {
                  return (
                    <Link to={`/organization/${organization?.id}`}>
                      <S.OrganizationBox>
                        <S.OrganizationInfo>
                          <S.OrganizationAvatar
                            src={
                              organization?.profile?.path || '/img/company.png'
                            }
                            alt="organization-logo"
                          />
                          <div>
                            <S.OrganizationTitle>
                              {organization?.title}
                            </S.OrganizationTitle>
                            <p>{organization?.state?.name || 'თბილისი'}</p>
                          </div>
                        </S.OrganizationInfo>
                        {/* <Button type="clean">
                          <Icon filename="setting" />
                        </Button> */}
                      </S.OrganizationBox>
                    </Link>
                  )
                })}
              </S.OrganizationsListBox>
            </S.UserActionList>

            <S.Divider />

            <S.UserActionList>
              <li type="clean" onClick={() => setInviteFriendShow(true)}>
                <Icon filename="profile-add" />
                <span>{t('common.inviteFriend')}</span>
              </li>
              <li type="clean" onClick={() => routeChange('/bookmarks')}>
                <Icon filename="saved-labels" />
                <span>{t('common.savedPostsAndRequests')}</span>
              </li>
              <li onClick={() => setEditInformationShown(true)}>
                <Icon filename="setting" />
                <span>{t('common.parameters')}</span>
              </li>
              <li type="clean" onClick={() => setUserInformationShow(true)}>
                <Icon filename="information" />
                <span>{t('common.changeLanguage')}</span>
              </li>
              <S.LanguageModal
                noOverflow
                title={t('common.changeLanguage')}
                show={userInformationShow}
                onClose={() => setUserInformationShow(false)}
              >
                <S.LangSelect
                  options={languages}
                  icon="globe"
                  placeholder=""
                  defVal={i18n.language}
                  onChange={(e) => changeLanguage(e)}
                />
              </S.LanguageModal>
              {profilePage && (
                <S.MediaVision>
                  <Button className="with-icon" type="clean">
                    <li
                      onClick={() => downloadFile(me?.resume?.path, 'resume')}
                    >
                      <Icon filename="document-download" />
                      <span>{t('user.downloadYourResume')}</span>
                    </li>
                  </Button>
                  <Button
                    onClick={() => setShowAs(!showAs)}
                    className="with-icon"
                    type="clean"
                  >
                    <li>
                      <Icon filename="information" />
                      <span>{t('user.showAs')}</span>
                    </li>
                  </Button>
                  <S.Divider />
                </S.MediaVision>
              )}
              <S.MediaVision>
                <li onClick={() => setActiveInfo(!activeInfo)}>
                  <S.MenuRow activeInfo={activeInfo}>
                    <S.MenuRowBox>
                      <Icon filename="information" />
                      <span id="menu-box">{t('user.siteMenu')}</span>
                    </S.MenuRowBox>
                    <Icon filename="angle-down" />
                  </S.MenuRow>
                </li>

                <S.InfoMenu activeInfo={activeInfo}>
                  {userActions.current[3].map((item, index) => (
                    <li onClick={item.onClick} key={index}>
                      <span>{item.name}</span>
                    </li>
                  ))}
                </S.InfoMenu>
              </S.MediaVision>
            </S.UserActionList>

            <Button type="clean" className="with-icon" onClick={logout}>
              <Icon filename="logout" />
              <S.LogoutText>{t('common.logout')}</S.LogoutText>
            </Button>
          </S.MoreInfoContent>
          <OfferList show={offerShow} onClose={() => setOfferShow(false)} />
          <CreateOrganization
            show={organizationShow}
            onClose={() => setOrganizationShow(false)}
            addOrganization={addOrganization}
          />
          <InviteFriend
            show={inviteFriendShow}
            onClose={() => setInviteFriendShow(false)}
          />
          <UserInformation
            data={user}
            show={editInformationShown}
            onClose={() => setEditInformationShown(false)}
          />
        </InfoDropdown>
        {width < 992 && (
          <BottomMenu show={createPostShown} onBackdropClick={backdropHandler}>
            <Grid
              type="avatar"
              className="btm-menu-grid"
              onClick={addPostHandler}
            >
              <Icon filename="like-tag" />
              <div>
                <h5>{t('feed.createPost')}</h5>
                <p>{t('common.shareInformationOnPlatform')}</p>
              </div>
            </Grid>
            <Grid
              type="avatar"
              className="btm-menu-grid"
              onClick={addRequestHandler}
            >
              <Icon filename="like" />
              <div>
                <h5>{t('feed.createRequest')}</h5>
                <p>{t('common.getVolunteersHelp')}</p>
              </div>
            </Grid>
          </BottomMenu>
        )}
      </S.User>
    </S.AuthorizedContent>
  )
}

export default AuthorizedUser
