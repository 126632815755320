import Grid from 'components/Grid'

import styled from 'styled-components'

export const GridContainer = styled(Grid)`
  display: flex;
  gap: 20px;
  align-items: flex-start;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    gap: 7px;
    width: 100%;

    .avatar {
      width: 36px;
      height: 36px;
    }
  }
`

export const Notifications = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NotificationsListBox = styled.div`
  margin: 20px 0px;
  max-height: 50vh;
  width: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffce07;
    border-radius: 10px;
    outline: 1px solid #ffce07;
  }
`

export const NotificationTime = styled.p`
  text-align: center;
`

export const NotificationsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const NotificationList = styled.div`
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 13px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 10px;
    padding: 0;
  }
`

export const NotificationItem = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: ${(props) => props.padding || '10px'};
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  border-radius: ${(props) => props.borderRadius || '5px'};
  gap: 15px;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.background[100]};
  background-color: ${(props) =>
    props.unreadMessage && 'rgba(232,232,232, 0.5)'};
  margin: 3px 0px;

  .date {
    padding: 5px 9px 9px 10px;
    color: ${(props) =>
      props.unreadMessage ? props.theme.colors.white : '#8D9297'};
    background-color: ${(props) =>
      props.unreadMessage ? props.theme.colors.primary[700] : 'transparent'};
    border-radius: 37px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
      height: 100%;
      font-size: 12px;
      padding: 3px 5px;
      margin-left: auto;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    gap: 0;
    border-bottom: 1px solid #f0eded;
    padding: 10px;
  }
`

export const NameParagraph = styled.h5`
  display: inline-block;
  padding-bottom: 3px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) => props.theme.fontSizes.sm};
    font-weight: 100;
    color: ${(props) => props.theme.colors.text['700']};
    display: flex;
    flex-wrap: wrap;
  }
`

export const NotificationText = styled.span`
  margin-left: 0;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.webkitLineClamp || 2};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-size: ${(props) => props.theme.fontSizes.xs};
  }
`

export const NotificationContainer = styled.div`
  width: ${(props) => props.notificationBoxWidth || '220px'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
