import moment from 'moment'

export const calculateDateDiffString = (dateStr) => {
  const currentDate = moment()
  const d = moment(dateStr)
  const diffDays = currentDate.diff(d, 'days')
  if (diffDays > 0) {
    if (diffDays === 1) {
      return 'გუშინ'
    }
    if (diffDays > 9) {
      const diffYears = currentDate.diff(d, 'years')
      if (diffYears > 0) {
        return `${d.date()}/${d.month()}/${d.year()}`
      }
      return `${d.date()}/${d.month()}`
    }
    return `${diffDays} დღე`
  }
  const diffHours = currentDate.diff(d, 'hours')
  if (diffHours > 0) {
    return `${diffHours} სთ`
  }
  const diffMin = currentDate.diff(d, 'minutes')
  if (diffMin > 0) {
    return `${diffMin} წთ`
  }

  return `ახლახანს`
}
