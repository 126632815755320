import useFeedFooter from 'hooks/useFeedFooter'
import React from 'react'
import * as S from './styled'

const FeedFooter = () => {
  const footerData = useFeedFooter()

  return (
    <div>
      <S.FeedFooterBox>
        <ul>
          {footerData?.FooterContent?.navigation[0]?.items?.map(
            (item, index) => {
              return (
                <S.ListItem key={index}>
                  <a target="_blank" rel="noreferrer" href={item.url}>
                    {item.title}
                  </a>
                </S.ListItem>
              )
            }
          )}
        </ul>
        <ul>
          {footerData?.FooterContent?.navigation[1]?.items?.map(
            (item, index) => {
              return (
                <S.ListItem key={index}>
                  <a target="_blank" rel="noreferrer" href={item.url}>
                    {item.title}
                  </a>
                </S.ListItem>
              )
            }
          )}
        </ul>
      </S.FeedFooterBox>
      <p>{footerData?.FooterContent?.copyright_text}</p>
    </div>
  )
}

export default FeedFooter
