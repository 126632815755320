import { useState } from 'react'
import Icon from 'components/Icon'
import InfoDropdown from 'components/InfoDropdown'
import Button from 'components/Forms/Button'
import { Link } from 'react-router-dom'
import { useUser } from 'contexts/UserProvider'
import useOrganization from 'hooks/useOrganization'
import { useTranslation } from 'react-i18next'
import Alert from 'modules/Modals/Alert'

import * as S from './styled'

const MyOrganizations = ({ myData }) => {
  // მაჩვენე ყველას ფუნქციონალია დასამატებელი. ყველა ორგანიზაცია გამოაქვს და 5-ს ვუჩვენებთ დეფოლტად.
  const { user } = useUser()
  const is_me = user?.is_me
  const [organizationId, setOrganizationId] = useState(null)
  const [alertShown, setAlertshown] = useState(false)
  const { deleteOrganization } = useOrganization()

  const { t } = useTranslation()

  return (
    <>
      {user?.userOrganizations?.length || myData?.userOrganizations?.length ? (
        <S.MyOrganizations>
          <h4>
            {(is_me || myData) && t('user.my')} {t('common.organizations')}
          </h4>
          <S.Organizations>
            {(user?.userOrganizations || myData?.userOrganizations)
              ?.slice(0, 5)
              .map((organization, i) => (
                <S.Organization key={i}>
                  <S.OrganizationInfo>
                    <Link to={`/organization/${organization.id}`}>
                      <S.OrganizationAvatar
                        src={organization?.profile?.path || '/img/company.png'}
                        alt="organization-profile"
                        type="simple"
                      />
                    </Link>
                    <div>
                      <h5>
                        <Link to={`/organization/${organization?.id}`}>
                          {organization?.title}
                        </Link>
                      </h5>
                      <p>{organization?.state?.name}</p>
                    </div>
                  </S.OrganizationInfo>
                  {(is_me || myData) && (
                    <S.SettingsMenu>
                      <InfoDropdown
                        actions={
                          <Button type="clean">
                            <Icon filename="setting" />
                          </Button>
                        }
                        minWidth={210}
                      >
                        <div>
                          <Icon filename="trash" />
                          <p
                            onClick={() => {
                              setOrganizationId(Number(organization.id))
                              setAlertshown(true)
                            }}
                          >
                            {t('organization.deleteOrganization')}
                          </p>
                        </div>
                      </InfoDropdown>
                    </S.SettingsMenu>
                  )}
                </S.Organization>
              ))}
          </S.Organizations>
        </S.MyOrganizations>
      ) : null}
      <Alert
        show={alertShown}
        onClose={() => setAlertshown(false)}
        onConfirm={() => {
          deleteOrganization(organizationId)
          setAlertshown(false)
        }}
      >
        {t('organization.deleteOrganizationWarning')}
      </Alert>
    </>
  )
}

export default MyOrganizations
