import { useLazyQuery } from '@apollo/client'
import { GET_META_DATA } from 'services/queries/getMetaData'

const useMetaData = () => {
  const [getMetaInfomation, { loading, data }] = useLazyQuery(GET_META_DATA, {
    fetchPolicy: 'cache',
  })

  const getMetaInfo = (url, setValue) => {
    getMetaInfomation({
      variables: {
        url,
      },
      onCompleted: (data) => {
        setValue('title', data?.GetMetaData?.meta_title)
        setValue('external_image', data?.GetMetaData?.og_image)

        if (data?.GetMetaData !== null) {
          setValue(
            'description',
            `${data?.GetMetaData?.url} \n \n ${data?.GetMetaData?.meta_description}`
          )
        }
      },
    })
  }

  return { getMetaInfo, metaInfo: data?.GetMetaData, loading }
}

export default useMetaData
