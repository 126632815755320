import { useQuery } from '@apollo/client'

const useLanding = (gql) => {
  const lang = localStorage.getItem('i18nextLng')

  const { loading, error, data } = useQuery(gql, {
    errorPolicy: 'all',
    variables: {
      lang,
    },
  })

  return {
    loading,
    error,
    data,
  }
}

export default useLanding
