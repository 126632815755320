import { useState, useEffect } from 'react'
import Button from 'components/Forms/Button'
import Icon from 'components/Icon'
import * as S from './styled'

import { createPortal } from 'react-dom'

const Modal = (props) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(props.show)

    if (props.show) {
      document.querySelector('body').style.overflow = 'hidden'
    } else {
      document.querySelector('body').style.overflow = 'unset'
    }
  }, [props.show])

  if (!open) {
    return null
  }

  const element = document.getElementById('modal-root')

  return createPortal(
    <S.Modal>
      <S.ModalContent
        modalMaxWidth={props.modalMaxWidth}
        modalMaxHeight={props.modalMaxHeight}
      >
        <S.ModalHeader>
          <h3>{props.title}</h3>
          <Button type="clean" onClick={props.onClose} className="close">
            <Icon filename="close-square" />
          </Button>
        </S.ModalHeader>
        <S.ModalBody
          noOverflow={props.noOverflow}
          height={props.height}
          id={props.id}
          responsiveMaxHeight={props.responsiveMaxHeight}
        >
          {props.children}
        </S.ModalBody>
        <S.ModalActions
          displayFlex={props.displayFlex}
          justifyContent={props.justifyContent}
        >
          {props.modalActions}
        </S.ModalActions>
      </S.ModalContent>
    </S.Modal>,
    element
  )
}

export default Modal
