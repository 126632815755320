import { gql } from '@apollo/client'
import { POST_FRAGMENT } from 'services/fragments/post'

export const ADD_POST = gql`
  ${POST_FRAGMENT}
  mutation CreatePost(
    $title: String
    $organization_id: Int
    $type: String
    $description: String
    $cover: Upload
    $volunt: Int
    $workers: Int
    $end_date: String
    $categories: [Int]
    $og_url: String
    $external_image: String
  ) {
    CreatePost(
      title: $title
      organization_id: $organization_id
      type: $type
      description: $description
      cover: $cover
      volunt: $volunt
      workers: $workers
      end_date: $end_date
      categories: $categories
      og_url: $og_url
      external_image: $external_image
    ) {
      response
      post {
        ...PostFragment
      }
    }
  }
`

export const ADD_COMMENT = gql`
  mutation CreateComment($post_id: Int, $body: String, $parent_id: Int) {
    CreateComment(post_id: $post_id, body: $body, parent_id: $parent_id) {
      response
      comment {
        id
        parent_id
        body
        commentCount
        likedUser
        user {
          id
          slug
          name
          surname
        }
      }
    }
  }
`

export const ADDREPLY_COMMENT = gql`
  mutation CreateComment($post_id: Int, $comment_id: Int, $body: String) {
    CreateComment(post_id: $post_id, comment_id: $comment_id, body: $body) {
      response
      comment {
        id
        parent_id
        body
        user {
          id
          slug
          name
          surname
        }
      }
    }
  }
`

export const LIKE_COMMENT = gql`
  mutation likeComment($comment_id: Int) {
    LikeComment(comment_id: $comment_id) {
      response
    }
  }
`

export const UNLIKE_COMMENT = gql`
  mutation unLikeComment($comment_id: Int) {
    UnLikeComment(comment_id: $comment_id) {
      response
    }
  }
`

export const HIDE_COMMENT = gql`
  mutation HideComment($comment_id: Int) {
    HideComment(comment_id: $comment_id) {
      response
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: Int) {
    DeleteComment(id: $id) {
      response
    }
  }
`

export const ADD_LIKE = gql`
  mutation likePost($post_id: Int) {
    LikePost(post_id: $post_id) {
      response
      post {
        id
      }
    }
  }
`

export const REMOVE_LIKE = gql`
  mutation unLikePost($post_id: Int) {
    UnLikePost(post_id: $post_id) {
      response
      post {
        id
      }
    }
  }
`

export const HIDE_POST = gql`
  mutation HidePost($post_id: Int) {
    HidePost(post_id: $post_id) {
      response
    }
  }
`

export const DELETE_POST = gql`
  mutation DeletePost($post_id: Int) {
    DeletePost(post_id: $post_id) {
      response
    }
  }
`
