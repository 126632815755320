import styled from 'styled-components'
import RSelect from 'react-select'

export const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  border: 1px solid #c5c9ca;
  border-radius: 43px;
  padding: 6px 20px;
  /* width: 133px; */
  align-items: center;
  position: relative;
  grid-gap: 10px;
  background: ${(props) => props.background || 'transparent'};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 6px 10px;
    grid-gap: 3px;
  }
  .custom-select__menu-portal {
    width: 100%;
    left: 0px;
    top: unset;
    bottom: 0px;
  }
`

export const Select = styled(RSelect)`
  .custom-select__control {
    border: none;
    outline: none !important;
    box-shadow: unset;
    cursor: pointer;
    background: transparent;

    .custom-select__value-container,
    .custom-select__input-container {
      padding: 0px;
    }

    .custom-select__single-value {
      display: flex;
      gap: 5px;
      align-items: center;

      svg {
        width: 17px;
        path {
          stroke: ${(props) => props.theme.colors.text[500]};
        }
      }
    }
  }

  .custom-select__indicator-separator {
    display: none;
  }

  .custom-select__indicator {
    padding: 0px;
  }

  .custom-select__control--menu-is-open{
    svg{
      transform: rotate(180deg);
    }
  }
`
