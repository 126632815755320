import styled from 'styled-components'

export const FeedFooterBox = styled.div`
  display: flex;
  justify-content: space-around;
  background: ${(props) => props.theme.colors.white};
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
`

export const ListItem = styled.li`
  padding: 10px 0px;
  cursor: pointer;
  list-style: none;
  a {
    color: #414042;
  }
`
