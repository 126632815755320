import { useMutation } from '@apollo/client'
import { UNFOLLOW_USER } from 'services/mutations/followUser'
import { GET_PEOPLE } from 'services/queries/getPeopleYouMayKnow'

const useUnFollow = () => {
  const [removeFollowMutation, unFollowUserResult] = useMutation(
    UNFOLLOW_USER,
    {
      errorPolicy: 'all',
      refetchQueries: [{ query: GET_PEOPLE }, 'getUser', 'UsersSearch', 'followers', 'followings'],
    }
  )

  const unfollowUser = (data) => {
    removeFollowMutation({
      variables: {
        user_id: data,
      },
    })
  }

  return [unfollowUser, unFollowUserResult]
}

export default useUnFollow
