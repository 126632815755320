import { SET_SHOW_AS } from './types'

export const initialState = {
  showAs: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_AS: {
      return {
        ...state,
        showAs: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
