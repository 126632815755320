import styled from 'styled-components'
import Button from 'components/Forms/Button'

export const UserSidebarSettings = styled.div`
  background-color: white;
  padding: 26px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 37px;
  max-width: 360px;
`

export const Setting = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    text-align: left;
  }
`

export const ProfileList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
