import Icon from 'components/Icon'
import Button from '../Button'
import * as S from './styled'

const CopyInput = (props) => {
  const copy = () => {
    navigator?.clipboard?.writeText?.(props.value)
  }

  return (
    <S.CopyInput
      className="copy-input"
      inputWidth={props.inputWidth}
      paddingResponsive={props.paddingResponsive}
      padding={props.padding}
    >
      <S.Input {...props} disabled />
      <Button type="clean" onClick={copy}>
        <Icon filename="copy" />
      </Button>
    </S.CopyInput>
  )
}

export default CopyInput
