import * as S from './styled'

const Grid = ({ type, className, ...rest }) => {
  const baseClassName = `grid ${className || ''}`

  switch (type) {
    case 'avatar': {
      return (
        <S.AvatarGrid className={`avatar-grid ${baseClassName}`} {...rest} />
      )
    }

    case 'avatar-online': {
      return (
        <S.AvatarOnline
          className={`avatar-online-grid ${baseClassName}`}
          {...rest}
        />
      )
    }

    default: {
      return <S.Grid className={baseClassName} {...rest} />
    }
  }
}

export default Grid
