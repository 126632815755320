import { useGoogleLogin, useGoogleLogout } from 'react-google-login'
import useLogin from 'hooks/useLogin'

const credentials = {
  clientId: '',
}

const useGoogleAuth = (props) => {
  const { socialLogin } = useLogin()
  const { signOut } = useGoogleLogout({
    ...credentials,
    onLogoutSuccess: () => {},
    onFailure: (e) => {
      console.error(e)
    },
  })
  const { signIn, loaded } = useGoogleLogin({
    onSuccess: (e) => {
      if (e?.profileObj && e?.accessToken && !props.login) {
        props.handleSocialChange?.({
          email: e?.profileObj?.email,
          name: e?.profileObj?.givenName,
          surname: e?.profileObj?.familyName,
          phone: e?.profileObj?.phone,
          token: e?.accessToken,
          provider: 'google',
        })
        signOut()
      }

      if (props.login) {
        socialLogin({
          token: e?.accessToken,
          social_provider: 'google',
        })
      }
    },
    onFailure: (e) => {
      console.error(e)
    },
    cookiePolicy: 'single_host_origin',
    ...credentials,
  })

  return {
    signIn,
    signOut,
    loaded,
  }
}

export default useGoogleAuth
