import styled from 'styled-components'

const calculatePadding = (size) => `calc((100vw - ${size}) / 2)`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 20px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 0;
    margin: auto;
    max-width: ${(props) => props.theme.containers.lg};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding: 0px;
    margin: auto;
    max-width: ${(props) => props.theme.containers.xl};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0px 10px;
  }
`

export const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 20px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding-left: ${(props) => calculatePadding(props.theme.containers.lg)};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding-left: ${(props) => calculatePadding(props.theme.containers.xl)};
  }
`

export const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 20px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    padding-right: ${(props) => calculatePadding(props.theme.containers.lg)};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding-right: ${(props) => calculatePadding(props.theme.containers.xl)};
  }
`

export const FeedContainer = styled(Container)`
  margin-top: ${(props) => props.showAsOtherUser && '150px'};
  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    /* max-width: ${(props) => props.theme.containers.feedSm}; */
    max-width: ${(props) => props.theme.containers.lg};
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    max-width: ${(props) => props.theme.containers.feed};
  }
`
