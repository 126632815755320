import Avatar from 'components/Avatar'
import Grid from 'components/Grid'
import Modal from 'components/Modal'
import Button from 'components/Forms/Button'
import Icon from 'components/Icon'
import * as S from '../styled'
import * as CS from './styled'

const OfferList = (props) => {
  return (
    <Modal title="შეთავაზებების სია" {...props}>
      <CS.OfferList>
        <CS.OfferListItem>
          <Grid type="avatar">
            <Avatar type="simple" src="/img/user/user1.jpg" />
            <h5>ნინიკო69</h5>
            <p>თბილისი</p>
          </Grid>
          <Button type="clean">
            <Icon filename="add" />
          </Button>
        </CS.OfferListItem>
        <CS.OfferListItem>
          <Grid type="avatar">
            <Avatar type="simple" src="/img/user/user1.jpg" />
            <h5>ნინიკო69</h5>
            <p>თბილისი</p>
          </Grid>
          <Button type="clean">
            <Icon filename="add" />
          </Button>
        </CS.OfferListItem>
        <CS.OfferListItem>
          <Grid type="avatar">
            <Avatar type="simple" src="/img/user/user1.jpg" />
            <h5>ნინიკო69</h5>
            <p>თბილისი</p>
          </Grid>
          <Button type="clean">
            <Icon filename="add" />
          </Button>
        </CS.OfferListItem>
        <CS.OfferListItem>
          <Grid type="avatar">
            <Avatar type="simple" src="/img/user/user1.jpg" />
            <h5>ნინიკო69</h5>
            <p>თბილისი</p>
          </Grid>
          <Button type="clean">
            <Icon filename="add" />
          </Button>
        </CS.OfferListItem>
        <CS.OfferListItem>
          <Grid type="avatar">
            <Avatar type="simple" src="/img/user/user1.jpg" />
            <h5>ნინიკო69</h5>
            <p>თბილისი</p>
          </Grid>
          <Button type="clean">
            <Icon filename="add" />
          </Button>
        </CS.OfferListItem>
      </CS.OfferList>

      <S.FormActions>
        <CS.ChosenOnes>
          <h5>არჩეული მოხალისეები</h5>
          <CS.ChosensList>
            <Icon filename="avatar-add" />
            <Icon filename="avatar-add" />
            <Icon filename="avatar-add" />
          </CS.ChosensList>
        </CS.ChosenOnes>
      </S.FormActions>
    </Modal>
  )
}

export default OfferList
