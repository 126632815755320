import { useMutation } from '@apollo/client'
import { UNSHOW_MESSAGES } from 'services/mutations/unshowMessages'

const useUnshowMessages = () => {
  const [unShowMessages] = useMutation(UNSHOW_MESSAGES, {
    errorPolicy: 'all',
    refetchQueries: ['getMe'],
  })

  return [unShowMessages]
}

export default useUnshowMessages
