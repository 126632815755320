import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DefaultLayout from 'layouts/default'
import { useAuthToken } from 'hooks/useAuthToken'
import Loader from 'components/Loader/Loader'
import getRoutes from './routes'
import i18n from '../i18n'
import * as S from './styled'


const Protected = ({ Component, ...rest }) => {
  const [authToken] = useAuthToken()
  const { t } = useTranslation()

  if (authToken) {
    return <Component t={t} i18n={i18n} {...rest} />
  }

  return <Redirect to="/login" />
}

const NotProtected = ({ Component, ...rest }) => {
  const [authToken] = useAuthToken()
  const { t, i18n } = useTranslation()

  const blackList = ['/', '/login', '/register']

  if (authToken && blackList.includes(rest?.location?.pathname)) {
    return <Redirect to="/feed" />
  }

  return <Component t={t} i18n={i18n} {...rest} />
}

const Routes = () => {
  const routes = getRoutes()

  return (
    <Suspense
      fallback={
        <S.loaderContainer>
          <Loader />
        </S.loaderContainer>
      }
    >
      <Switch>
        {routes.map(({ path, Component, exact, layout, isProtected }, key) => {
          const Layout = layout ? layout : DefaultLayout

          const Render = (props) => (
            <Layout>
              {isProtected ? (
                <Protected Component={Component} {...props} />
              ) : (
                <NotProtected Component={Component} {...props} />
              )}
            </Layout>
          )

          return (
            <Route
              exact={exact}
              path={path}
              key={key}
              render={(props) => {
                return <Render {...props} />
              }}
            />
          )
        })}
      </Switch>
    </Suspense>
  )
}

export default Routes
