import { useContext, createContext, useEffect } from 'react'
import { useAuthToken } from 'hooks/useAuthToken'
import { useLazyQuery } from '@apollo/client'
import { GET_ME } from 'services/queries/getMe'

const AuthorizedUserContext = createContext()

export const useAuthorizedUser = () => useContext(AuthorizedUserContext)

const AuthorizedUserProvider = (props) => {
  const [getMe, { data }] = useLazyQuery(GET_ME)
  const [authToken] = useAuthToken()

  useEffect(() => {
    if (authToken) {
      getMe()
    }
  }, [authToken, getMe])

  return (
    <AuthorizedUserContext.Provider value={{ ...data }}>
      {props.children}
    </AuthorizedUserContext.Provider>
  )
}

export default AuthorizedUserProvider
