import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation loginMutation($login: String, $password: String) {
    login(login: $login, password: $password) {
      token
      expiration_date
    }
  }
`

export const SOCIAL_LOGIN = gql`
  mutation socialLoginMutation($token: String, $social_provider: String) {
    SocialLogin(token: $token, social_provider: $social_provider) {
      token
    }
  }
`

export const REGISTRATION = gql`
  mutation registrationMutation(
    $name: String
    $surname: String
    $sex: Int
    $email: String
    $password: String
    $date_of_birth: String
    $phone: String
    $state_id: Int
    $address: String
    $referal_code: String
    $token: String
    $enableEmail: Boolean
    $provider: String
    $agreement: Boolean
  ) {
    registration(
      name: $name
      surname: $surname
      sex: $sex
      email: $email
      password: $password
      date_of_birth: $date_of_birth
      phone: $phone
      state_id: $state_id
      address: $address
      referal_code: $referal_code
      token: $token
      enableEmail: $enableEmail
      social_provider: $provider
      agreement: $agreement
    ) {
      user {
        id
        slug
        email
      }
    }
  }
`

export const ACTIVATE_USER = gql`
  mutation activateUserMutation($activation_code: String) {
    ActivateUser(activation_code: $activation_code) {
      token
    }
  }
`

export const INVALIDATE = gql`
  mutation invalidate($token: String) {
    invalidate(token: $token) {
      id
      message
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation refresh($token: String) {
    refresh(token: $token) {
      token
    }
  }
`
