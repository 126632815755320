import { useState } from 'react'
import Search from './Search'
import Checkbox from './Checkbox'
import Switch from './Switch'
import Radio from './Radio'
import RangeInput from './Range'
import Upload from './Upload'
import CopyInput from './CopyInput'
import DateInput from './DateInput'
import Select from '../Select'
import * as S from './styled'

const Input = ({ type, name, register, ...rest }) => {
  const [passwordOpen, setPasswordOpen] = useState(false)

  const registerObject = register ? { ...register(name) } : {}

  const globalProps = {
    ...rest,
    register,
    name,
  }

  switch (type) {
    case 'password-with-icon': {
      return (
        <S.PasswordInput>
          <S.Input
            {...rest}
            {...registerObject}
            type={passwordOpen ? 'text' : 'password'}
          />
          {passwordOpen ? (
            <S.EyeIcon
              filename="eye"
              onClick={() => setPasswordOpen(!passwordOpen)}
            />
          ) : (
            <S.EyeIcon
              filename="eye-slash"
              onClick={() => setPasswordOpen(!passwordOpen)}
            />
          )}
        </S.PasswordInput>
      )
    }

    case 'search': {
      return <Search {...globalProps} />
    }

    case 'checkbox': {
      return <Checkbox {...globalProps} />
    }

    case 'radio': {
      return <Radio {...globalProps} />
    }

    case 'textarea': {
      return <S.Textarea {...rest} {...registerObject} />
    }

    case 'range': {
      return <RangeInput {...globalProps} />
    }

    case 'select': {
      return <Select {...globalProps} />
    }

    case 'switch': {
      return <Switch {...globalProps} />
    }

    case 'upload': {
      return <Upload {...globalProps} />
    }

    case 'copy': {
      return <CopyInput {...globalProps} />
    }

    case 'date': {
      return <DateInput {...globalProps} />
    }

    default:
      return <S.Input {...rest} {...registerObject} type={type} />
  }
}

export const ErrorInput = ({ error, ...rest }) => (
  <S.ErrorInput>
    <Input {...rest} />
    {error && (
      <span className="error-message">
        {error?.message ===
        'date_of_birth must be a `date` type, but the final value was: `Invalid Date`.'
          ? 'ველის შევსება სავალდებულოა'
          : error?.message}
      </span>
    )}
  </S.ErrorInput>
)

export default ErrorInput
