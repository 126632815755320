import useWindowWidth from 'hooks/useWindowWidth'
import * as S from './styled'

const Logo = (props) => {
  const width = useWindowWidth()


 return  (
  <S.Logo className="header-logo" to="/" >
    <img
      src={props.type === 'white' ? '/img/logo-white.png' : '/img/logo.png'}
      alt="Time Bank"
    />
    <S.BetaImg width={width}>
      <img src='/svg/BETA.svg' alt="Time Bank Beta"/>
    </S.BetaImg>
  </S.Logo>
)
  }

export default Logo
