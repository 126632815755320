import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Avatar from 'components/Avatar'
import Grid from 'components/Grid'
import Modal from 'components/Modal'
import Button, { ButtonGroup } from 'components/Forms/Button'
import Input from 'components/Forms/Input'
import Select from 'components/Forms/Select'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'
import { postSchema } from 'validation'
import * as S from '../styled'
import * as PS from './styled'
import useWindowWidth from 'hooks/useWindowWidth'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Icon from 'components/Icon'
import { ContentState, convertFromHTML } from 'draft-js'
import useMetaData from 'hooks/useMetaData'

const previewLink = (str) => {
  return str?.split(' ').find((st) => st.startsWith('https'))
}

const CreatePost = (props) => {
  const windowWidth = useWindowWidth()
  const [defaultValue, setDefaultValue] = useState()
  const [editorShown, setEditorShown] = useState(false)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const onEditorStateChange = (editorState) => setEditorState(editorState)

  const description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
  const containHtmlContent = props.post?.description?.startsWith('<')

  useEffect(() => {
    const blocksFromHTML = convertFromHTML(props.post?.description || '')
    const initialEditorState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )

    setEditorState(EditorState.createWithContent(initialEditorState))
  }, [props.post?.description])

  const { me } = useAuthorizedUser()
  const { t } = useTranslation()
  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(postSchema),
    defaultValues: defaultValue,
  })

  const [type, setType] = useState([])

  const { getMetaInfo, metaInfo } = useMetaData()

  const coverImage = watch('cover')
  const inpDescription = watch('description')

  useEffect(() => {
    const metaUrl = previewLink(inpDescription)

    if (metaUrl) {
      getMetaInfo(metaUrl, setValue)
    }
  }, [inpDescription])

  useEffect(() => {
    return () => {
      reset()
      setEditorShown(false)
      if (metaInfo) {
        getMetaInfo('', setValue)
      }
    }
  }, [props.onClose])

  useEffect(() => {
    let organization = me?.userOrganizations?.map((item) => {
      return {
        label: item?.title,
        value: item?.id,
      }
    })
    setType(organization)
  }, [me?.userOrganizations])

  const submitHandler = (args) => {
    if (props.editPost) {
      const payload = {
        ...args,
        cover: !watch('external_image') && args.cover,
        og_url: metaInfo?.url,
        external_image: args.external_image || metaInfo?.external_image,
        description:
          editorShown || containHtmlContent ? description : args.description,
        id: props.post?.id,
      }
      props.editPost(payload)
    } else {
      props.addPost({
        ...args,
        og_url: metaInfo?.url,
        external_image: args.external_image,
        description: editorState.getCurrentContent().hasText()
          ? description
          : args.description,
      })
    }
    window.scrollTo(0, 0)
    props.onClose()
  }

  const defaultInputValue = () => {
    const payload = {
      organization_id: props.post?.organization_id,
      title: props.post?.title,
      description: props.post?.description,
      cover: props.post?.cover,
      external_image: props.post?.external_image,
    }
    setDefaultValue(payload)
    reset(payload)
  }

  useEffect(() => {
    defaultInputValue()
  }, [props.show])

  return (
    <Modal
      title={props.post ? t('post.editPost') : t('post.addPost')}
      {...props}
    >
      <S.ModalForm onSubmit={handleSubmit(submitHandler)}>
        <Grid type="avatar">
          <Avatar type="simple" src={me?.avatar?.path} sex={me?.sex} />
          <h5>{t('post.applicant')}</h5>
          <p>
            {me?.name} {me?.surname}
          </p>
        </Grid>
        <Select
          options={type}
          register={register}
          name="organization_id"
          placeholder={t('post.selectOrganization')}
          defVal={props.post?.organization_id}
        />
        <Input
          type="text"
          name="title"
          error={errors?.title}
          register={register}
          placeholder={t('post.postTitle')}
        />
        <section>
          <Input
            type="hidden"
            hidden
            name="type"
            value="post"
            register={register}
          />
          <Input
            type="hidden"
            hidden
            name="og_url"
            value={metaInfo?.url}
            register={register}
          />
          <Input
            type="hidden"
            hidden
            name="external_image"
            value={metaInfo?.og_image}
            register={register}
          />
        </section>
        {!(editorShown || containHtmlContent) && (
          <div style={{ position: 'relative' }}>
            <Input
              type="textarea"
              name="description"
              register={register}
              error={errors?.description}
              placeholder={t('post.description')}
            />
            {(metaInfo?.og_image || props.data?.cover) && (
              <PS.MetaImage src={metaInfo?.og_image || props.data?.cover} />
            )}

            {metaInfo ? (
              <PS.EditorSwitcher
                onClick={() => {
                  getMetaInfo('', setValue)
                  setValue('title', '')
                  setValue('description', '')
                }}
              >
                <Icon filename="delete" />
              </PS.EditorSwitcher>
            ) : (
              <PS.EditorSwitcher onClick={() => setEditorShown(true)}>
                <Icon filename="edit" />
              </PS.EditorSwitcher>
            )}
          </div>
        )}
        {editorShown && (
          <PS.IconContainer>
            <Icon
              filename="arrow-right"
              onClick={() => setEditorShown(false)}
            />
          </PS.IconContainer>
        )}
        {(editorShown || containHtmlContent) && (
          <div>
            <Editor
              editorState={editorState}
              editorClassName="demo-editor"
              wrapperClassName="demo-wrapper"
              editorStyle={{
                minHeight: '100px',
                paddingLeft: '15px',
                borderTopRadius: '20px',
                borderBottomRadius: '20px',
                background: 'rgba(197,201,202,0.1)',
              }}
              onEditorStateChange={onEditorStateChange}
            />
            <textarea disabled hidden value={description} />
          </div>
        )}
        {windowWidth < 992 && (
          <ButtonGroup>
            <Input
              type="upload"
              register={register}
              name="cover"
              label={
                coverImage ? (
                  <PS.CoverSelectNotificationText>
                    {t('post.imageUploadedSuccessfully')}
                  </PS.CoverSelectNotificationText>
                ) : (
                  <>{windowWidth < 990 ? '' : t('post.imageMaxSize')}</>
                )
              }
            />
          </ButtonGroup>
        )}
        <S.FormActions end={metaInfo ? 'end' : ''}>
          {windowWidth > 992 && !(props.data?.external_image || metaInfo) && (
            <ButtonGroup>
              <Input
                name="cover"
                type="upload"
                accept="image/*"
                register={register}
                disabled={metaInfo}
                label={
                  coverImage ? (
                    <PS.CoverSelectNotificationText>
                      {t('post.imageUploadedSuccessfully')}
                    </PS.CoverSelectNotificationText>
                  ) : (
                    <>{windowWidth < 990 ? '' : t('post.imageMaxSize')}</>
                  )
                }
              />
            </ButtonGroup>
          )}
          <Button type="primary" submit>
            {props.post ? t('post.editPost') : t('post.createPost')}
          </Button>
        </S.FormActions>
      </S.ModalForm>
    </Modal>
  )
}

export default CreatePost
