import styled from 'styled-components'

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000000001;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background[160]};
`

export const ModalContent = styled.div`
  max-width: ${(props) => props.modalMaxWidth || '555px'};
  width: 100%;
  max-height: ${(props) => props.modalMaxHeight || '85%'};
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 30px 15px 30px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 100vh;
    max-height: 100%;
    gap: 10px;
    padding: 20px 15px 15px 30px;
  }
`

export const ModalHeader = styled.div`
  h3 {
    font-size: ${(props) => props.theme.fontSizes.lg};
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      font-size: ${(props) => props.theme.fontSizes.md};
    }
  }
  button.close {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: ${(props) => props.theme.colors.white};
    border: 5px solid ${(props) => props.theme.colors.background[160]};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ModalBody = styled.div`
  width: 100%;
  max-height: 80vh;
  height: ${(props) => props.height || 'auto'};
  position: relative;
  overflow-y: ${(props) => (props.noOverflow ? 'initial' : 'auto')};
  overflow-x: ${(props) => (props.noOverflow ? 'initial' : 'hidden')};

  padding-right: 18px;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffce07;
    border-radius: 10px;
    outline: 1px solid #ffce07;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 100%;
    max-height: ${(props) => props.responsiveMaxHeight || '80vh'};
    border: 1px solid transparent;

    form {
      max-height: 85%;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid transparent;
    }
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.md}) and (max-height: 500px) {
    form {
      max-height: 75%;
    }
  }
`

export const ModalActions = styled.div`
  display: ${(props) => props.displayFlex || ''};
  align-items: center;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
`
