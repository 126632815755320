import styled from 'styled-components'

export const PostNearby = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 20px 0;
    background: ${(props) => props.theme.colors.white};
    border-radius: 10px;
  }
`

export const PostNearbyJeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    button {
      color: ${(props) => props.theme.colors.text[300]};
      font-size: ${(props) => props.theme.fontSizes.xs};

      svg {
        path {
          stroke: ${(props) => props.theme.colors.text[300]};
        }
      }
    }
  }
`

export const PostNearbyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 0 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow-x: scroll;
    width: calc(100vw - 50px);
  }
`

export const DescriptionBox = styled.div`
  max-width: 300px;
`

export const Post = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
  }

  h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: ${(props) => props.theme.fontSizes.sm};
  }

  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 0px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 280px;
    gap: 5px;

    img {
      height: 180px;
      width: 100%;
    }
  }
`
