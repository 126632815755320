import { useState } from 'react'
import Button from '../Button'
import Icon from 'components/Icon'
import InputRange from 'react-input-range'
import * as S from './styled'

const RangeInput = ({
  hasManualIncrement,
  name,
  register,
  defaultRange,
  ...rest
}) => {
  const registerObject = register(name)
  const [value, setValue] = useState(defaultRange || 1)

  const setValueInForm = (value) => {
    registerObject?.onChange({
      target: {
        name,
        value,
      },
    })
  }

  const onIncrement = (e) => {
    e.preventDefault()
    if (value < rest.maxValue) {
      setValue(value + 1)
      setValueInForm(value + 1)
    }
  }

  const onDecrement = (e) => {
    e.preventDefault()

    if (value > rest.minValue) {
      setValue(value - 1)
      setValueInForm(value - 1)
    }
  }

  return (
    <S.RangeLabel>
      <S.RangeInput>
        <InputRange
          {...rest}
          name={name}
          value={value}
          onChange={(value) => {
            setValue(value)
            setValueInForm(value)
          }}
        />
      </S.RangeInput>

      {hasManualIncrement && (
        <S.ManualIncrement>
          <Button type="clean" onClick={onDecrement}>
            <Icon filename="minus" />
          </Button>
          <S.VoluntInfo>
            <Icon filename="buy-crypto" />
            <S.VoluntInput type="number" value={value} />
          </S.VoluntInfo>
          <Button type="clean" onClick={onIncrement}>
            <Icon filename="add" />
          </Button>
        </S.ManualIncrement>
      )}
    </S.RangeLabel>
  )
}

export default RangeInput
