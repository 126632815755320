import styled from 'styled-components'
import { SelectContainer } from 'components/Forms/Select/styled'
import Modal from 'components/Modal'
import Input from 'components/Forms/Input'
import Select from 'components/Forms/Select'

export const LanguageModal = styled(Modal)``

export const LangSelect = styled(Select)``

export const Header = styled.header`
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000000000;
  background-color: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 60px;
  }
`

export const ShowAsOtherUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #414042;
  padding: 20px 0px;
  text-align: center;
  color: #ffffff;
`

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  cursor: pointer;
  border: none;
  color: #ffce07;
`

export const CloseButtonText = styled.span`
  color: #ffce07;
  margin-left: 5px;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0px;
  pa section {
    position: relative;
  }

  .search-input {
    max-width: 620px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 8px 0px;
    pa .search-input {
      width: 170px;
    }
  }
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    gap: 10px;
  }
`

export const LanguageSwitcher = styled.div`
  .custom-select-container {
    padding: 3px 20px 3px 20px;
    border: 1px solid #f3f3f3;
    background-color: transparent;
  }
`

export const AuthorizedContent = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  .btm-menu-grid {
    margin-top: 15px;

    > div {
      display: flex;
      flex-direction: column;
    }
  }
`

export const AddContainer = styled.div`
  position: relative;

  .grid {
    cursor: pointer;
  }
  button {
    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary[700]};
      }
    }
  }
`

export const DeleteSearchValueButton = styled.button`
  position: absolute;
  top: 25%;
  right: 5%;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    right: 10%;
    top: 20%;
  }
`

export const SearchBox = styled.div`
  position: relative;
`

export const SearchInput = styled(Input)`
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 450px;
  }
`

export const SelectMenu = styled(SelectContainer)`
  max-width: 300px;
  border: none;

  > div {
    min-width: 140px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

export const DesktopSize = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 120px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  svg {
    cursor: pointer;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

export const NotificationContainer = styled.div`
  position: relative;
`

export const User = styled.div`
  position: relative;
  z-index: 10000;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    position: unset;
  }
`
export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .simple-avatar,
  .empty-avatar {
    width: 50px;
    height: 50px;
    transition: border 0.3s ease;

    border: 3px solid
      ${(props) =>
        props.open ? props.theme.colors.primary[700] : 'transparent'};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
      width: 30px;
      height: 30px;
    }
  }

  svg#arrow-down {
    transition: transform 0.3s ease;
    transform: rotate(${(props) => (props.open ? '180deg' : '0deg')});
  }
`

export const MoreInfoHeader = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.background[100]};
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    button {
      /* height: 40px; */
      border: 1px solid ${(props) => props.theme.colors.background['150']};
    }
    button > a {
      font-size: ${(props) => props.theme.fontSizes.xs};
      color: ${(props) => props.theme.colors.text['700']};
    }
  }
`

export const MoreInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  button {
    color: ${(props) => props.theme.colors.error};
  }
`

export const AvatarInfo = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'avatar title'
    'avatar description';

  .simple-avatar,
  .empty-avatar {
    width: 50px;
    height: 50px;
    grid-area: avatar;
  }

  h4 {
    grid-area: title;
    padding-top: 15px;
  }

  p {
    grid-area: description;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`

export const UserActionList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 3px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 10px 5px 10px 0;

    .gold_icon {
      path {
        stroke: ${(props) => props.theme.colors.primary[700]};
      }
    }
    svg {
      path {
        stroke: ${(props) => props.theme.colors.text[700]};
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    li {
      span {
        font-size: ${(props) => props.theme.fontSizes.sm};
      }
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #f7f7f7;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    opacity: 0;
  }
`

export const LogoutText = styled.span`
  color: ${(props) => props.theme.colors.error};
  width: 65px;
`

export const MediaVision = styled.div`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: block;
  }
`

export const MenuRow = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    span {
      padding-left: 10px;
    }
    #arrow-down {
      rotate: ${(props) => (props.activeInfo ? '180deg' : 'none')};
    }
  }
`

export const MenuRowBox = styled.div`
  display: flex;
  align-items: center;
`

export const InfoMenu = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: ${(props) => (props.activeInfo ? 'block' : 'none')};
    padding: 10px 0 10px 25px;
    li {
      padding: 5px;
    }
  }
`

export const AvailableTime = styled.div`
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      font-size: ${(props) => props.theme.fontSizes.xs};
    }

    p {
      color: ${(props) => props.theme.colors.primary['700']};
      font-weight: 600;
    }
  }
`

export const NewNotificationsNumber = styled.div`
  position: relative;

  p {
    color: white;
    padding: 2px;
    font-size: 13px;
    min-width: 24px;
    height: 24px;
    font-weight: bold;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #e64d3e;
    border-radius: 100%;
    border: 2px solid ${(props) => props.theme.colors.white};
    position: absolute;
    bottom: 0px;
    right: -8px;
  }
`

export const OrganizationsListBox = styled.div`
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffce07;
    border-radius: 10px;
    outline: 1px solid #ffce07;
  }
`

export const OrganizationBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 3px;
  cursor: pointer;
  border-radius: 10px;

  :hover {
    background-color: rgba(232, 232, 232, 0.5);
  }
`

export const OrganizationAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`

export const OrganizationInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    margin-top: 4px;
  }
`

export const MyOrganizationsHeader = styled.p`
  padding-bottom: 10px;
`

export const OrganizationTitle = styled.h5`
  color: #8d9297;
`

export const CalendarIcon = styled.div`
  width: 28px;
  height: 28px;
  cursor: pointer;

  img {
    opacity: 0.8;
    width: 100%;
    height: 100%;
  }
`
