import { gql } from '@apollo/client'

export const GET_LANDING = gql`
  query getLanding($lang: String) {
    HomeContent(lang: $lang) {
      first_section_h2
      first_section_span
      first_section_text
      first_section_url
      first_section_url_title
      carousel {
        h2
        text
        url
        url_title
        span
        volunteers {
          state {
            name
          }
          avatar {
            path
            id
          }
          sex
          id
          name
          surname
          followers_count
          completed_requests
          volunt
        }
      }
      first_section_image
      first_section_video
      first_section_youtube_url
      first_section_partners {
        url
        logo {
          path
        }
      }
      second_section_h2
      second_section_span
      second_section_text
      second_section_image
      second_section_show_unique_users
      second_section_unique_users_title
      second_section_unique_users_svg
      second_section_unique_users
      second_section_show_requests
      second_section_requests_title
      second_section_requests_svg
      second_section_requests
      second_section_show_completed_requests
      second_section_completed_requests_title
      second_section_completed_requests_svg
      second_section_completed_requests
      second_section_show_volunts
      second_section_volunts_title
      second_section_volunts_svg
      second_section_volunts
      third_section_h2
      third_section_span
      third_section_texts {
        text
      }
      third_section_url
      third_section_url_title
      third_section_image
      carousel_section_texts {
        h2
        span
        text
        url
        url_title
      }
      carousel_section_volunteers {
        name
        surname
        about
        followers_count
        completed_requests
        volunt
        state {
          name
        }
        avatar {
          id
          path
        }
      }
      categories_section_h2
      categories_section_span
      categories_section_image
      categories_section_categories {
        id
        name
        users_count
        children {
          id
          name
          users_count
        }
      }
      fourth_section_h2
      fourth_section_span
      fourth_section_text
      fourth_section_volunteers {
        id
        slug
        name
        surname
        sex
        avatar {
          id
          path
        }
        state {
          name
        }
        about
      }
      fifth_section_h2
      fifth_section_span
      fifth_section_text
      fifth_section_urls {
        url
        url_title
      }
      fifth_section_images {
        image
      }
    }
  }
`

export const GET_FOOTER = gql`
  query getInfo($lang: String) {
    HomeContent(lang: $lang) {
      footer_logo
      footer_text
      footer_navigation {
        title
        items {
          url
          title
        }
      }
      footer_copyright_text
      footer_copyright_navigation {
        url
        title
      }
    }
  }
`
