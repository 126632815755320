import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Heading = styled.h3`
  font-size: 12px;
  color: #8d9297;
  opacity: 0.6;
  margin-bottom: 5px;
`

export const SearchResultsMenu = styled.div`
  display: flex;
  border: 1px solid #c5c9ca4c;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  z-index: 100;
  background: white;
  width: 450px;
  border-radius: 10px;
  padding: 20px;
  top: 80px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    top: 55px;
    left: 0px;
  }
`

export const Loading = styled.p`
  font-size: 12px;
  color: #414042;
  text-align: center;
`

export const FoundContent = styled.div``

export const UserContent = styled(Link)`
  display: flex;
  gap: 5px;

  div {
    margin-top: 5px;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }
`

export const UserInfo = styled.div`
  + p {
    font-size: 12px;
    margin-top: 3px;
  }
`

export const SearchContent = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 20px;
    height: 20px;
  }

  div {
    background: #f7f7f7;
    border-radius: 50%;
    padding: 6px 7px;
  }
`

export const ShowAll = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 20px;

  svg {
    width: 15px;
  }
`

export const TextContent = styled.p`
  font-size: 12px;
  color: #414042;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
`
