import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import useApolloClient from 'hooks/useApolloClient'
import Routes from 'routes'
import { GoogleOAuthProvider } from '@react-oauth/google'

const App = () => {
  const client = useApolloClient()
  const googleClientId =
    '968401365720-ofkmpolmm5hris7ddaakuf0dacb1i99f.apps.googleusercontent.com'

  return (
    <Router>
      <ApolloProvider client={client}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <div className="app">
            <Routes />
          </div>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </Router>
  )
}

export default App
