import { useState } from 'react'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'
import { useTranslation } from 'react-i18next'
import { languages } from 'constants/global'
import { Link } from 'react-router-dom'
import useDownloadFile from 'hooks/useDownloadFile'
import useSwitchLocale from 'hooks/useSwitchLocale'
import Icon from 'components/Icon'
import i18n from '../../i18n'

import * as S from './styled'

const NotificationsRightBar = ({ myData }) => {
  const [userInformationShow, setUserInformationShow] = useState(false)
  const { me } = useAuthorizedUser()
  const [downloadFile] = useDownloadFile()
  const [switchLocale] = useSwitchLocale()

  const { t } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng?.value)
    switchLocale(lng?.value)
  }

  return (
    <S.NotificationsRightBarSettings>
      <S.Setting type="clean" onClick={() => setUserInformationShow(true)}>
        <span>{t('common.parameters')}</span>
        <Icon filename="setting-2" />
      </S.Setting>

      <S.LanguageModal
        noOverflow
        title={t('common.changeLanguage')}
        show={userInformationShow}
        onClose={() => setUserInformationShow(false)}
      >
        <S.LangSelect
          options={languages}
          icon="globe"
          placeholder=""
          defVal={i18n.language}
          onChange={(e) => changeLanguage(e)}
        />
      </S.LanguageModal>

      <Link to={`/user/${me?.slug || myData?.slug}`}>
        <S.Setting type="clean">
          <span>{t('common.myProfile')}</span>
          <Icon filename="profile" />
        </S.Setting>
      </Link>

      <S.Setting
        onClick={() => {
          downloadFile(me?.resume?.path || myData?.resume?.path, 'resume')
        }}
        type="clean"
      >
        <span>{t('user.downloadYourResume')}</span>
        <Icon filename="document-download" />
      </S.Setting>
    </S.NotificationsRightBarSettings>
  )
}

export default NotificationsRightBar
