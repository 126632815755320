import { gql } from '@apollo/client'

export const FOLLOW_USER = gql`
  mutation FollowUser($user_id: Int) {
    FollowUser(user_id: $user_id) {
      response
    }
  }
`

export const UNFOLLOW_USER = gql`
  mutation UnFollowUser($user_id: Int) {
    UnFollowUser(user_id: $user_id) {
      response
    }
  }
`
