import { useMutation } from '@apollo/client'
import {
  UPDATE_ORGANIZATION_SOCIAL,
  UPDATE_USER_SOCIAL,
} from 'services/mutations/socialMedia'

const useSocialMedia = () => {
  const [editUserSocialMediaMutation, editUserSocialMediaResult] = useMutation(
    UPDATE_USER_SOCIAL,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser'],
    }
  )

  const [editOrganizationSocialMutation, editOrganizationSocialResult] =
    useMutation(UPDATE_ORGANIZATION_SOCIAL, {
      errorPolicy: 'all',
      refetchQueries: ['getOrganization'],
    })

  const editUserSocialMedia = (data) => {
    editUserSocialMediaMutation({
      variables: {
        ...data,
      },
    })
  }

  const editOrganizationSocial = (data) => {
    editOrganizationSocialMutation({
      variables: {
        organization_id: data.id,
        ...data,
      },
    })
  }

  return {
    editUserSocialMedia,
    editUserSocialMediaResult,
    editOrganizationSocial,
    editOrganizationSocialResult,
  }
}

export default useSocialMedia
