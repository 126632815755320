import styled from 'styled-components'

export const Grid = styled.div`
  display: grid;

  ${(props) => (props.gap ? `gap: ${props.gap};` : '')}
  ${(props) =>
    props.columnGrid
      ? `grid-template-columns: repeat(${props.columnGrid}, 1fr);`
      : ''}
    ${(props) =>
    props.rowGrid ? `grid-template-rows: repeat(${props.rowGrid}, 1fr);` : ''}
`
export const AvatarGeneral = styled(Grid)`
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  grid-template-areas:
    'icon title'
    'icon description';

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    grid-area: title;
  }

  svg {
    grid-area: icon;
  }

  p,
  span {
    grid-area: description;
  }
`

export const AvatarGrid = styled(AvatarGeneral)`
  .avatar {
    grid-area: icon;
    width: 50px;
    height: 50px;
  }
`

export const AvatarOnline = styled(AvatarGeneral)`
  .avatar-online {
    grid-area: icon;

    .avatar {
      width: 50px;
      height: 50px;
    }
  }
`
