import { gql } from '@apollo/client'

export const GET_NEARBY_REQUESTS = gql`
  query NearByRequests {
    NearByRequests(limit: 5, page: 1) {
      data {
        id
        title
        cover
        approved
        slug
        description
        workers
        volunt
        comments {
          id
        }
      }
    }
  }
`
