import { useMutation } from '@apollo/client'
import { FOLLOW_USER } from 'services/mutations/followUser'
import { GET_PEOPLE } from 'services/queries/getPeopleYouMayKnow'

const useFollow = () => {
  const [addFollowMutation, followUserResult] = useMutation(FOLLOW_USER, {
    errorPolicy: 'all',
    refetchQueries: [{ query: GET_PEOPLE }, 'getUser', 'UsersSearch', 'followers', 'followings'],
  })

  const followUser = (data) => {
    addFollowMutation({
      variables: {
        user_id: data,
      },
    })
  }

  return [followUser, followUserResult]
}

export default useFollow
