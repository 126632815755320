import { useQuery } from '@apollo/client'
import { GET_STATES } from 'services/queries/getStates'

const useCities = () => {
  const { data } = useQuery(GET_STATES)

  const cities = data?.states?.map((state) => ({
    label: state?.name,
    value: state?.id,
  }))

  return [cities]
}

export default useCities
