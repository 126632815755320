import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import NotificationSettings from 'modules/Modals/NotificationSettings'
import Button from 'components/Forms/Button'
import Avatar from 'components/Avatar'
import Grid from 'components/Grid'
import Icon from 'components/Icon'
import useConversation from '../../hooks/useConversation'
import { calculateDateDiffString } from '../../utils/dates'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useReadMessage from 'hooks/useReadMessage'
import * as S from './styled'
import { t } from 'i18next'

export const PrivateMessagesList = ({ conversations }) => {
  const history = useHistory()

  const [readMessageHandler] = useReadMessage()

  return (
    <S.PrivateMessagesList>
      {conversations.length > 0 ? (
        conversations.map((conversation) => {
          return (
            <S.PrivateMessageItem
              unreadMessage={conversation?.last_message?.unread}
              borderRadius="5px"
              key={conversation.id}
              onClick={() => {
                if (conversation?.last_message?.unread) {
                  readMessageHandler(conversation?.id)
                }
                history.push(`/messages/${conversation.id}`)
              }}
            >
              <Grid type="avatar">
                <Avatar
                  src={conversation?.user?.avatar?.path}
                  sex={conversation?.user?.sex}
                  type="simple"
                />
                <h5>
                  {conversation?.user?.name} {conversation?.user?.surname}
                </h5>
                <S.MessageText>
                  {conversation?.last_message?.message || 'N/A'}
                </S.MessageText>
              </Grid>
              <p className="date">
                {calculateDateDiffString(
                  conversation?.last_message?.created_at
                )}
              </p>
            </S.PrivateMessageItem>
          )
        })
      ) : (
        <>{t('messages.youDontHaveMessages')}</>
      )}
    </S.PrivateMessagesList>
  )
}

const PrivateMessages = () => {
  const [settingShow, setSettingShow] = useState(false)
  const { getConversationUser, conversationData } = useConversation()

  const { t } = useTranslation()
  useEffect(() => {
    getConversationUser({ limit: 3, page: 1 })
  }, [])

  return (
    <S.PrivateMessages>
      <S.PrivateMessagesHeader>
        <h4>{t('chat.privateMessages')}</h4>
        <Button type="clean" onClick={() => setSettingShow(true)}>
          <Icon filename="setting" />
        </Button>
      </S.PrivateMessagesHeader>
      <PrivateMessagesList
        conversations={conversationData?.conversations?.data || []}
      />
      <NotificationSettings
        show={settingShow}
        onClose={() => setSettingShow(false)}
      />
      <Button type="clean" className="with-icon">
        <Link to="/messages">
          <span>{t('feed.seeAll')}</span>
          <Icon filename="angle-down" />
        </Link>
      </Button>
    </S.PrivateMessages>
  )
}

export default PrivateMessages
