import { useMutation } from '@apollo/client'
import { UPDATE_RESUME } from 'services/mutations/updateResume'

const useUpdateResume = () => {
  const [UpdateResume, { data, loading, error }] = useMutation(UPDATE_RESUME, {
    errorPolicy: 'all',
    refetchQueries: ['getMe'],
  })

  return [UpdateResume, data]
}

export default useUpdateResume
