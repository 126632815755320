import { gql } from '@apollo/client'
import { ORGANIZATION_FRAGMENT } from './organization'
import { REWARD_FRAGMENT } from './reward'
import { VOLUNTEER_WORK_EXPERIENCE_FRAGMENT } from './volunteerWorkExperience'
import { WORK_EXPERIENCE_FRAGMENT } from './workExperience'

export const USER_FRAGMENT = (type) => gql`
  ${ORGANIZATION_FRAGMENT}
  ${REWARD_FRAGMENT}
  ${WORK_EXPERIENCE_FRAGMENT}
  ${VOLUNTEER_WORK_EXPERIENCE_FRAGMENT}
  fragment UserFragment on ${type === 'me' ? 'Me' : 'User'} {
    id
    slug
    name
    surname
    sex
    email
    user_phone_privacy
    user_email_privacy
    followers_count
    followings_count
    followers_privacy
    followings_privacy
    cover {
      id
      path
    }
    avatar {
      id
      path
    }
    date_of_birth
    phone
    address
    about_completed
    about
    average_rating
    rating_count
    volunt
    volunt_time
    is_me
    is_following_you
    followed_by_you
    following_each_other
    referal_code
    education_completed
    work_experience_completed
    created_at
    updated_at
    about_privacy
    education_privacy
    work_experience_privacy
    followers_privacy
    followings_privacy
    rewards_privacy
    volunteer_experience_privacy
    skills_privacy
    linkedin_url
    facebook_url
    instagram_url
    dribble_url
    pinterest_url
    twitter_url
    youtube_url
    behance_url
    
    state {
      id
      name
      slug
    }

    grouped_skills {
      children {
          id
          name
      }
      name
    }

    userOrganizations {
      ...OrganizationFragment
    }
    rewards {
      ...Reward
    }
    education {
      id
      educational_institution
      course
      degree
      start_month
      start_year
      end_month
      end_year
      still_studying
    }
    work_experience {
      ...WorkExperience
    }

    volunteer_work_experience {
      ...VolunteerWorkExperience
    }
    is_online
  }
`
