import * as S from './styled'
import Button from 'components/Forms/Button'
import { useTranslation } from 'react-i18next'

const TermsAndCondition = (props) => {
  const { t } = useTranslation()

  return (
    <S.TermsAndCondition
      className="terms-and-conditions"
      color={props.color}
      listFontSize={props.listFontSize}
    >
      <h2>{t('login.termsAndConditions')}</h2>
      <ul>
        <li>{t('login.randomText')}</li>
        <li>{t('login.description1')}</li>
        <li>{t('login.description2')}</li>
        <li>{t('login.description3')}</li>
      </ul>
      <S.MediaVision>
        <Button type="primary" onClick={() => props.setTerms(false)}>
          <span>{t('login.next')}</span>
        </Button>
      </S.MediaVision>
    </S.TermsAndCondition>
  )
}

export default TermsAndCondition
