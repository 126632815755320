import * as S from './styled'
import _ from 'lodash'

const buttons = {
  primary: S.PrimaryButton,
  clean: S.CleanButton,
  default: S.Button,
}

export const ButtonGroup = (props) => (
  <S.ButtonGroup {...props} className="button-group" />
)

const Button = (props) => {
  const ButtonComponent = buttons[props.type] || buttons['default']

  return (
    <ButtonComponent
      {..._.omit(props, ['type'])}
      type={props.submit ? 'submit' : 'button'}
    />
  )
}

export default Button
