import * as yup from 'yup'

const schema = (arg) => {
  return yup.object({
    title: yup.string().required('ველის შევსება აუცილებელია'),
    description: yup.string().required('ველის შევსება აუცილებელია'),
    // start_date: arg
    //   ? null
    //   : yup.date().required('თარიღის მითითება აუცილებელია'),
    end_date: arg ? null : yup.date().required('თარიღის მითითება აუცილებელია'),
    workers: yup
      .string()
      .required('მიუთითეთ დამხმარეების რაოდენობა (რიცხვი აუცილებლად)')
      .min(1, 'მინიმუმი ოდენობა შეადგენს 1-ს'),
    // volunt: arg? null: yup.string().required('მიუთითეთ თაიმქოინების ოდენობა'),
  })
}

export default schema
