import { useMutation } from '@apollo/client'
import { START_REQUEST } from 'services/mutations/startRequets'

const useStartRequest = () => {
  const [startRequestMutation, { data: requestData, loading, error }] =
    useMutation(START_REQUEST, {
      errorPolicy: 'all',
    })

  const startRequest = (requestId) => {
    startRequestMutation({
      variables: {
        request_id: requestId,
      },
    })
  }
  return {
    startRequest,
    startRequestResponse: requestData,
    startRequestLoading: loading,
    error,
  }
}

export default useStartRequest
