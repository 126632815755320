import styled from 'styled-components'

export const SkillsMenu = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;

  > h1 {
    font-size: 12px;
    color: #8d9297;
    margin-top: 15px;
  }

  span {
    color: #000;
    font-size: 12px;
  }
`

export const ModalAction = styled.div`
  display: flex;
  justify-content: flex-end;
`
