import { gql } from '@apollo/client'

export const POST_FRAGMENT = gql`
  fragment PostFragment on Post {
    id
    approved
    isPostBookmarked
    created_at
    title
    slug
    description
    hide
    archived
    cover
    latitude
    longitude
    likes_count
    volunt
    shares_count
    likedUser
    share_url
    isPostSharer
    is_shared
    is_started
    is_started
    can_help
    og_url
    comments_count
    organization_id
    volunteer_requests {
      id
      name
    }
    type
    skills {
      id
      name
      slug
      own
    }
    comments {
      id
      body
      likedUser
      currentCommentLikes
      created_at
      parent_id
      replies {
        id
        body
        likedUser
        currentCommentLikes
        created_at
        parent_id
        user {
          id
          slug
          name
          surname
          avatar {
            id
            path
          }
          cover {
            id
            path
          }
          sex
        }
      }
      user {
        id
        slug
        name
        surname
        avatar {
          id
          path
        }
        cover {
          id
          path
        }
        sex
      }
    }
  }
`
