import { useMutation } from '@apollo/client'
import { FINISH_REQUEST } from './finishRequest'

const useFinishRequest = () => {
  const [finishRequestMutation, { data: requestData, loading, error }] =
    useMutation(FINISH_REQUEST, {
      errorPolicy: 'all',
    })

  const finishRequest = (requestId) => {
    finishRequestMutation({
      variables: {
        request_id: requestId,
      },
    })
  }
  return { finishRequest, requestData, finishRequestLoading: loading, error }
}

export default useFinishRequest
