import styled from 'styled-components'
import Icon from '../../components/Icon'

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;

  .textarea {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-left: 10px;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.primary[700]};
      display: block;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: 100vh;
    margin-top: 15px;
  }

  @media screen and (min-width: 360px) {
    overflow: ${(props) => (props.scrollable ? 'auto' : 'hidden')};
  }

  section {
    display: none;
  }
`

export const FormActions = styled.div`
  display: flex;
  justify-content: ${(props) => (props.end ? 'flex-end' : 'space-between')};
  align-items: ${(props) => (props.align ? 'flex-end' : 'center')};

  button.submit {
    margin-left: auto;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    margin-bottom: 10px;

    button[type='submit'] {
      position: absolute;
      margin: 0;
      bottom: 0;
      width: calc(100% - 60px);
      margin-top: 10px;
      font-size: ${(props) => props.theme.fontSizes.xs};
    }
    button {
      margin-top: 10px;
    }
  }
`

export const InputGroup = styled.div`
  display: flex;
  gap: 10px;
`

export const TimeCoin = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: space-between;
  width: 100%;

  .simple-avatar {
    width: 50px;
    height: 50px;
  }

  .username {
    color: #414042;
    font-size: 14px;
    font-weight: bold;
  }

  .caption {
    color: #8d9297;
    font-size: 14px;
  }

  div {
    input[type='number'] {
      text-align: center;
    }
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  p {
    font-weight: 600;
  }

  .icon > svg {
    display: none;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 50%;

    p {
      font-size: ${(props) => props.theme.fontSizes.sm};
      color: ${(props) => props.theme.colors.text['700']};
      margin-bottom: 12px;
    }
    label {
      display: flex;
      flex-direction: column;
    }
    .sc-furwcr {
      text-align: center;
    }
    .icon > svg {
      display: block;
      position: relative;
      top: -65px;
      left: 70px;
    }
  }
`

export const StarIcon = styled(Icon)`
  height: 20px;
  width: 20px;

  path {
    fill: ${(props) => props.theme.colors.primary[700]};
  }
`

export const EmptyStarIcon = styled(Icon)`
  height: 20px;
  width: 20px;

  path {
    stroke: ${(props) => props.theme.colors.primary[700]};
  }
`

export const Message = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.error};
`

export const ButtonActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`

export const InputAndSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  input {
    width: 300px;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    input {
      width: 220px;
    }
    .custom-select__value-container {
      width: 20px;
      min-width: 20px;
    }
  }
`
