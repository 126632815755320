import { useState, useEffect, useRef } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUser } from 'contexts/UserProvider'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Modal from 'components/Modal'
import Input from 'components/Forms/Input'
import Button from 'components/Forms/Button'

import useCities from 'hooks/useCities'
import usePrivacy from 'hooks/usePrivacy'
import moment from 'moment'

import * as _ from 'lodash'
import * as S from '../styled'
import { userInformationSchema } from 'validation'

const initialValues = ['name', 'surname', 'email', 'phone', 'about', 'address']
const privacies = [
  {
    label: 'Public',
    value: 'public',
    icon: 'globe',
  },
  {
    label: 'Followers',
    value: 'followers',
    icon: 'followers',
  },
]

const dataFilter = (data) =>
  _.pickBy(data, function (value, key) {
    return initialValues.includes(key)
  })

const UserInformation = (props) => {
  const inputRef = useRef(null)

  const { user, editUser } = useUser()
  const [updatePrivacy] = usePrivacy()
  const [cities] = useCities()
  const [changePassword, setChangePassword] = useState(false)
  const [defaultValue, setDefaultValue] = useState({})
  const [scrollTop, setScrollTop] = useState()

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...dataFilter(props.data),
      state_id: props.data?.state?.id,
    },
    resolver: yupResolver(userInformationSchema),
  })

  const onSubmit = (e) => {
    const submitedData = {
      about: e ? e.about : props.data?.about,
      address: e ? e.address : props.data?.address,
      date_of_birth: moment(
        e.date_of_birth ? e.date_of_birth : defaultValue?.date_of_birth
      )?._d,
      email: e ? e.email : props.data?.email,
      phone: e ? e.phone.slice(0, 15) : props.data?.phone.slice(0, 15),
      sex: e.sex ? e.sex : props.data?.sex,
      state_id: e.state_id ? e.state_id : props.data?.state?.id - 1,
      surname: e ? e.surname : props.data.surname,
      name: e ? e.name : props.data.name,
      password_confirmation: e?.password_confirmation,
      current_password: e?.current_password,
      password: e?.password,
    }

    if (props.data) {
      editUser(submitedData)
      reset()
    }

    if (typeof props.onClose === 'function') {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.data) {
      reset({
        ...dataFilter(props.data),
        state_id: props.data?.state?.id,
      })
    }
  }, [props.data])

  useEffect(() => {
    const defaultVal = {
      state_id: props.data?.state?.id - 1,
      sex: props.data?.date_of_birth,
      date_of_birth: moment(props.data?.date_of_birth),
    }

    setDefaultValue(defaultVal)
  }, [])

  const genders = [
    {},
    {
      label: 'მამრობითი',
      value: 1,
    },
    {
      label: 'მდედრობითი',
      value: 2,
    },
  ]

  const changePasswordFunc = (e) => {
    e.preventDefault()
    setChangePassword(!changePassword)
  }

  const onPrivacyUpdate = (item, type) => {
    updatePrivacy({
      privacy: item?.value,
      type: type,
    })
  }

  useEffect(() => {
    inputRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }, [changePassword])

  const modalActions = (
    <S.FormActions align>
      <Button type="primary" submit onClick={handleSubmit(onSubmit)}>
        {props.data ? 'რედაქტირება' : 'დამატება'}
      </Button>
    </S.FormActions>
  )

  return (
    <Modal
      displayFlex="flex"
      justifyContent="flex-end"
      modalActions={modalActions}
      title={t('user.editInformation')}
      {...props}
      scrollTop={scrollTop}
    >
      <S.ModalForm autoComplete="off">
        <S.InputGroup>
          <Input
            type="text"
            name="name"
            register={register}
            placeholder="სახელი"
            error={errors.name}
          />
          <Input
            register={register}
            name="surname"
            placeholder="გვარი"
            error={errors.surname}
            type="text"
          />
        </S.InputGroup>
        <S.InputAndSelect>
          <Input
            register={register}
            name="email"
            placeholder="ელ. ფოსტა"
            error={errors.email}
            type="email"
          />
          <Input
            defVal={user?.user_email_privacy}
            type="select"
            options={privacies}
            onChange={(e) => onPrivacyUpdate(e, 'user_email')}
          />
        </S.InputAndSelect>
        <Input
          register={register}
          name="date_of_birth"
          type="date"
          placeholder="დაბადების თარიღი"
          defVal={props.data?.date_of_birth}
        />
        <S.InputAndSelect>
          <Input
            register={register}
            name="phone"
            type="text"
            error={errors.phone}
            placeholder="მობილურის ნომერი"
          />
          <Input
            defVal={user?.user_phone_privacy}
            type="select"
            options={privacies}
            onChange={(e) => onPrivacyUpdate(e, 'user_phone')}
          />
        </S.InputAndSelect>
        <Input
          register={register}
          name="state_id"
          type="select"
          options={cities}
          placeholder="ქალაქი"
          defVal={props.data?.state?.id}
        />
        <Input
          register={register}
          name="sex"
          type="select"
          options={genders}
          placeholder="სქესი"
          defVal={props.data?.sex}
        />
        <Input
          register={register}
          name="address"
          placeholder="მისამართი"
          type="text"
          error={errors.address}
        />
        <span className="textarea">{t('user.textareaSpan')}</span>
        <Input
          register={register}
          name="about"
          type="textarea"
          placeholder="აღწერა"
        />

        <Button type="clean" onClick={(e) => changePasswordFunc(e)}>
          პაროლის შეცვლა
        </Button>

        {changePassword && (
          <>
            <Input
              register={register}
              name="current_password"
              type="password-with-icon"
              placeholder="ძველი პაროლი"
            />
            <Input
              register={register}
              name="password"
              type="password-with-icon"
              placeholder="ახალი პაროლი"
            />
            <Input
              register={register}
              name="password_confirmation"
              type="password-with-icon"
              placeholder="გაიმეორეთ პაროლი"
            />
            <span ref={inputRef} />
          </>
        )}
      </S.ModalForm>
    </Modal>
  )
}

export default UserInformation
