import Button from 'components/Forms/Button'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .avatar {
    height: 40px;
    width: 40px;
  }
`

export const Box = styled.div`
  padding: 10px 0px;
`

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NameBox = styled.div`
  margin-left: 10px;
`

export const VoluntBox = styled.p`
  margin-left: 5px;
`

export const AddressBox = styled.p`
  padding-top: 5px;
`

export const CloseButton = styled(Button)`
  background-color: #ffffff00;
  border: 1px solid #dfe0df;
  cursor: pointer;
`
