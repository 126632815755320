import { useLazyQuery, useMutation } from '@apollo/client'
import {
  ADD_ORGANIZATION,
  APPROVE_USER_TO_ORGANIZATION,
  CANCEL_REQUEST_TO_ORGANIZATION,
  DECLINE_USER_TO_ORGANIZATION,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SKILL,
  JOIN_ORGANIZATION,
  KICK_USER_FROM_ORGANIZATION,
  LEAVE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SKILLS,
} from 'services/mutations/organization'
import { GET_ORGANIZATION_SKILLS } from 'services/queries/getOrganization'

const useOrganization = () => {
  const [getOrganizationSkillsQuery, { data: organizationSkills }] =
    useLazyQuery(GET_ORGANIZATION_SKILLS, {
      errorPolicy: 'all',
    })

  const [addOrganizationMutation, addOrganizationResult] = useMutation(
    ADD_ORGANIZATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getUser', 'getMe'],
    }
  )

  const [updateSkillsMutation, updateSkillsResult] = useMutation(
    UPDATE_ORGANIZATION_SKILLS,
    {
      errorPolicy: 'all',
      refetchQueries: ['getOrganization'],
    }
  )

  const [updateOrganizationMutation, updateOrganizationResult] = useMutation(
    UPDATE_ORGANIZATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getOrganization'],
    }
  )

  const [joinOrganizationMutation] = useMutation(JOIN_ORGANIZATION, {
    errorPolicy: 'all',
    refetchQueries: ['getOrganization', 'OrganizationsSearch', 'organizations'],
  })

  const [deleteOrganizationMutation] = useMutation(DELETE_ORGANIZATION, {
    errorPolicy: 'all',
    refetchQueries: ['getUser', 'getMe'],
  })

  const [deleteOrganizationSkillMutation] = useMutation(
    DELETE_ORGANIZATION_SKILL,
    {
      errorPolicy: 'all',
      refetchQueries: ['getOrganization'],
    }
  )

  const [approveUserMutation, approveUserResult] = useMutation(
    APPROVE_USER_TO_ORGANIZATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getOrganization'],
    }
  )

  const [declineUserMutation, declineUserResult] = useMutation(
    DECLINE_USER_TO_ORGANIZATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getOrganization'],
    }
  )

  const [kickUserMutation, kickUserResult] = useMutation(
    KICK_USER_FROM_ORGANIZATION,
    {
      errorPolicy: 'all',
      refetchQueries: ['getOrganization'],
    }
  )

  const [cancelRequestMutation, cancelRequestResult] = useMutation(
    CANCEL_REQUEST_TO_ORGANIZATION,
    {
      errorPolicy: 'all',
      refetchQueries: [
        'getOrganization',
        'OrganizationsSearch',
        'organizations',
      ],
    }
  )

  const [leaveOrganizationMutation, leaveOrganizationResult] = useMutation(
    LEAVE_ORGANIZATION,
    {
      errorPolicy: 'all',
      refetchQueries: [
        'getOrganization',
        'OrganizationsSearch',
        'organizations',
      ],
    }
  )

  const getOrganizationSkills = (organization_id) => {
    getOrganizationSkillsQuery({
      variables: {
        organization_id,
      },
    })
  }

  const deleteOrganization = (id) => {
    deleteOrganizationMutation({
      variables: {
        id,
      },
    })
  }

  const updateOrganizationSkills = (data, organization_id) => {
    const arrOfSKills = Object.values(data)
      ?.filter((el) => typeof el === 'string')
      .map((el) => parseInt(el))

    updateSkillsMutation({
      variables: {
        id: arrOfSKills,
        organization_id,
      },
    })
  }

  const joinOrganization = (organization_id) => {
    joinOrganizationMutation({
      variables: {
        organization_id,
      },
    })
  }

  const updateOrganization = (data, organization_id) => {
    updateOrganizationMutation({
      variables: {
        organization_id,
        ...data,
      },
    })
  }

  const deleteOrganizationSkill = (organization_id, id) => {
    deleteOrganizationSkillMutation({
      variables: {
        organization_id,
        id,
      },
    })
  }

  const addOrganization = (data) => {
    const json = JSON.stringify(data)
    const withStrings = JSON.parse(json, (key, val) =>
      key === 'approved' ? Number(val) : val
    )

    addOrganizationMutation({
      variables: withStrings,
    })
  }

  const declineUserToOrganization = (organization_id, user_id) => {
    declineUserMutation({
      variables: {
        organization_id,
        user_id,
      },
    })
  }

  const cancelOrganizationRequest = (organization_id) => {
    cancelRequestMutation({
      variables: {
        organization_id,
      },
    })
  }

  const kickUserFromOrganization = (organization_id, user_id) => {
    kickUserMutation({
      variables: {
        organization_id,
        user_id,
      },
    })
  }

  const approveUserToOrganization = (organization_id, user_id) => {
    approveUserMutation({
      variables: {
        organization_id,
        user_id,
      },
    })
  }

  const leaveOrganization = (organization_id) => {
    leaveOrganizationMutation({
      variables: {
        organization_id,
      },
    })
  }

  return {
    addOrganization,
    addOrganizationResult,
    updateOrganization,
    deleteOrganization,
    updateOrganizationResult,
    organizationSkills,
    joinOrganization,
    updateOrganizationSkills,
    getOrganizationSkills,
    organizationSkills,
    leaveOrganization,
    deleteOrganizationSkill,
    approveUserToOrganization,
    declineUserToOrganization,
    kickUserFromOrganization,
    cancelOrganizationRequest,
  }
}

export default useOrganization
