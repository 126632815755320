import { gql } from '@apollo/client'

export const GET_META_DATA = gql`
  query GetMetaData($url: String) {
    GetMetaData(url: $url) {
      meta_title
      meta_description
      title
      og_image
      url
    }
  }
`
