import React from 'react'
import './index.css'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HelmetProvider } from 'react-helmet-async'
import { ThemeProvider } from 'styled-components'
import theme from 'theme'
import { CookiesProvider } from 'react-cookie'
import GlobalStyle from './GlobalStyles'
import './i18n'

import 'slick-carousel/slick/slick.css'
import 'react-tabs/style/react-tabs.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-input-range/lib/css/index.css'

const Main = () => {
  return (
    // <React.StrictMode>
    <CookiesProvider>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </HelmetProvider>
    </CookiesProvider>
    // </React.StrictMode>
  )
}

ReactDOM.render(<Main />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
