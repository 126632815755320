import { useState } from 'react'
import Icon from 'components/Icon'
import * as S from './styled'

const Accordion = ({ title, children, info, ...rest }) => {
  const [open, setOpen] = useState(false)

  const isInfo = info ? 'info-accordion' : ''

  return (
    <S.Accordion className={`${open && 'open'} ${isInfo}`} {...rest}>
      <S.AccordionHeader onClick={() => setOpen(!open)}>
        <p>{title}</p>
        <Icon filename="angle-down" />
      </S.AccordionHeader>
      <S.AccordionContent>{children}</S.AccordionContent>
    </S.Accordion>
  )
}

export default Accordion
