import styled from 'styled-components'

export const Button = styled.button`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.background[150]};
  box-shadow: unset;
  outline: none;
  cursor: pointer;
  border-radius: 43px;
  padding: 12px 40px 16px 40px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    border: 1px solid ${(props) => props.theme.colors.text[300]};
    padding: 12px 25px 16px 25px;
  }

  &.with-icon {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    grid-gap: 10px;
  }

  &.small {
    padding: 12px 21px;
  }

  &.transparent {
    background-color: transparent;
    color: ${(props) => props.theme.colors.white};
  }
`

export const PrimaryButton = styled(Button)`
  border: none;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary[700]};
  font-size: ${(props) => props.theme.fontSizes.sm};

  &:disabled {
    background-color: ${(props) => props.theme.colors.background[250]};
  }
`

export const CleanButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.text[700]};
  border: none;
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 5px;
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: ${(props) => (props.gap ? `${props.gap}px` : '10px')};
  align-items: center;
`
