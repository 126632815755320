import styled from 'styled-components'

export const Footer = styled.footer`
  padding-top: 40px;
  border-top: 1px solid ${(props) => props.theme.colors.background[150]};
  ${(props) => !props.authorized && 'margin-top: 200px;'}
  width: 100%;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    ${(props) => !props.authorized && 'margin-top: 80px;'}
  }
`

export const FooterTop = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.authorized ? '1fr' : '4fr 5fr')};
  grid-gap: 160px;
  ${(props) => !props.authorized && 'padding-bottom: 50px;'}
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-gap: 60px;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const FooterText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  img {
    width: 177px;
  }

  p {
    color: ${(props) => props.theme.colors.text[500]};
    line-height: 25px;
  }
`

export const FooterNav = styled.nav`
  display: grid;
  grid-template-columns: ${(props) =>
    props.authorized ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
  justify-content: space-between;
  grid-gap: 20px;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(1, 1fr);
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 17px;

    h3 {
      color: ${(props) => props.theme.colors.text[700]};
      font-size: ${(props) => props.theme.fontSizes.md};
      font-weight: normal;
    }

    li {
      a {
        color: ${(props) => props.theme.colors[500]};
      }
    }
  }
`

export const FooterBottom = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background[200]};
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    background-color: transparent;
  }
`

export const FooterBottomContent = styled.div`
  padding: 13px 0px 18px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column-reverse;
    div {
      padding: 13px 0px 18px;
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    div {
      gap: 15px;
      a {
        width: 50%;
      }
    }
  }

  p {
    color: ${(props) => props.theme.colors.text[500]};
  }
`

export const FooterBottomLinks = styled.div`
  display: flex;
  gap: 49px;

  a {
    color: ${(props) => props.theme.colors.text[500]};
  }
`
