import { useState } from 'react'
import Select from 'components/Forms/Select'
import Logo from 'components/Logo'
import { languages } from 'constants/global'
import { LeftContainer, RightContainer } from 'components/Container'
import TermsAndCondition from 'components/TermsAndCondition'
import useSwitchLocale from 'hooks/useSwitchLocale'
import useWindowWidth from 'hooks/useWindowWidth'
import i18n from '../../i18n'
import * as S from './styled'

const AuthLayout = (props) => {
  const [terms, setTerms] = useState(true)
  const width = useWindowWidth()
  const [switchLocale] = useSwitchLocale()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng?.value)
    switchLocale(lng?.value)
  }

  return (
    <S.AuthLayout>
      <S.AuthLeftSide terms={terms}>
        <LeftContainer>
          <S.LeftSideContent>
            {width > 992 ? (
              <Logo type={'white'} />
            ) : (
              <S.HeaderMobile>
                <Logo type={width < 992 ? 'logo' : 'white'} />
                <Select
                  options={languages}
                  icon="globe"
                  placeholder=""
                  defVal={i18n.language}
                  onChange={(e) => changeLanguage(e)}
                />
              </S.HeaderMobile>
            )}

            <TermsAndCondition setTerms={setTerms} />
            <img src="img/finger-up.png" alt="Finger Up" />
          </S.LeftSideContent>
        </LeftContainer>
      </S.AuthLeftSide>
      <S.AuthRightSide terms={terms}>
        <RightContainer>
          <S.RightSideContent>
            <S.AuthActions>
              {width > 992 ? (
                <Select
                  options={languages}
                  icon="globe"
                  placeholder=""
                  defVal={i18n.language}
                  onChange={(e) => changeLanguage(e)}
                />
              ) : (
                <S.HeaderMobile>
                  <Logo />
                  <Select
                    options={languages}
                    icon="globe"
                    placeholder=""
                    defVal={i18n.language}
                    onChange={(e) => changeLanguage(e)}
                  />
                </S.HeaderMobile>
              )}
            </S.AuthActions>
            <S.AuthContent>{props.children}</S.AuthContent>
          </S.RightSideContent>
        </RightContainer>
      </S.AuthRightSide>
    </S.AuthLayout>
  )
}

export default AuthLayout
