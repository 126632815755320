import styled from 'styled-components'

export const SettingItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`

export const SettingItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  p {
    color: ${(props) => props.theme.colors.text[700]};
  }
`

export const SettingsTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSizes.sm};
  color: ${(props) => props.theme.colors.text[500]};
  margin-bottom: 20px;
  font-weight: normal;
`
