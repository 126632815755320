import { useQuery } from '@apollo/client'
import { GET_NOTIFICATIONS } from 'services/queries/getNotifications'

const useNotifications = (page) => {
  const { data } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      limit: 30,
      page,
    },
  })

  const notificationsData = data?.notifications?.data
  const hasMore = data?.notifications?.has_more_pages

  return [notificationsData, hasMore]
}

export default useNotifications
