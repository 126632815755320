import { gql } from '@apollo/client'
import { POST_FRAGMENT } from 'services/fragments/post'

export const GET_FEED = gql`
  query dataFeed($limit: Int, $page: Int, $type: String) {
    dataFeed(limit: $limit, page: $page, type: $type) {
      data {
        organization_id
        can_help
        isPostBookmarked
        notifications_on
        og_url
        volunteer_requests {
          id
          name
        }
        is_shared
        shared_post {
          id
          title
          type
          approved
          skills {
            id
            name
          }
          slug
          description
          workers
          volunt
          start_date
          end_date
          cover
          latitude
          longitude
          likes_count
          comments_count
          shares_count
          likedUser
          isPostSharer
          is_started
          is_finished

          volunteer_requests {
            id
            name
          }
          comments {
            id
            body
            replies {
              id
              body
            }
          }
          user {
            id
            slug
            sex
            name
            type
            surname
            email
            state {
              name
            }
            avatar {
              id
              path
            }
            is_me
            is_online
            is_activated
            state {
              name
            }
          }
        }
        id
        title
        type
        approved
        skills {
          id
          name
        }
        slug
        description
        workers
        volunt
        start_date
        end_date
        cover
        latitude
        longitude
        share_url
        likes_count
        comments_count
        shares_count
        likedUser
        isPostSharer
        is_started
        created_at
        is_finished
        comments {
          id
          body
          replies {
            id
            body
          }
        }
        user {
          id
          slug
          sex
          name
          type
          surname
          email
          state {
            name
          }
          avatar {
            id
            path
          }
          is_me
          is_online
          is_activated
          state {
            name
          }
        }
      }
      has_more_pages
    }
  }
`
