import styled from 'styled-components'

export const YouMayKnow = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 20px;
  border-radius: 10px;
`

export const YouMayKnowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const YouMayKnowList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const UserContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const User = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-areas:
    'avatar name'
    'avatar description';

  .simple-avatar {
    grid-area: avatar;
    width: 40px;
    height: 40px;
  }

  div {
    width: 40px;
    height: 40px;
    grid-area: avatar;
    cursor: pointer;
  }

  h4 {
    grid-area: name;
    margin: 0px;
    cursor: pointer;

    width: 200px;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  p {
    grid-area: description;
  }
`

export const Banner = styled.div`
  margin-top: 32px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }

  button {
    left: 50%;
    bottom: 3px;
    font-size: 14px;
    position: absolute;
    padding: 12px 15px;
    background: #f06524;
    transform: translate(-50%, -50%);
  }
`
