const useDownloadFile = () => {
  const downloadFile = (url, fileName) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `${fileName}.pdf`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(() => alert('ფაილის ჩამოტვირთვა ვერ მოხერხდა'))
  }

  return [downloadFile]
}

export default useDownloadFile
