import { useMutation } from '@apollo/client'
import { ADD_POST } from 'services/mutations/post'
import { GET_USER_POSTS } from 'services/queries/getActivityPosts'
import { GET_FEED } from 'services/queries/getFeed'
import { GET_ORGANIZATION_FEED } from 'services/queries/getOrganizationPosts'

const numberValues = ['organization_id', 'workers', 'volunt', 'categories']

const useRequest = () => {
  const [addRequestMutation, addRequestResult] = useMutation(ADD_POST, {
    errorPolicy: 'all',
    refetchQueries: [
      GET_USER_POSTS,
      GET_ORGANIZATION_FEED,
      {
        query: GET_FEED,
        variables: {
          limit: 10,
          page: 1,
        },
      },
    ],
  })

  const addRequest = (data) => {
    const { cover, ...restData } = data
    const json = JSON.stringify(restData)
    const withStrings = JSON.parse(json, (key, val) => {
      return typeof val !== 'object' &&
        val !== null &&
        numberValues.includes(key)
        ? Number(val)
        : val
    })

    const requestData = {
      cover,
      ...withStrings,
    }

    addRequestMutation({
      variables: requestData,
    })
  }

  return [addRequest, addRequestResult]
}

export default useRequest
