import * as S from '../styled'
import Modal from 'components/Modal'
import Button from 'components/Forms/Button'
import { useTranslation } from 'react-i18next'

const Alert = (props) => {
  const { t } = useTranslation()
  return (
    <Modal {...props}>
      <S.ModalForm autoComplete="off">
        <h3>{props.children}</h3>
        <S.ButtonActions>
          <Button onClick={props.onConfirm} type="primary">
            {t('common.yes')}
          </Button>
          <Button onClick={props.onClose} type="primary">
            {t('common.no')}
          </Button>
        </S.ButtonActions>
      </S.ModalForm>
    </Modal>
  )
}

export default Alert
