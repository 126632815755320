const checkDevice = (arg) => {
  const getDevice = navigator.userAgent

  const device = {
    iPad: /iPad/.test(getDevice),
    iPhone: /iPhone/.test(getDevice),
  }

  const response = device.iPhone || device.iPad ? arg + 2 + 'px' : arg + 'px'

  return response
}

const theme = {
  colors: {
    primary: {
      700: '#FFCE07',
      300: '#fffae5',
    },
    text: {
      700: '#414042',
      500: '#8d9297',
      300: '#c5c9ca',
      110: 'rgba(247,247,247,0.5)',
      100: '#f7f7f7',
    },
    success: '#69B61A',
    background: {
      100: 'rgba(197,201,202,0.1)',
      150: 'rgba(197,201,202,0.3)',
      160: 'rgba(65,64,66,0.3)',
      170: 'rgba(65,64,66,0.7)',
      200: '#FAFAFA',
      210: '#F7F7F7',
      250: '#C5C9CA',
      300: '#DFE0DF',
    },
    shadow: {
      100: 'rgba(65,64,66,0.05)',
    },
    error: '#D52F35',
    white: '#ffffff',
  },
  fontSizes: {
    xxs: checkDevice(10),
    xs: checkDevice(12),
    sm: checkDevice(14),
    md: checkDevice(16),
    mdx: checkDevice(18),
    lg: checkDevice(20),
    xl: checkDevice(30),
    xxl: checkDevice(40),
  },
  breakpoints: {
    xs: '480px',
    sm: '767px',
    md: '992px',
    lg: '1200px',
    lg2: '1280px',
    xl: '1600px',
  },
  containers: {
    lg: '1180px',
    xl: '1530px',
    feed: '1270px',
    feedSm: '1000px',
  },
}

export default theme
