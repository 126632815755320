import { useHistory, useLocation } from 'react-router-dom'

const useQueryString = (val) => {
  const { search } = useLocation()
  const history = useHistory()

  const queryString = new URLSearchParams(search)
  const result = queryString.get(val)

  return {
    result,
    search,
    history,
  }
}

export default useQueryString
