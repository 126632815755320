import { gql } from '@apollo/client'

export const GET_NOTIFICATIONS = gql`
  query notifications($limit: Int, $page: Int) {
    notifications(limit: $limit, page: $page) {
      data {
        id
        post {
          id
          type
        }
        from_user {
          id
          name
          type
          slug
          surname
          email
          volunt
          volunt_time
          is_online
          avatar {
            id
            path
          }
          cover {
            id
            path
          }
          is_me
          sex
        }
        to_user {
          id
          name
          type
          surname
          email
          volunt
          volunt_time
          is_online
          avatar {
            id
            path
          }
          cover {
            id
            path
          }
          is_me
          sex
        }
        type
        from_system
        message
        unread
        created_at
        updated_at
      }
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
    }
  }
`
