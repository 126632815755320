import * as S from './styled'
import Container from 'components/Container'
import useLanding from 'hooks/useLanding'
import { GET_FOOTER } from 'services/queries/getLanding'

const Footer = (props) => {
  const { data: footerData, loading } = useLanding(GET_FOOTER)
  const data = footerData?.HomeContent

  return (
    <S.Footer authorized={props.authorized}>
      <Container>
        <S.FooterTop authorized={props.authorized}>
          {!props.authorized && (
            <S.FooterText>
              <img src={data?.footer_logo} alt="Time Bank Logo" />
              <p dangerouslySetInnerHTML={{ __html: data?.footer_text }}></p>
            </S.FooterText>
          )}
          <S.FooterNav authorized={props.authorized}>
            {!props.authorized &&
              data?.footer_navigation.map((el, index) => (
                <ul key={index}>
                  <h3>{el.title}</h3>

                  {el.items.map((el, index) =>
                    el?.title === 'ჩვენ შესახებ' || 'About us' ? (
                      <li key={index}>
                        <a href={el.url} rel="noreferrer">
                          {el.title}
                        </a>
                      </li>
                    ) : (
                      <li key={index}>
                        <a href={el.url} target="_blank" rel="noreferrer">
                          {el.title}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              ))}
          </S.FooterNav>
        </S.FooterTop>
      </Container>
      <S.FooterBottom>
        <Container>
          <S.FooterBottomContent>
            <p>{data?.footer_copyright_text}</p>
            {!props.authorized && (
              <S.FooterBottomLinks>
                {data?.footer_copyright_navigation.map((elem, index) => (
                  <a href={elem.url} key={index}>
                    {elem.title}
                  </a>
                ))}
              </S.FooterBottomLinks>
            )}
          </S.FooterBottomContent>
        </Container>
      </S.FooterBottom>
    </S.Footer>
  )
}

export default Footer
