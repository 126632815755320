import { gql } from '@apollo/client'

export const UPDATE_USER_SOCIAL = gql`
  mutation UpdateSocialMedia(
    $linkedin_url: String
    $facebook_url: String
    $instagram_url: String
    $dribble_url: String
    $pinterest_url: String
    $twitter_url: String
    $youtube_url: String
    $behance_url: String
  ) {
    UpdateSocialMedia(
      linkedin_url: $linkedin_url
      facebook_url: $facebook_url
      instagram_url: $instagram_url
      dribble_url: $dribble_url
      pinterest_url: $pinterest_url
      twitter_url: $twitter_url
      youtube_url: $youtube_url
      behance_url: $behance_url
    ) {
      response
    }
  }
`

export const UPDATE_ORGANIZATION_SOCIAL = gql`
  mutation UpdateOrganizationSocialMedia(
    $organization_id: Int
    $linkedin_url: String
    $facebook_url: String
    $instagram_url: String
    $dribble_url: String
    $pinterest_url: String
    $twitter_url: String
    $youtube_url: String
    $behance_url: String
  ) {
    UpdateOrganizationSocialMedia(
      organization_id: $organization_id
      linkedin_url: $linkedin_url
      facebook_url: $facebook_url
      instagram_url: $instagram_url
      dribble_url: $dribble_url
      pinterest_url: $pinterest_url
      twitter_url: $twitter_url
      youtube_url: $youtube_url
      behance_url: $behance_url
    ) {
      response
    }
  }
`
