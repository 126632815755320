import Icon from 'components/Icon'
import * as S from './styled'
import { useState, useRef, useEffect } from 'react'

const InfoDropdown = ({ actions, children, ...rest }) => {
  const [open, setOpen] = useState(false)
  const dropDownRef = useRef()

  useEffect(() => {
    const handler = (event) => {
      const checkTarget = event.target.id === 'menu-box'
      if (!dropDownRef?.current?.contains(event.target) && !checkTarget) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [])

  return (
    <div ref={dropDownRef}>
      <S.Actions
        onClick={(e) => {
          setOpen(!open)
          e.stopPropagation()
        }}
        open={open}
      >
        {actions}
      </S.Actions>
      <S.InfoDropdown open={open} {...rest}>
        <Icon filename="mark-arrow" className="mark-arrow" />
        {children}
      </S.InfoDropdown>
    </div>
  )
}

export default InfoDropdown
