import { gql } from '@apollo/client'

export const GET_STATES = gql`
  query states {
    states {
      id
      name
      slug
      latitude
      longitude
    }
  }
`
