import { useState } from 'react'
import { useQuery } from '@apollo/client'
import Header from 'components/Header'
import { FeedContainer } from 'components/Container'
import CreatePost from 'modules/CreatePost'
import PostsNearby from 'modules/PostsNearby'
import YouMayKnow from 'modules/YouMayKnow'
import FriendsList from 'modules/FriendsList'
import MyOrganizations from 'modules/MyOrganizations'
import UserSidebarSettings from 'modules/UserSidebarSettings'
import RequestRightBar from 'modules/RequestRightBar'
import AuthorizedUserProvider from 'contexts/AuthorizedUser'
import FeedFooter from 'components/FeedFooter'
import NotificationsRightBar from 'modules/NotificationsRightBar'
import { GET_ME } from 'services/queries/getMe'

import * as S from './styled'

const possibleInfos = {
  createPost: CreatePost,
  postNearby: PostsNearby,
  youMayKnow: YouMayKnow,
  myOrganizations: MyOrganizations,
  userSidebarSettings: UserSidebarSettings,
  requestRightBar: RequestRightBar,
}

const Empty = (props) => <>{props.children}</>

const AuthorizedLayout = (props) => {
  const { data } = useQuery(GET_ME)

  const Provider = props.provider || Empty
  const [showAsOtherUser, setShowAsOtherUser] = useState(false)
  return (
    <AuthorizedUserProvider>
      <Provider>
        <Header
          showAsOtherUser={showAsOtherUser}
          setShowAsOtherUser={setShowAsOtherUser}
        />
        <S.AuthorizedContent>
          <FeedContainer showAsOtherUser={showAsOtherUser}>
            <S.ContentWrapper>
              <S.Content>{props.children}</S.Content>
              <S.AdditionalInfo>
                {props.notificationsPage && (
                  <>
                    <NotificationsRightBar myData={data?.me} />
                    <MyOrganizations myData={data?.me} />
                  </>
                )}
                {props.infos &&
                  props.infos.map((info, index) => {
                    const Component = possibleInfos[info]

                    if (Component) {
                      return (
                        <Component
                          setShowAsOtherUser={setShowAsOtherUser}
                          key={`info-component-${index}`}
                        />
                      )
                    }

                    return null
                  })}
                {!props.hideFooter && <FeedFooter />}
              </S.AdditionalInfo>
            </S.ContentWrapper>
          </FeedContainer>
          <S.Friends>
            <FriendsList />
          </S.Friends>
        </S.AuthorizedContent>
      </Provider>
    </AuthorizedUserProvider>
  )
}

export default AuthorizedLayout
