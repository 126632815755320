import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router-dom'
import Avatar from 'components/Avatar'
import Button from 'components/Forms/Button'
import Icon from 'components/Icon'
import NotificationSettings from 'modules/Modals/NotificationSettings'
import useNotifications from 'hooks/useNotifications'
import useReadNotification from 'hooks/useReadNotification'
import { useTranslation } from 'react-i18next'
import * as S from './styled'

export const UserNotificationList = (props) => {
  const [page, setPage] = useState(1)
  const [allData, setAllData] = useState([])

  const [notificationsData, hasMore] = useNotifications(page)
  const [readNotificationHandler] = useReadNotification()

  useEffect(() => {
    if (notificationsData) {
      setAllData((prevState) => {
        return [...prevState, ...notificationsData]
      })
    }
  }, [notificationsData])

  return (
    <S.NotificationList>
      <InfiniteScroll
        hasMore={hasMore}
        dataLength={allData?.length}
        style={{ overflow: 'hidden' }}
        next={() => setPage((prev) => prev + 1)}
      >
        {allData?.map((notification) => {
          let notificationType

          if (notification?.type === 'volunteer_request_recived') {
            if (notification.post) {
              notificationType = `/${notification?.post?.type}/${notification?.post?.id}`
            } else {
              notificationType = `/user/${notification?.from_user?.slug}`
            }
          } else if (notification?.type === 'post_liked') {
            if (notification.post) {
              notificationType = `/${notification?.post?.type}/${notification?.post?.id}`
            } else {
              notificationType = `/user/${notification?.from_user?.slug}`
            }
          } else if (notification?.type === 'volunteer_hired') {
            notificationType = `/user/${notification?.from_user?.slug}`
          } else if (
            notification?.type === 'request_finished' ||
            notification?.type === 'transaction_rejected' ||
            notification?.type === 'volunts_requested'
          ) {
            if (notification.post) {
              notificationType = `/${notification?.post?.type}/${notification?.post?.id}`
            } else {
              notificationType = `/user/${notification?.from_user?.slug}`
            }
          } else if (
            notification?.type === 'new_follower' ||
            notification?.type === 'send_volunts' ||
            notification?.type === 'transaction_approved' ||
            notification?.type === 'organization_member_joined' ||
            notification?.type === 'organization_member_left'
          ) {
            notificationType = `/user/${notification?.from_user?.slug}`
          } else if (notification?.type === 'user_rated') {
            notificationType = `/user/${notification?.to_user?.slug}`
          } else if (notification?.type === 'organization_create') {
            notificationType = `/organizations`
          } else if (notification?.type === 'post_comment') {
            notificationType = `/${notification?.post?.type}/${notification?.post?.id}`
          } else if (notification?.type === 'comment_reply_user') {
            notificationType = `/${notification?.post?.type}/${notification?.post?.id}`
          } else if (notification?.type === 'comment_reply') {
            notificationType = `/${notification?.post?.type}/${notification?.post?.id}`
          } else {
            notificationType = `/user/${notification?.from_user?.slug}`
          }

          const notificationItem = (
            <S.NotificationItem
              unreadMessage={notification?.unread}
              padding={props.padding}
              backgroundColor={props.backgroundColor}
              borderRadius={props.borderRadius}
              key={notification?.id}
              onClick={() => {
                readNotificationHandler(notification?.id)
              }}
            >
              <S.GridContainer type="avatar">
                <div>
                  <Avatar
                    className="avatar"
                    src={notification?.from_user?.avatar?.path}
                    sex={notification?.from_user?.sex}
                    type="simple"
                  />
                </div>
                <S.NotificationContainer
                  notificationBoxWidth={props.notificationBoxWidth}
                >
                  <S.NameParagraph>
                    {notification?.from_user?.name}{' '}
                    {notification?.from_user?.surname}
                  </S.NameParagraph>
                  <S.NotificationText webkitLineClamp={props.webkitLineClamp}>
                    {notification?.message || 'N/A'}
                  </S.NotificationText>
                </S.NotificationContainer>
                <S.NotificationTime className="date">
                  {notification?.created_at}
                </S.NotificationTime>
              </S.GridContainer>
            </S.NotificationItem>
          )

          return (
            <Link key={notification?.id} to={notificationType}>
              {notificationItem}
            </Link>
          )
        })}
      </InfiniteScroll>
    </S.NotificationList>
  )
}

const UserNotifications = ({ commentNotifications }) => {
  const [settingShow, setSettingShow] = useState(false)
  const { t } = useTranslation()

  return (
    <S.Notifications>
      <S.NotificationsHeader>
        <h4>{t('notifications.messages')}</h4>
        <Button type="clean" onClick={() => setSettingShow(true)}>
          <Icon filename="setting" />
        </Button>
      </S.NotificationsHeader>
      <S.NotificationsListBox>
        <UserNotificationList />
      </S.NotificationsListBox>
      <NotificationSettings
        show={settingShow}
        commentNotifications={commentNotifications}
        onClose={() => setSettingShow(false)}
      />
      <Button type="clean" className="with-icon">
        <Link to="/notifications">
          <span>{t('feed.seeAll')}</span>
          <Icon filename="angle-down" />
        </Link>
      </Button>
    </S.Notifications>
  )
}

export default UserNotifications
