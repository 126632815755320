import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'

import Avatar from 'components/Avatar'
import Button from 'components/Forms/Button'
import Icon from 'components/Icon'

import usePostSuggestions from 'hooks/usePostSuggestions'
import useStartRequest from 'hooks/useStartRequest'
import useFinishRequest from 'services/mutations/useFinishRequest'

import * as S from './styled'
import { useTranslation } from 'react-i18next'

const WorkSpace = () => {
  const location = useLocation()
  const history = useHistory()
  const { me } = useAuthorizedUser()

  const { t } = useTranslation()

  const postUrl = location?.pathname.split('/')
  const postId = Number(postUrl[postUrl.length - 1])

  const { data } = usePostSuggestions(postId)
  const { startRequest, startRequestResponse, startRequestLoading } =
    useStartRequest()
  const { finishRequest, finishRequestLoading } = useFinishRequest()

  const startChatHandler = (requestId) => {
    startRequest(requestId)
  }

  useEffect(() => {
    if (startRequestResponse?.StartRequest) {
      history.push('/messages')
    }
  }, [startRequestResponse])

  const finishRequestHandler = (requestId) => {
    finishRequest(requestId)
  }

  return (
    <>
      {me?.id && me?.id === data?.post?.user?.id && (
        <>
          <div>
            <h4>{t('feed.workspace')}</h4>
            {data?.post?.volunteers?.map((volunteer) => {
              return (
                <S.Box key={volunteer.id}>
                  <S.Container>
                    <S.FlexBox>
                      <Avatar
                        type="simple"
                        src={volunteer?.avatar?.path}
                        sex={volunteer?.sex}
                      />
                      <S.NameBox>
                        <h4>
                          {volunteer.name} {volunteer.surname}
                        </h4>
                        <S.AddressBox>{volunteer.state.name}</S.AddressBox>
                      </S.NameBox>
                    </S.FlexBox>
                    <S.FlexBox>
                      <Icon filename="buy-crypto" />
                      <S.VoluntBox>{volunteer.volunt}</S.VoluntBox>
                    </S.FlexBox>
                  </S.Container>
                </S.Box>
              )
            })}
          </div>
          {!data?.post?.is_started && (
            <Button
              type="primary"
              onClick={() => startChatHandler(data?.post?.id)}
              disabled={startRequestLoading}
            >
              {t('feed.startChatting')}
            </Button>
          )}
          {data?.post?.is_started && !data?.post?.is_finished && (
            <S.CloseButton
              onClick={() => finishRequestHandler(data?.post?.id)}
              disabled={finishRequestLoading}
            >
              {t('feed.closeRequest')}
            </S.CloseButton>
          )}
        </>
      )}
    </>
  )
}

export default WorkSpace
