import styled from 'styled-components'

export const Backdrop = styled.div`
  position: fixed;
  zindex: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  display: ${(props) => (props.show ? 'block' : 'none')};
`

export const BottomModal = styled.div`
  width: 300px;
  min-height: 130px;
  position: fixed;
  background: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  zindex: 1000;
  padding: 15px 20px;
  border-radius: 20px 20px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: ${(props) => (props.show ? 'block' : 'none')};
`
