import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import Icon from 'components/Icon'

export const SearchIcon = styled(Icon)`
  cursor: pointer;
`

export const Input = styled.input`
  width: 100%;
  max-height: 48px;
  padding: 15px 30px;
  background-color: ${(props) => props.theme.colors.background[100]};
  border-radius: 43px;
  border: none;
  outline: none;
  font-weight: 550;

  &::placeholder {
    color: ${(props) => props.theme.colors.text[500]};
    font-weight: 550;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  width: ${(props) => props.checkboxContainerwidth || '200px'};
`

export const ErrorInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span.error-message {
    padding-left: 8px;
    font-size: ${(props) => props.theme.fontSizes.xs};
    color: ${(props) => props.theme.colors.error};
  }
`

export const PasswordInput = styled.div`
  width: 100%;
  position: relative;

  .custom-input {
    padding-right: 60px;
  }

  svg {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`

export const Search = styled.div`
  position: relative;
  width: 100%;

  input {
    padding-left: 70px;
  }

  svg {
    position: absolute;
    left: 27px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    svg {
      width: 20px;
      left: 20px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 85% !important;
    margin: 0 auto;
    input {
      padding: 10px;
      padding-left: 35px;
    }
    svg {
      width: 20px;
      left: 7px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

export const CopyInput = styled.div`
  position: relative;
  width: ${(props) => props.inputWidth || '100%'};

  input {
    padding-right: ${(props) => props.padding || '70px'};
  }

  button {
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
    input {
      padding-right: ${(props) => props.paddingResponsive || '70px'};
    }
  }
`

export const Checkbox = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  cursor: pointer;
  align-items: center;

  i {
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 5px;
    background: rgba(197, 201, 202, 0.2);
    box-shadow: 1px 1px rgba(191, 191, 191, 0.7);
    transition: background-color 0.3s ease;
    svg {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input {
    display: none;

    &:checked {
      & + i {
        background-color: ${(props) => props.theme.colors.primary[700]};

        svg {
          opacity: 1;
        }
      }
    }
  }

  span {
    a {
      color: ${(props) => props.theme.colors.text[700]};
      text-decoration: underline;
    }
  }
`

export const Textarea = styled.textarea`
  width: 100%;
  min-height: 140px;
  padding: 15px 30px;
  background-color: ${(props) => props.theme.colors.background[100]};
  border-radius: 24px;
  border: none;
  outline: none;
  resize: none;
  overflow-y: auto;

  &::placeholder {
    color: ${(props) => props.theme.colors.text[500]};
  }
  &::-webkit-scrollbar {
    width: 0;
  }
`

export const RangeLabel = styled.label`
  display: flex;
  width: 100%;
  gap: 50px;
  padding: 8px;
`

export const RangeInput = styled.div`
  width: 100%;

  .input-range__label-container {
    color: ${(props) => props.theme.colors.text[700]};
  }

  .input-range__track--active {
    background-color: ${(props) => props.theme.colors.primary[700]};
  }

  .input-range__slider {
    background-color: ${(props) => props.theme.colors.primary[700]};
    border-color: ${(props) => props.theme.colors.primary[700]};
  }
`

export const VoluntInput = styled.input`
  width: 100px;
  padding: 10px 15px;
  background-color: ${(props) => props.theme.colors.background[100]};
  border-radius: 43px;
  outline: none;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 200px;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.text[500]};
    font-weight: 550;
  }
`

export const VoluntInfo = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 7px;
    left: 8px;
  }
`

export const ManualIncrement = styled.div`
  display: grid;
  grid-template-columns: 39px 1fr 39px;
  align-items: center;
  grid-gap: 10px;

  input {
    border: 1px solid ${(props) => props.theme.colors.text[500]};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    width: 39px;
    height: 39px;
    background-color: ${(props) => props.theme.colors.background[150]};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${(props) => props.theme.colors.primary[700]};

      svg {
        path {
          stroke: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`

export const Upload = styled.label`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }

  span {
    display: block;
    max-width: 200px;
  }
`

export const Radio = styled.label`
  cursor: pointer;
  input {
    display: none;
    &:checked {
      & + p {
        background-color: ${(props) => props.theme.colors.primary[700]};
        color: ${(props) => props.theme.colors.white};
      }
    }
  }

  p {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.background[100]};
    color: ${(props) => props.theme.colors.text[500]};
  }
`

export const RadioCheck = styled.label`
  cursor: pointer;

  ${(props) => props.theme.colors.primary[700]};
  i {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    text-align: center;
    line-height: 20px;
    background: rgba(197, 201, 202, 0.2);
    box-shadow: 1px 1px rgba(191, 191, 191, 0.7);
    transition: background-color 0.3s ease-in-out;
    svg {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  span {
    transition: color 0.3s ease-in-out;
  }

  input {
    display: none;
    &:checked {
      & + div i {
        background-color: ${(props) => props.theme.colors.primary[700]};
        svg {
          opacity: 1;
        }
      }

      & + div span {
        color: ${(props) => props.theme.colors.text[700]};
      }
    }
  }
`

export const Switch = styled.label`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  cursor: pointer;

  i {
    width: 54px;
    height: 30px;
    border-radius: 23px;
    background-color: ${(props) => props.theme.colors.text[100]};
    position: relative;

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: ${(props) => props.theme.colors.text[500]};
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      left: 3px;
      transition: all 0.3s ease-in-out;
    }
  }

  input {
    display: none;

    &:checked {
      & + i {
        &::before {
          background-color: ${(props) => props.theme.colors.primary[700]};
          left: 50%;
        }
      }
    }
  }

  span {
    a {
      color: ${(props) => props.theme.colors.text[700]};
      text-decoration: underline;
    }
  }
`

export const SwitchToggle = styled.label`
  cursor: pointer;

  div {
    display: flex;
    border-radius: 45px;
    border: 1px solid ${(props) => props.theme.colors.background[150]};

    span {
      display: block;
      padding: 11px 25px;
      border-radius: 45px;
    }
  }

  input {
    display: none;

    &:checked {
      & + div {
        span {
          &:last-child {
            background-color: ${(props) => props.theme.colors.primary[700]};
            color: white;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    div {
      span {
        padding: 5px 15px;
      }
    }
  }
`

export const DateInput = styled(DatePicker)`
  width: 100%;
  max-height: 48px;
  padding: 15px 30px;
  background-color: ${(props) => props.theme.colors.background[100]};
  border-radius: 43px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
`

export const DatePickerBox = styled.div`
  select {
    width: 120px;
    margin: 20px 5px 15px 5px;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
  }
`

export const TodayButton = styled.button`
  margin: 10px 0px;
  padding: 5px 15px;
  background-color: #ffce07;
  color: #000;
  border: 1px solid #aeaeae;
  cursor: pointer;
  border-radius: 3px;
`

export const EyeIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`
