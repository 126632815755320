import { useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { workSchema } from 'validation'

import Modal from 'components/Modal'
import Input from 'components/Forms/Input'
import Button from 'components/Forms/Button'

import useWork from 'hooks/useWork'
import moment from 'moment'

import * as S from '../styled'
import { t } from 'i18next'

const CreateWork = (props) => {
  const [editModal, setEditModal] = useState(undefined)

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return props.data
    }, [props.data]),
    resolver: yupResolver(workSchema(editModal)),
  })

  const { addWork, editWork } = useWork()
  const [endError, setEndError] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endJobDate, setEndJobDate] = useState('')

  const stillWorking = watch('still_working')

  useEffect(() => {
    if (endJobDate) setEndError(null)
  }, [endJobDate])

  const endDateFunc = (e) => {
    if (e.end_date) {
      setEndJobDate(e.end_date)
      return e.end_date
    } else if (e.still_studying) {
      return null
    } else {
      return moment(endJobDate)._d
    }
  }

  const onSubmit = (e) => {
    const defaultValues = {
      ...e,
      start_date: e.start_date ? e.start_date : moment(startDate)._d,
      end_month: e.still_working ? null : e.end_month,
      end_year: e.still_working ? null : e.end_year,
      end_date: endDateFunc(e),
    }

    if (!endJobDate && !e.still_working) {
      setEndError(t('user.warning'))
      return
    }

    if (props.data) {
      editWork(defaultValues, props.data.id)
      reset(e)
    } else {
      addWork(e)
    }

    if (typeof props.onClose === 'function') {
      props.onClose()
    }
    props.setData({})
  }

  useEffect(() => {
    if (props.data) {
      reset(props.data)
    }

    if (props.data?.company) {
      setEditModal('update')
    } else {
      setEditModal(undefined)
    }
  }, [props.show])

  useEffect(() => {
    setStartDate(props.data?.start_month + '-' + props.data?.start_year + '-1')
    setEndJobDate(
      props.data?.still_working
        ? new Date()
        : props.data?.end_month + '-' + props.data?.end_year + '-1'
    )
  }, [props.data?.start_month])

  const onClose = () => {
    props.setData({})
    props.onClose()
  }
  return (
    <Modal
      title={props.data?.company ? t('user.editJob') : t('user.addJob')}
      {...props}
      onClose={onClose}
    >
      <S.ModalForm onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          register={register}
          name="company"
          error={errors?.company}
          placeholder={t('user.company')}
        />
        <Input
          register={register}
          name="position"
          error={errors?.position}
          placeholder={t('user.workPosition')}
        />
        <Input
          register={register}
          name="start_date"
          error={errors?.start_date}
          type="date"
          placeholder={t('user.startDate')}
          defVal={startDate}
        />
        <Input
          register={register}
          disabled={stillWorking}
          type="date"
          name="end_date"
          placeholder={t('user.endDate')}
          defVal={endJobDate}
        />
        {endError && <S.Message>{endError}</S.Message>}
        <S.FormActions>
          <Input type="checkbox" register={register} name="still_working">
            {t('user.stillWorking')}
          </Input>
          <Button type="primary" submit>
            {props.data?.company ? t('user.edit') : t('user.add')}
          </Button>
        </S.FormActions>
      </S.ModalForm>
    </Modal>
  )
}

export default CreateWork
