import styled from 'styled-components'

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  svg {
    transition: transform 0.3s ease;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    p {
      color: ${(props) => props.theme.colors.text[700]};
      font-weight: 100;
      font-size: 14px;
    }
  }
`

export const AccordionContent = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  color: #414042;
`

export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: bold;

  &.info-accordion {
    ${AccordionHeader} {
      padding: 17px 20px 22px 20px;
      border-bottom: 1px solid ${(props) => props.theme.colors.background[150]};
    }

    ${AccordionContent} {
      padding: 0px 20px;
    }
  }

  &.open {
    ${AccordionHeader} {
      svg {
        transform: ${(props) => (props.show ? '' : 'rotate(180deg)')};
      }
    }

    ${AccordionContent} {
      max-height: 400px;
    }

    &.info-accordion {
      background-color: ${(props) => props.theme.colors.background[210]};

      ${AccordionHeader} {
        border-bottom: none;
      }

      ${AccordionContent} {
        padding-bottom: 26px;
      }
    }
  }
`
