import { useEffect, useState } from 'react'
import Modal from 'components/Modal'
import Button from 'components/Forms/Button'
import Input from 'components/Forms/Input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { organizationSchema } from 'validation'
import useSkills from 'hooks/useSkills'
import useWindowWidth from 'hooks/useWindowWidth'
import { useTranslation } from 'react-i18next'
import * as S from '../styled'
import * as CS from './styled'

const CreateOrganization = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(organizationSchema),
  })
  const [skillsList, setSkillsList] = useState([])
  const [categoriesError, setCategoriesError] = useState(null)
  const [chosenSkills, setChosenSkills] = useState([])
  const { getSkills, skillsData } = useSkills()

  const width = useWindowWidth() < 992

  const { t } = useTranslation()

  const getSkillsInfo = (curr) => {
    return {
      label: curr.name,
      value: curr.id,
    }
  }

  useEffect(() => {
    reset()
  }, [props.onClose])

  useEffect(() => {
    getSkills()

    setSkillsList(
      skillsData?.skills?.reduce((acc, curr) => {
        const arr = curr.children.map(getSkillsInfo)
        return acc.concat(arr)
      }, [])
    )
  }, [skillsData])

  const submitHandler = (args) => {
    if (!chosenSkills.length) {
      setCategoriesError(t('organization.requiredSelectCategories'))
      return
    }

    props.addOrganization({ ...args, skill_id: chosenSkills })
    props.onClose()
    setCategoriesError(null)
  }

  return (
    <Modal
      responsiveMaxHeight="100vh"
      title={t('organization.createOrganization')}
      {...props}
    >
      <S.ModalForm onSubmit={handleSubmit(submitHandler)} scrollable>
        <Input
          register={register}
          error={errors?.title}
          name="title"
          placeholder={t('organization.organizationName')}
        />
        <Input
          register={register}
          error={errors?.description}
          name="description"
          type="textarea"
          placeholder={t('organization.organizationDescription')}
        />
        <Input
          isMulti
          isSearchable={width ? false : true}
          type="select"
          register={register}
          name="skill_id"
          error={errors?.skill_id}
          options={skillsList}
          placeholder={t('organization.selectSkills')}
          onChange={(e) => setChosenSkills(e.map((el) => el.value))}
        />
        {categoriesError && <S.Message>{categoriesError}</S.Message>}

        <CS.WhoCan>
          <p>{t('organization.whoCanJoin')}</p>
          <Input
            register={register}
            name="approved"
            value="1"
            type="radio"
            variant="check"
            label={t('organization.joinAutomaticly')}
          />
          <Input
            register={register}
            name="approved"
            value="0"
            type="radio"
            variant="check"
            label={t('organization.needApprove')}
          />
        </CS.WhoCan>
        <CS.WhoCan>
          <p>{t('organization.whoCanPostRequest')}</p>
          <Input
            register={register}
            name="approve"
            value="1"
            type="radio"
            variant="check"
            label={t('organization.allMembers')}
          />
          <Input
            register={register}
            name="approve"
            value="0"
            type="radio"
            variant="check"
            label={t('organization.onlyAdministrator')}
          />
        </CS.WhoCan>

        <S.FormActions>
          <Button type="primary" className="submit" submit>
            {t('organization.createSociety')}
          </Button>
        </S.FormActions>
      </S.ModalForm>
    </Modal>
  )
}

export default CreateOrganization
