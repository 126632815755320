import React from 'react'
import * as S from './styled'

const Loader = () => {
  return (
    <S.LoaderBox>
      <div className="loader-wrapper">
        <div className="loader">
          <div className="roller"></div>
          <div className="roller"></div>
        </div>
        <div id="loader2" className="loader">
          <div className="roller"></div>
          <div className="roller"></div>
        </div>
        <div id="loader3" className="loader">
          <div className="roller"></div>
          <div className="roller"></div>
        </div>
      </div>
    </S.LoaderBox>
  )
}

export default Loader
