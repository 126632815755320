import { toast } from 'react-toastify'

import { useAuthorizedUser } from 'contexts/AuthorizedUser'

import Button, { ButtonGroup } from 'components/Forms/Button'
import Input from 'components/Forms/Input'
import Modal from 'components/Modal'
import Toast from 'components/Toast/Toast'

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'
import { useTranslation } from 'react-i18next'

import * as S from './styled'

const InviteFriend = (props) => {
  const { me } = useAuthorizedUser()
  const { t } = useTranslation()

  const notify = (message, status) => {
    if (status === 'success') {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else if (status === 'error') {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
  }

  const copyLink = () => {
    notify('ლინკი დაკოპირდა', 'success')
    // props.onClose()
  }

  const iconStyle = {
    iconFillColor: '#8d9297',
    size: 40,
    round: true,
    bgStyle: {
      fill: 'transparent',
    },
  }

  return (
    <S.InviteFriendModal title={t('common.inviteFriend')} {...props}>
      <S.InviteFriend>
        <img src="/img/heart.png" alt="Heart Img" />
        <p>{t('feed.randomText')}</p>
        <Button type="clean" onClick={() => copyLink()}>
          <Input
            type="copy"
            value={me?.referal_code_share_url}
            inputWidth="500px"
            padding="100px"
            paddingResponsive="150px"
          />
        </Button>
        <S.Share>
          <p>{t('common.orShare')}</p>
          <ButtonGroup>
            <FacebookShareButton url={me?.referal_code_share_url}>
              {/* <Icon filename='facebook' /> */}
              <FacebookIcon {...iconStyle} />
            </FacebookShareButton>
            <LinkedinShareButton url={me?.referal_code_share_url}>
              <LinkedinIcon {...iconStyle} />
            </LinkedinShareButton>
            <TwitterShareButton url={me?.referal_code_share_url}>
              <TwitterIcon {...iconStyle} />
            </TwitterShareButton>
          </ButtonGroup>
        </S.Share>
      </S.InviteFriend>
      <Toast />
    </S.InviteFriendModal>
  )
}

export default InviteFriend
