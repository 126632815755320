import styled from 'styled-components'
import Modal from 'components/Modal'

export const InviteFriendModal = styled(Modal)`
`

export const InviteFriend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 50px;
    input[disabled] {
      color: ${(props) => props.theme.colors.text[700]};
    }
    ::i-block-chrome,
    input[disabled] {
      color: ${(props) => props.theme.colors.text[700]};
    }
  }
`

export const Share = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: center;
  align-items: center;
  margin-top: 15px;
`
