import * as S from './styled'

const Container = (props) => {
  return <S.Container>{props.children}</S.Container>
}

export const LeftContainer = (props) => {
  return <S.LeftContainer>{props.children}</S.LeftContainer>
}

export const RightContainer = (props) => {
  return <S.RightContainer>{props.children}</S.RightContainer>
}

export const FeedContainer = (props) => <S.FeedContainer {...props} />

export default Container
