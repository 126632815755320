import { useEffect, useRef, useState } from 'react'
import Icon from 'components/Icon'
import { components } from 'react-select'
import * as S from './styled'

const { SingleValue } = components

const SelectValue = (props) => (
  <SingleValue {...props}>
    {props.data?.icon && <Icon filename={props.data?.icon} />}
    {props.data.label}
  </SingleValue>
)

const Select = (props) => {
  const { options, ...rest } = props
  const { onChange } = props.register?.(props.name) || {}
  const [element, setElement] = useState(null)
  const SelectRef = useRef(null)
  const bgColor = 'rgba(197,201,202,0.1)'

  const handleChange = (e) => {
    if (typeof onChange === 'function') {
      onChange({
        target: {
          name: props.name,
          value: e?.value,
        },
      })
    }

    if (typeof props.onChange === 'function') {
      props.onChange(e)
    }
  }

  useEffect(() => {
    if (SelectRef) {
      setElement(SelectRef.current)
    }
  }, [SelectRef])

  const defaultVal = () => {
    const payload = []
    if (props.defVal) {
      if (Array.isArray(props.defVal) && props.defVal?.length > 0) {
        props.defVal?.map((each, i) => {
          payload.push({
            value: each.id ? each.id : each.value,
            label: each.name ? each.name : each.label,
          })
        })
        if (props.setChosenSkills) {
          props.setChosenSkills(payload?.map((each) => each.value))
        }
        return payload
      }
      return options.find((item) => item.value === props.defVal)
    }
    return null
  }

  return (
    <S.SelectContainer
      background={bgColor}
      ref={SelectRef}
      className="custom-select-container"
      open={props.open}
    >
      <span>{props.icon && <Icon filename={props.icon} />}</span>
      <S.Select
        classNamePrefix="custom-select"
        menuPortalTarget={element}
        isSearchable={props.isSearchable}
        placeholder={'EN'}
        isMulti={props.isMulti}
        options={options}
        onChange={handleChange}
        components={{ SingleValue: SelectValue }}
        defaultValue={defaultVal}
        {...rest}
      />
    </S.SelectContainer>
  )
}

export default Select
