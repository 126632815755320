import styled from 'styled-components'

export const PrivateMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PrivateMessagesHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const PrivateMessagesList = styled.div`
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
`

export const PrivateMessageItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.unreadMessage && 'rgba(232,232,232, 0.5)'};
  border-radius: ${(props) => props.borderRadius || null};
  border-bottom: 1px solid ${(props) => props.theme.colors.background[100]};

  p.date {
    padding: 5px 9px 9px 10px;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary[700]};
    border-radius: 37px;
    white-space: nowrap;
  }
`

export const MessageText = styled.p`
  display: -webkit-box;
  margin: 8px 0px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
