import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useDownloadFile from 'hooks/useDownloadFile'

import { useUser } from 'contexts/UserProvider'
import { useAuthorizedUser } from 'contexts/AuthorizedUser'
import { useTranslation } from 'react-i18next'

import Accordion from 'components/Accordion'
import Icon from 'components/Icon'
import Button from 'components/Forms/Button'

import CreateEducation from 'modules/Modals/CreateEducation'
import CreateWork from 'modules/Modals/CreateWork'
import UserInformation from 'modules/Modals/UserInformation'
import CreateSocialNetwork from 'modules/Modals/CreateSocialNetwork'
import CreateSkills from 'modules/Modals/CreateSkills'
import useSocialMedia from 'hooks/useSocialMedia'
import useUpdateResume from 'hooks/useUpdateResume'

import * as S from './styled'

const UserSidebarSettings = (props) => {
  const { me } = useAuthorizedUser()
  const { showAs, setShowAs, user } = useUser()
  const [downloadFile] = useDownloadFile()
  const { editUserSocialMedia, editOrganizationSocial } = useSocialMedia()
  const [UpdateResume, data] = useUpdateResume()

  const [informationModalOpen, setInformationModalOpe] = useState(false)
  const [educationModalOpen, setEducationModalOpen] = useState(false)
  const [workModalOpen, setWorkOpen] = useState(false)
  const [socialNetworkModalOpen, setSocialNetworkModalOpen] = useState(false)
  const [skillsModalOpen, setSkillsModalOpen] = useState(false)

  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    UpdateResume()
  }, [])

  const isMyOrganization = user?.user?.is_me

  if (!user?.is_me) {
    return null
  }

  const redirectAboutPage = () => {
    history.push(`${window.location.pathname}?tab=1`)
  }

  return (
    <S.UserSidebarSettings>
      <Accordion title={t('user.editProfile')} show={showAs}>
        {!showAs && (
          <S.ProfileList>
            <li>
              <Button type="clean" onClick={() => setInformationModalOpe(true)}>
                {t('user.privateInformation')}
              </Button>
            </li>
            <li>
              <Button type="clean" onClick={() => redirectAboutPage()}>
                {t('user.education')}
              </Button>
            </li>
            <li>
              <Button type="clean" onClick={() => redirectAboutPage()}>
                {t('user.workExperience')}
              </Button>
            </li>
            <li>
              <Button type="clean" onClick={() => setSkillsModalOpen(true)}>
                {t('user.skills')}
              </Button>
            </li>
            <li>
              <Button
                type="clean"
                onClick={() => setSocialNetworkModalOpen(true)}
              >
                {t('user.socialNetworks')}
              </Button>
            </li>
          </S.ProfileList>
        )}
      </Accordion>
      <S.Setting
        type="clean"
        onClick={() => {
          setShowAs(!showAs)
          props.setShowAsOtherUser((prevState) => !prevState)
        }}
      >
        <span>{t('user.showAs')}</span>
        <Icon filename="information" />
      </S.Setting>
      <S.Setting
        onClick={() => {
          if (showAs) return
          else
            downloadFile(
              me?.resume?.path || data?.UpdateResume?.user?.resume?.path,
              'resume'
            )
        }}
        type="clean"
      >
        <span>{t('user.downloadYourResume')}</span>
        <Icon filename="document-download" />
      </S.Setting>

      <CreateEducation
        show={educationModalOpen}
        onClose={() => {
          setEducationModalOpen(false)
        }}
        data={me?.education[0]}
        setData={() => console.log('Cleared')}
      />
      <CreateWork
        show={workModalOpen}
        onClose={() => {
          setWorkOpen(false)
        }}
        data={me?.work_experience[0]}
        setData={() => console.log('cleared')}
      />
      <UserInformation
        show={informationModalOpen}
        onClose={() => {
          setInformationModalOpe(false)
        }}
        data={user}
      />
      <CreateSocialNetwork
        user={user}
        show={socialNetworkModalOpen}
        onClose={() => {
          setSocialNetworkModalOpen(false)
        }}
        editSocialMedia={
          isMyOrganization ? editOrganizationSocial : editUserSocialMedia
        }
      />
      <CreateSkills
        show={skillsModalOpen}
        onClose={() => {
          setSkillsModalOpen(false)
        }}
      />
    </S.UserSidebarSettings>
  )
}

export default UserSidebarSettings
