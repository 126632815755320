import { useMutation } from '@apollo/client'
import { READ_NOTIFICATION } from 'services/mutations/readNotification'

const useReadNotification = () => {
  const [readNotification] = useMutation(READ_NOTIFICATION, {
    errorPolicy: 'all',
    // refetchQueries: ['notifications'],
  })

  const readNotificationHandler = (id) => {
    readNotification({
      variables: {
        notification_id: id,
      },
    })
  }

  return [readNotificationHandler]
}

export default useReadNotification
